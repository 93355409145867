import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DERs from './DERs';
import DER from './routes/DER';

const DERsContainer = () => {
  return (
    <Switch>
      <Route exact path="/ders">
        <DERs />
      </Route>
      <Route path="/ders/:derID">
        <DER />
      </Route>
    </Switch>
  );
};

export default DERsContainer;
