import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Events from './Events';
import CreateEvent from './routes/CreateEvent';
import SingleEvent from './routes/SingleEvent';

const EventRoute = () => (
  <Switch>
    <Route exact path="/program/:programID/events">
      <Events />
    </Route>
    <Route exact path="/program/:programID/events/create">
      <CreateEvent />
    </Route>
    <Route exact path="/program/:programID/events/:eventID">
      <SingleEvent />
    </Route>
  </Switch>
);

export default EventRoute;
