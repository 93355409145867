import { CimObject } from 'types/cim';

export function getSingleRef(obj: CimObject, refName: string) {
  const ref = obj.references[refName];
  return Array.isArray(ref) ? ref[0] : ref;
}

export function getMultiRef(obj: CimObject, refName: string) {
  const ref = obj.references[refName];
  return Array.isArray(ref) ? ref : typeof ref !== 'undefined' ? [ref] : [];
}
