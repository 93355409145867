import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Program from './Program';
import BidsOffers from './routes/BidOffers';
import Enrollment from './routes/Enrollment';
import Events from './routes/Events';
import OperationalView from './routes/OperationalView';
import Reports from './routes/Reports';
import Settings from './routes/Settings';

const ProgramContainer = () => {
  return (
    <Switch>
      <Route exact path="/program/:programID">
        <Program />
      </Route>
      <Route path="/program/:programID/enrollment">
        <Enrollment />
      </Route>
      <Route path="/program/:programID/events">
        <Events />
      </Route>
      <Route path="/program/:programID/live">
        <OperationalView />
      </Route>
      <Route path="/program/:programID/offers">
        <BidsOffers />
      </Route>
      <Route exact path="/program/:programID/reports">
        <Reports />
      </Route>
      <Route exact path="/program/:programID/settings">
        <Settings />
      </Route>
    </Switch>
  );
};

export default ProgramContainer;
