import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ProgramAdmin from './ProgramAdmin';
import ProgramAdminSettings from './routes/ProgramAdminSettings';

const ProgramAdminSettingsContainer = () => {
  return (
    <Switch>
      <Route exact path="/admin/programs">
        <ProgramAdmin />
      </Route>
      <Route path="/admin/programs/:programID">
        <ProgramAdminSettings />
      </Route>
    </Switch>
  );
};

export default ProgramAdminSettingsContainer;
