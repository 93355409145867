import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import { Container, useContainers } from 'hooks/useContainers';
import { DER, useDERs } from 'hooks/useDERs';
import { ProgramsContext, Program } from 'contexts/ProgramsContext';

import { FeederEnrollment } from 'types/feeder';

export type DERType =
  | 'BESS'
  | 'GenSet'
  | 'Hydro'
  | 'Load'
  | 'PhotoVoltaic'
  | 'Wind';

export type AssetEnrollment = {
  asset_id: string;
  control_strategy: 'SCHEDULED' | 'CURTAILABLE';
  feeder_id: string;
  info: {
    apparent_power: number;
    class: string;
    der_phases: string;
    der_rdf_id: string;
    der_type: DERType;
    feeder: {
      id: string;
      name: string;
    };
    gen_capacity: number;
    location: [string, string];
    name: string;
    phases: number;
    power_factor: number;
    rated_voltage: number;
    reactive_power: number;
    real_power: number;
    usage_point_type: string | null;
  };
  is_aggregated: boolean;
  market_der_id: number;
  program_id: number;
  ramp_rate: number | null;
  workspace_name: string;
  activated?: boolean;
  email?: {
    email: string;
    enabled: boolean;
  };
  operating_mode?: 'UNCONSTRAINED' | 'BID_OFFER';
};

export function useContainerEnrollment(
  program: Program | null
): {
  containers: { [id: string]: Container };
  loading: boolean;
  toggleContainerEnrollment: (
    event: React.MouseEvent<HTMLInputElement>,
    containerId: string
  ) => void;
} {
  const { makeRequest: addFeeder, loading: addFeederLoading } = useRequest(
    `/api/dsp/program/${program?.program_id}/enroll_feeder`
  );
  const {
    makeRequest: removeFeeder,
    loading: removeFeederLoading,
  } = useRequest(`/api/dsp/program/${program?.program_id}/remove_feeder`);

  const { containers, containersLoading, refetchContainers } = useContainers(
    program?.program_id
  );

  const addFeederToProgram = ({ id, name }: { id: string; name: string }) =>
    addFeeder({
      method: 'post',
      body: { feeder_id: id },
      onSuccess: refetchContainers,
      toast: {
        success: `${name} was successfully enrolled in ${program?.name}`,
        error: (error: AxiosError) => {
          if (error.message.includes('enrolled in one program')) {
            return `${name} is already enrolled in a program.`;
          }
          return `There was an unexpected error trying to enroll ${name}.`;
        },
      },
    });

  const removeFeederFromProgram = ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) =>
    removeFeeder({
      method: 'post',
      body: { feeder_id: id },
      onSuccess: refetchContainers,
      toast: {
        success: `${name} was successfully unenrolled from ${program?.name}`,
        error: (error: AxiosError<Error>) => {
          const messages: { [key in string | number]: string } = {
            404: `Program ${program?.name} does not exist.`,
            default: `There was an unexpected error trying to unenroll ${name}.`,
          };
          return messages[error.response?.status || 'default'];
        },
      },
    });

  const toggleContainerEnrollment = (
    event: React.MouseEvent<HTMLInputElement>,
    containerId: string
  ) => {
    if (containers) {
      !event.currentTarget.checked
        ? removeFeederFromProgram(containers[containerId])
        : addFeederToProgram(containers[containerId]);
    }
  };

  return {
    containers: containers || {},
    loading: containersLoading || addFeederLoading || removeFeederLoading,
    toggleContainerEnrollment,
  };
}

export function useDEREnrolment(
  programID?: string
): {
  DERs: { [id: string]: DER };
  DERsLoading: boolean;
  toggleDEREnrollment: (
    event: React.MouseEvent<HTMLInputElement>,
    containerId: string,
    derID: string
  ) => void;
  updating: boolean;
} {
  const { DERs, DERsLoading, updateDEREnrollmentState } = useDERs(programID);

  const { makeRequest: addDer, loading: addDERLoading } = useRequest(
    `/api/dsp/program/${programID}/enroll_asset`
  );
  const { makeRequest: removeDer, loading: removeDERLoading } = useRequest(
    `/api/dsp/program/${programID}/remove_asset`
  );

  const addDERToProgram = async (feederID: string, derID: string) => {
    return addDer({
      method: 'post',
      body: {
        feeder_id: feederID,
        asset_id: derID,
      },
      onSuccess: () => updateDEREnrollmentState(derID, true),
      toast: {
        success: `Successfully added DER to program.`,
        error: 'Could not add DER to program.',
      },
    });
  };
  const removeDERFromProgram = async (derID: string) => {
    return removeDer({
      method: 'post',
      body: {
        asset_id: derID,
      },
      onSuccess: () => updateDEREnrollmentState(derID, false),
      toast: {
        success: `Successfully removed DER from the program.`,
        error: 'Could not remove DER from the program.',
      },
    });
  };

  const toggleDEREnrollment = (
    event: React.MouseEvent<HTMLInputElement>,
    containerId: string,
    derID: string
  ) => {
    !event.currentTarget.checked
      ? removeDERFromProgram(derID)
      : addDERToProgram(containerId, derID);
  };

  return {
    DERs: DERs || {},
    DERsLoading,
    toggleDEREnrollment,
    updating: addDERLoading || removeDERLoading,
  };
}

export function useEnrollmentAPI() {
  const { programID } = useParams<{ programID: string }>();

  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);

  const {
    containers = {},
    loading: containersLoading,
    toggleContainerEnrollment,
  } = useContainerEnrollment(program);

  const {
    toggleDEREnrollment,
    updating,
    DERsLoading,
    DERs = {},
  } = useDEREnrolment(programID);

  const containerTree: {
    [id: string]: Container;
  } = useMemo(() => {
    const sorter = Intl.Collator(undefined, {
      numeric: true, // make sure 10-foo comes after 2-bar
      sensitivity: 'base', // ignore case
    });

    // Remove substations from the UI
    const sortedContainers: Container[] = Object.values(containers)
      .sort((a, b) => sorter.compare(a.name, b.name))
      .filter((c) => c.type !== 'substation');
    const sortedDERs: DER[] = Object.values(DERs).sort((a: DER, b: DER) =>
      sorter.compare(a.name, b.name)
    );

    return sortedContainers.reduce((tree, container) => {
      // Gather associated DERs
      const children = sortedDERs.filter(
        (DER: DER) => DER.feeder?.id === container.id
      );
      if (children.length > 0) {
        return {
          ...tree,
          [container.id]: {
            ...container,
            children,
          },
        };
      }

      return tree;
    }, {});
  }, [containers, DERs]);
  const exportedContainers = useMemo(() => Object.values(containerTree), [
    containerTree,
  ]);

  return {
    programID,
    containers: exportedContainers,
    loading: containersLoading || DERsLoading,
    updating,
    toggleContainerEnrollment,
    toggleDEREnrollment,
  };
}

export function useAssetEnrollmentInfo(
  programID?: string,
  der_rdf_id?: string
) {
  const {
    data: enrollment,
    loading: enrollmentLoading,
    error,
    refetch,
  } = useRequestEffect<AssetEnrollment>({
    url: `/api/dsp/program/${programID}/der/${der_rdf_id}`,
    method: 'get',
    refetchOnChange: [programID, der_rdf_id],
    blockRequest: () => programID === undefined || der_rdf_id === undefined,
    toast: {
      error: 'There was an unexpected error loading your Asset.',
    },
  });

  return {
    enrollment,
    enrollmentLoading,
    error,
    refetch,
  };
}

export function useFeederEnrollmentInfo(programID?: string, feederID?: string) {
  const {
    data: enrollment,
    loading: enrollmentLoading,
    error,
    refetch,
  } = useRequestEffect<FeederEnrollment>({
    url: `/api/dsp/program/${programID}/feeder_enrollment/${feederID}`,
    method: 'get',
    refetchOnChange: [programID, feederID],
    blockRequest: () => programID === undefined || feederID === undefined,
    toast: {
      error: 'There was an unexpected error loading your Feeder.',
    },
  });

  return {
    enrollment,
    enrollmentLoading,
    error,
    refetch,
  };
}
