import { useEffect, useRef } from 'react';

export default function useInterval(func: CallableFunction, interval: number) {
  const currentFunc = useRef<CallableFunction>(func);

  // Only update the current interval function if the callback changes
  useEffect(() => {
    currentFunc.current = func;
  }, [func]);

  // Set up the interval.
  useEffect(() => {
    if (interval) {
      const id = setInterval(() => currentFunc.current(), interval);

      // Clear interval on unmount
      return () => clearInterval(id);
    }
    return () => null;
  }, [interval]);
}
