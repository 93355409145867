import { DateTime } from 'luxon';
import React, {
  useContext,
  useState,
  FunctionComponent,
  Fragment,
} from 'react';
import { useParams } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
import HeaderLayout from 'components/HeaderLayout';
import LMPChart from 'components/LMPChart';
import { ProgramsContext } from 'contexts/ProgramsContext';
import { useContainers } from 'hooks/useContainers';

import './Program.scss';
import { ChartDataset } from 'chart.js';

interface ProgramParams {
  programID: string;
}

type ISO = {
  id: number;
  name: string;
  locale: string;
  currency: string;
};

const Program: FunctionComponent = () => {
  const { programID } = useParams<ProgramParams>();
  const { getProgram } = useContext(ProgramsContext);
  const [date, setDate] = useState(DateTime.local());
  const [isoName, setIsoName] = useState('');
  const [nodeName, setNodeName] = useState('');

  const program = getProgram(programID);
  useRequestEffect({
    url: `/api/dsp/program/${programID}`,
    method: 'get',
    refetchOnChange: [programID],
    onSuccess: (data: any) => {
      if (data) {
        setIsoName(data.iso);
        setDate(date.setZone(data.timezone));
      }
    },
    blockRequest: () => !programID,
  });

  const {
    loading: loadingSameDay,
    data: forecastDataSameDay,
  } = useRequestEffect<{
    [key: string]: Array<{ load: number; time: string }>;
  }>({
    url: `/api/dsp/program/${programID}/feeder/forecasts`,
    method: 'get',
    refetchOnChange: [date],
    initialData: {},
    params: {
      start_time: date.startOf('day').toISO(),
      end_time: date.endOf('day').toISO(),
      market_type: 'sameday',
    },
  });

  const {
    loading: loadingDayAhead,
    data: forecastDataDayAhead,
  } = useRequestEffect<{
    [key: string]: Array<{ load: number; time: string }>;
  }>({
    url: `/api/dsp/program/${programID}/feeder/forecasts`,
    method: 'get',
    refetchOnChange: [date],
    initialData: {},
    params: {
      start_time: date.startOf('day').toISO(),
      end_time: date.endOf('day').toISO(),
      market_type: 'dayahead',
    },
  });

  // Use the first feeder enrolled in the program to set the zoneName used in LMP chart
  const feeder_id = Object.keys(forecastDataSameDay || {})[0];
  useRequestEffect({
    url: `/api/dsp/program/${programID}/feeder_enrollment/${feeder_id}`,
    method: 'get',
    refetchOnChange: [programID],
    onSuccess: (data: any) => {
      if (data) {
        setNodeName(data.zone);
      }
    },
    blockRequest: () => !feeder_id,
  });

  const { data: iso } = useRequestEffect<ISO>({
    url: `/api/dsp/iso/${program?.iso_id}`,
    method: 'get',
    blockRequest: () => !program,
  });

  // Need to load the containers so that we can use them to show traces per name on the cart
  const { containersLoading, containers = {} } = useContainers(programID);

  let sameDayDatasets: Array<ChartDataset> = [];
  const sameDayCount = Object.keys(forecastDataSameDay || {}).length;
  const colorSpacing =
    360 / (sameDayCount + Object.keys(forecastDataDayAhead || {}).length);

  if (!containersLoading && !loadingSameDay && program) {
    sameDayDatasets = Object.entries(forecastDataSameDay || {}).map(
      ([feeder_id, forecast], index) => {
        // @ts-ignore
        const feeder = containers[feeder_id];
        return {
          borderColor: `hsl(${index * colorSpacing}, 100%, 25%)`,
          backgroundColor: `hsl(${index * colorSpacing}, 100%, 25%)`,
          borderWidth: 2,
          label: `${feeder.name}: Same Day`,
          data: forecast
            .map(({ load, time }) => ({
              load: load * 1e-6,
              time: DateTime.fromISO(time, { zone: program.timezone }),
            }))
            .sort((first, second) => first.time.diff(second.time).milliseconds)
            .map(({ load, time }) => ({
              x: time.valueOf(),
              y: load,
            })),
          fill: false,
          stepped: 'before',
        };
      }
    );
  }

  let dayAheadDataSets: Array<ChartDataset> = [];

  if (!containersLoading && !loadingDayAhead && program) {
    dayAheadDataSets = Object.entries(forecastDataDayAhead || {}).map(
      ([feeder_id, forecast], index) => {
        // @ts-ignore
        const feeder = containers[feeder_id];
        return {
          borderColor: `hsl(${
            (index + sameDayCount) * colorSpacing
          }, 100%, 25%)`,
          backgroundColor: `hsl(${
            (index + sameDayCount) * colorSpacing
          }, 100%, 25%)`,
          borderWidth: 2,
          label: `${feeder.name}: Day Ahead`,
          data: forecast
            .map(({ load, time }) => ({
              load: load * 1e-6,
              time: DateTime.fromISO(time, { zone: program.timezone }),
            }))
            .sort((first, second) => first.time.diff(second.time).milliseconds)
            .map(({ load, time }) => ({
              x: time.valueOf(),
              y: load,
            })),
          fill: false,
          stepped: 'before',
        };
      }
    );
  }

  return (
    <HeaderLayout className="program-route" title={`Program: ${program?.name}`}>
      {program !== null && !loadingDayAhead && (
        <Fragment>
          <div className="chart-container">
            <ChartWrapper
              config={{
                type: 'line',
                data: {
                  datasets: [...sameDayDatasets, ...dayAheadDataSets],
                  labels: [],
                },
                options: {
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      intersect: false,
                      mode: 'index',
                      callbacks: {
                        title: (tooltipItems) => {
                          let title = '';
                          if (tooltipItems.length > 0) {
                            const item = tooltipItems[0];
                            const dateTime = DateTime.fromMillis(
                              item.parsed.x,
                              { zone: program.timezone }
                            );
                            title = dateTime.toFormat('DDD hh:mm:ss a ZZ');
                          }

                          return title;
                        },
                        label: (tooltipItem) => {
                          const { parsed, dataset } = tooltipItem;
                          let value = parsed.y;
                          value = Math.round(value * 100) / 100; // Get 2 decimal places
                          return `${
                            dataset.label
                          }: ${value.toLocaleString()} MW`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      adapters: {
                        date: {
                          zone: program.timezone,
                        },
                      },
                      min: date.startOf('day').valueOf(),
                      max: date.startOf('day').plus({ days: 1 }).valueOf(),
                      type: 'time',
                      offset: true,
                      title: {
                        display: true,
                        text: `Time (${program.timezone})`,
                      },
                      ticks: {
                        major: {
                          enabled: true,
                        },
                        source: 'auto',
                        autoSkip: true,
                        autoSkipPadding: 75,
                        maxRotation: 0,
                        sampleSize: 100,
                      },
                    },
                    y: {
                      min: 0,
                      suggestedMax: 1, // Ensure that we at last show to 1MW
                      grid: {
                        drawBorder: false,
                      },
                      title: {
                        display: true,
                        text: 'Forecasted Feeder Load (MW)',
                      },
                    },
                  },
                },
              }}
            />
          </div>
          <div className="chart-container">
            <LMPChart
              start_time={date.startOf('day')}
              end_time={date.endOf('day')}
              currency={iso?.currency || 'USD'}
              locale={iso?.locale || 'en-US'}
              isoName={isoName}
              nodeName={nodeName}
              timezone={program.timezone}
              sameDayDuration={program.sameday_event_duration}
              showZero={program.constraint_energy_management}
            />
          </div>
        </Fragment>
      )}
    </HeaderLayout>
  );
};

export default Program;
