import React, { useContext, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import Card from 'components/Card';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import { ProgramsContext } from 'contexts/ProgramsContext';

import './ProgramCard.scss';

interface ProgramCardProps {
  programID: number;
  programName: string;
}

const ProgramCard = ({ programID, programName }: ProgramCardProps) => {
  const { deleteProgram } = useContext(ProgramsContext);
  const [showModal, setShowModal] = useState(false);

  const { makeRequest: deleteProgramRequest, loading: deleting } = useRequest(
    `/api/dsp/program/${programID}`
  );

  const deleteProgramHandler = async () => {
    await deleteProgramRequest({
      method: 'delete',
      toast: {
        error: 'Could not delete program.',
        success: `Successfully removed program ${programName}.`,
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        deleteProgram(programID);
        setShowModal(false);
      },
      onError: () => {
        setShowModal(false);
      },
    });
  };

  return (
    <Fragment>
      <Card
        buttonGroup={
          <IconButton
            disabled={deleting}
            icon="delete"
            onClick={() => setShowModal(true)}
            tooltip="Delete program"
          />
        }
        className="program-card"
        title={programName}
      >
        <Link to={`/admin/programs/${programID}`}>Program Admin Settings</Link>
      </Card>
      <Modal
        active={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => deleteProgramHandler()}
        title="Confirm Program Deletion"
        width="300px"
      >
        <div className="confirm-message">
          <div>{`Are you sure you wish to delete Program: ${programName}? This action is permanent.`}</div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProgramCard;
