import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import HeaderLayout from 'components/HeaderLayout';

import './Admin.scss';

const Admin = () => {
  return (
    <HeaderLayout className="admin" title="Admin Panel">
      <Fragment>
        <Card
          className="admin-link"
          title={
            <Link to="/admin/programs">
              <h3 style={{ fontSize: 36 }}>Programs</h3>
            </Link>
          }
        />
        <Card
          className="admin-link"
          title={
            <Link to="/admin/health-check">
              <h3 style={{ fontSize: 36 }}>Service Status</h3>
            </Link>
          }
        />
        <Card
          className="admin-link"
          title={
            <Link to="/admin/simulations">
              <h3 style={{ fontSize: 36 }}>Simulations</h3>
            </Link>
          }
        />
        <Card
          className="admin-link"
          title={
            <Link to="/admin/triggers">
              <h3 style={{ fontSize: 36 }}>Trigger Events</h3>
            </Link>
          }
        />
      </Fragment>
    </HeaderLayout>
  );
};

export default Admin;
