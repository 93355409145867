import React from 'react';

/**
 * Creates a loading spinner. There are two variations available:
 *   - circles : spinner made up of circle that change opacity
 *   - bars : 3 vertical bars that change size
 */
type LoadingSpinnerProps = {
  type: 'bars' | 'circles';
};

const LoadingSpinner = ({ type }: LoadingSpinnerProps) => (
  <div className="loading-container">
    <div className={`loading-spinner--${type}`}>Loading...</div>
  </div>
);

LoadingSpinner.defaultProps = {
  type: 'circles',
};

export default LoadingSpinner;
