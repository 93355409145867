import { GeoJsonLayer } from 'deck.gl';
import { OverlayData, OverlayFeature, OverlayFeatures } from 'types/map';

const getForecastLayers = (
  polygonLayerAssets: OverlayFeatures,
  scaling: number,
  overlayData: OverlayData<number>,
  onHover: (obj: Record<string, unknown>, x: number, y: number) => void
) => {
  return [
    new GeoJsonLayer<OverlayFeature>({
      id: 'forecastGeoJson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) => scaling * overlayData[n.properties.id],
      getFillColor: [122, 213, 20],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
  ];
};

export default getForecastLayers;
