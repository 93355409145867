import React, { ReactNode } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import Tooltip from 'components/Tooltip';

import './Tabs.scss';

interface ThemedTabConfig {
  disabled?: boolean;
  disabledTooltip?: string;
  name: string;
}

interface ThemedTabsProps {
  children: (TabComponent: typeof TabPanel) => ReactNode;
  className?: string;
  defaultIndex?: number;
  headerRenderer?: () => ReactNode;
  onSelect?: (index: number) => void;
  selectedIndex?: number;
  tabs: ThemedTabConfig[];
  underline?: 'line' | 'shadow';
}

const ThemedTabs = ({
  children,
  className,
  defaultIndex = 0,
  headerRenderer,
  onSelect,
  selectedIndex,
  tabs,
  underline = 'shadow',
}: ThemedTabsProps) => (
  <Tabs
    className={`themed-tabs ${className ? className : ''} ${underline}`}
    defaultIndex={!selectedIndex && !onSelect ? defaultIndex : undefined}
    onSelect={onSelect ? (tabIndex) => onSelect(tabIndex) : undefined}
    selectedIndex={selectedIndex}
  >
    <TabList>
      <div className="tab-container">
        {tabs.map((tab) => (
          <Tab disabled={tab.disabled} key={tab.name}>
            <div>
              <Tooltip content={tab.disabled ? tab.disabledTooltip || '' : ''}>
                {tab.name}
              </Tooltip>
            </div>
          </Tab>
        ))}
      </div>
      {headerRenderer && (
        <div className="extra-content-container">{headerRenderer()}</div>
      )}
    </TabList>
    {children(TabPanel)}
  </Tabs>
);

export default ThemedTabs;
