import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FeederSettings from './routes/FeederSettings';
import AssetSettings from './routes/AssetSettings';

import Enrollment from './components/Enrollment';

const EnrollmentRoutes = () => (
  <Switch>
    <Route index exact path="/program/:programID/enrollment">
      <Enrollment />
    </Route>
    <Route exact path="/program/:programID/enrollment/feeder/:feederID">
      <FeederSettings />
    </Route>
    <Route exact path="/program/:programID/enrollment/asset/:feederID/:assetID">
      <AssetSettings />
    </Route>
  </Switch>
);

export default EnrollmentRoutes;
