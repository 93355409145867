import React, { useRef, useEffect, useState, FunctionComponent } from 'react';

import {
  BarController,
  BarElement,
  Chart,
  Legend,
  LineElement,
  LineController,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  ChartConfiguration,
} from 'chart.js';

// The default Chart.js moment wrapper does not support timezones
// so we've written our own to use luxon
import './luxon-tz-adapter';
import './ChartWrapper.scss';

Chart.register(
  BarController,
  BarElement,
  Legend,
  LineElement,
  LineController,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
);

type ChartProps = {
  config: ChartConfiguration;
};

const ChartWrapper: FunctionComponent<ChartProps> = ({ config }) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);

  const createChart = () => {
    if (canvas === null) {
      // Haven't captured the ref yet
      return;
    }

    if (chartInstance !== null) {
      // Need to destroy the old chart before we recreate
      chartInstance.destroy();
    }

    const canvasDOMNode = canvas.current;
    const ctx = canvasDOMNode?.getContext('2d');

    if (ctx) {
      const chart = new Chart(ctx, config);

      setChartInstance(chart);
    }

    // Ensure that we destroy the chart on the way out
    return () => {
      if (chartInstance !== null) {
        chartInstance.destroy();
      }
    };
  };

  // If the canvas or overall chart type changes, recreate the chart. Otherwise we just update the chart
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(createChart, [canvas, config]);

  return (
    <div className="chart-wrapper">
      <canvas ref={canvas} />
    </div>
  );
};

export default ChartWrapper;
