import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import Card from 'components/Card';
import useInterval from 'hooks/useInterval';

import './ServiceStatusTile.scss';
import Tooltip from 'components/Tooltip';

type HealthCheckStatus = {
  service_status: string;
  db_status?: string;
  queue_status?: string;
  object_store_status?: string;
};

type ServiceStatusTileRowProps = {
  label: string;
  status?: string;
};

type ServiceStatusTileProps = {
  serviceName: string;
  servicePath: string;
};

const ServiceStatusTileRow: FunctionComponent<ServiceStatusTileRowProps> = ({
  label,
  status,
}) => (
  <div className="service-status-tile-row">
    <span>{label}</span>
    <Tooltip content={`Service Status: '${status ?? 'unknown'}'`}>
      <span
        className={classNames({
          'material-icons': true,
          'icon-success': !!status,
          'icon-error': !status,
        })}
      >
        {status === 'up' ? 'check_circle' : 'error'}
      </span>
    </Tooltip>
  </div>
);

const ServiceStatusTile: FunctionComponent<ServiceStatusTileProps> = ({
  serviceName,
  servicePath,
}) => {
  const {
    data: healthCheckStatus,
    loading: statusLoading,
    refetch,
  } = useRequestEffect<HealthCheckStatus>({
    url: `/api/health-check${servicePath}`,
    method: 'get',
    refetchOnChange: [servicePath],
  });

  useInterval(refetch, 5000);

  return (
    <Card
      className="service-status-tile"
      title={
        <>
          {serviceName}{' '}
          {statusLoading && <i className="material-icons spinner">autorenew</i>}
        </>
      }
    >
      <ServiceStatusTileRow
        label="HTTP API"
        status={healthCheckStatus?.service_status}
      />
      {/*
        Only show db and queue status if they are defined in the response,
        otherwise its assumed they aren't used by the specified service
      */}
      {healthCheckStatus?.db_status !== undefined && (
        <ServiceStatusTileRow
          label="Database Connectivity"
          status={healthCheckStatus?.db_status}
        />
      )}
      {healthCheckStatus?.queue_status !== undefined && (
        <ServiceStatusTileRow
          label="Message Queue Connectivity"
          status={healthCheckStatus?.queue_status}
        />
      )}
      {healthCheckStatus?.object_store_status !== undefined && (
        <ServiceStatusTileRow
          label="Object/File Store Connectivity"
          status={healthCheckStatus?.object_store_status}
        />
      )}
    </Card>
  );
};
export default ServiceStatusTile;
