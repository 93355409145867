import check from 'check-types';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import DatePicker from 'components/DatePicker';
import HeaderLayout from 'components/HeaderLayout';
import NumberInput from 'components/NumberInput';
import Select from 'components/Select';

import { ProgramsContext } from 'contexts/ProgramsContext';
import { useDERs } from 'hooks/useDERs';

import './CreateEvent.scss';

const MARKET_OPTIONS = [
  { label: 'Day Ahead', value: 'DAYAHEAD' },
  { label: 'Same Day', value: 'SAMEDAY' },
];
const STATUS_OPTIONS = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Confirmed', value: 'CONFIRMED' },
  { label: 'Completed', value: 'COMPLETED' },
];

const CreateEvent = () => {
  const { programID } = useParams<{ programID: string }>();
  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);

  const location = useLocation();
  const history = useHistory();

  // State for event
  const [derID, setDerID] = useState<string | null>(null);
  const [market, setMarket] = useState('DAYAHEAD');
  const [power, setPower] = useState<number | undefined>(0);
  const [reactivePower, setReactivePower] = useState<number | undefined>(0);
  const [status, setStatus] = useState('CONFIRMED');
  const [time, setTime] = useState(
    DateTime.local()
      .setZone(program ? program.timezone : 'UTC')
      .startOf('hour')
  );
  const [unitPrice, setUnitPrice] = useState<number | undefined>(1);

  const { DERs } = useDERs(programID);
  const DER_OPTIONS = useMemo(() => {
    if (!DERs) {
      return [];
    }

    return Object.values(DERs).map((der) => {
      return { label: der.name, value: der.der_rdf_id };
    });
  }, [DERs]);
  useEffect(() => {
    if (program) {
      setTime((t) => t.setZone(program.timezone));
    }
  }, [program]);

  const { makeRequest: runSave, loading: saving } = useRequest(
    `/api/dsp/program/${programID}/pricing_event`
  );
  const createPricingEvent = async () => {
    if (check.number(power) && check.number(reactivePower)) {
      await runSave({
        method: 'post',
        body: {
          der_rdf_id: derID,
          market_type: market,
          // UI uses kW, kVAr. API uses W, VAr
          power_required: power * 1000,
          reactive_power_required: reactivePower * 1000,
          status,
          time: time.toISO(),
          unit_price: unitPrice,
        },
        onSuccess: () => {
          // Redirect back to PE list
          history.push(location.pathname.replace('/create', ''));
        },
        toast: {
          error:
            'Could not create event. Check that all parameters are correct',
          success: 'Successfully created event.',
        },
      });
    }
  };

  const valid =
    !!derID &&
    unitPrice !== 0 &&
    !check.undefined(power) &&
    !check.undefined(reactivePower) &&
    !check.undefined(unitPrice);

  return (
    <HeaderLayout
      className="create-event"
      title={
        <Breadcrumbs
          parents={[
            {
              to: `/program/${programID}/events`,
              label: <h2 className="title">Pricing Event</h2>,
            },
          ]}
          separator="/"
          currentHeader="Create Event"
        />
      }
      titleRightContent={
        <Button
          disabled={saving || !valid}
          onClick={() => createPricingEvent()}
        >
          Create Event
        </Button>
      }
    >
      <Card className="form-container">
        <div className="form-row">
          <span>Event Time</span>
          <DatePicker
            date={time}
            onChange={(startTime) => {
              setTime(startTime);
            }}
            options={{
              enableSeconds: false,
              enableTime: true,
              formatDate: (date) => {
                const dt = DateTime.fromJSDate(date);
                return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
              },
              minuteIncrement:
                market === 'SAMEDAY' && program
                  ? program.sameday_event_duration / 60
                  : 60,
            }}
            useUTC={false}
          />
        </div>
        <div className="form-row">
          <Select
            isClearable
            isMulti={false}
            label="DER"
            onChange={(opt) => {
              if (opt) {
                setDerID(opt.value);
              } else {
                setDerID(null);
              }
            }}
            options={DER_OPTIONS}
            value={DER_OPTIONS.find((opt) => opt.value === derID)}
          />
        </div>
        <div className="form-row">
          <Select
            isClearable={false}
            isMulti={false}
            label="Market"
            onChange={(opt) => {
              // Reset to start of hour since the previous time might not be valid
              // in cases where there are sub hourly events
              setMarket(opt.value);
              setTime(time.startOf('hour'));
            }}
            options={MARKET_OPTIONS}
            value={MARKET_OPTIONS.find((opt) => opt.value === market)}
          />
        </div>
        <div className="form-row">
          <Select
            isClearable={false}
            isMulti={false}
            label="Initial Status"
            onChange={(opt) => setStatus(opt.value)}
            options={STATUS_OPTIONS}
            value={STATUS_OPTIONS.find((opt) => opt.value === status)}
          />
        </div>
        <div className="form-row">
          <NumberInput
            id="power"
            label="Power"
            onChange={(value) => setPower(value)}
            required
            unit="kW"
            value={power}
          />
        </div>
        <div className="form-row">
          <NumberInput
            id="reactive_power"
            label="Reactive Power"
            onChange={(value) => setReactivePower(value)}
            required
            unit="kVAr"
            value={reactivePower}
          />
        </div>
        <div className="form-row">
          <NumberInput
            id="unit_price"
            label="Unit Price"
            onChange={(value) => setUnitPrice(value)}
            required
            unit="$/MWhr"
            value={unitPrice}
          />
        </div>
      </Card>
    </HeaderLayout>
  );
};
export default CreateEvent;
