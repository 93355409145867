import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import RadioButtonGroup from 'components/RadioButtonGroup';
import NumberInput from 'components/NumberInput';
import Select from 'components/Select';
import Tabs from 'components/Tabs';
import TextInput from 'components/TextInput';

import { ProgramsContext } from 'contexts/ProgramsContext';
import TimeZones from 'helpers/timezones';
import useLocaleData from 'hooks/useLocaleData';

import { Program, SystemMarketInfo } from 'types/program';

import './Settings.scss';

type ProgramKey = keyof Program;
type MarketKey = keyof SystemMarketInfo;

interface SelectInterface {
  label: string;
  value: string;
}

const timezoneOpts = TimeZones.map((name) => ({ label: name, value: name }));

const eventDurationOpts = [
  { label: '1 Hour', value: 3600 },
  { label: '30 Minute', value: 1800 },
  { label: '15 Minute', value: 900 },
  { label: '5 Minute', value: 300 },
];
const eventMethodOpts = [
  { label: 'Batch', value: 'BATCH' },
  { label: 'Sequential', value: 'SEQUENTIAL' },
];

const batteryControlOpts = [
  { label: 'Any', id: 'ANY' },
  { label: 'GridOS', id: 'GRIDOS' },
  { label: 'Participant', id: 'PARTICIPANT' },
];
const marketObjectiveOpts = [
  { label: 'Minimize Losses', value: 'LOSS' },
  { label: 'Minimize Costs', value: 'COST' },
  { label: 'Minimize PV Curtailments', value: 'PV' },
  { label: 'Minimize PV Curtailments via BESS', value: 'PV_BESS' },
];
const financialModelOpts = [
  { label: 'DLMP', id: 'DLMP' },
  { label: 'LMP+D', id: 'LMPD' },
  { label: 'Pay as bid', id: 'PAY_AS_BID' },
  { label: 'Pay as clear', id: 'PAY_AS_CLEAR' },
];

enum marketObjective {
  'LOSS' = 'LOSS',
  'COST' = 'COST',
  'PV' = 'PV',
  'PV_BESS' = 'PV_BESS',
}

const Settings = () => {
  const { programID } = useParams<{ programID: string }>();

  const [edited, setEdited] = useState(false);
  const [program, setProgram] = useState<Program | null>(null); // Keep track of program here so that we can mutate
  const [selectedMarketObjective, setMarketObjective] = useState<
    marketObjective | undefined
  >(undefined);
  const [saveMessages, setSaveMessages] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [needToShowModal, setNeedToShowModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { updateProgram } = useContext(ProgramsContext);

  const history = useHistory();
  const { currencies, locales } = useLocaleData();

  const { loading: programLoading } = useRequestEffect<Program>({
    url: `/api/dsp/program/${programID}`,
    method: 'get',
    dataTransform: (data: Program) => {
      // Add defaults since we didn't migrate data
      if (data.system_market_info === null) {
        data.system_market_info = {
          avoided_generation_capacity_cost: undefined,
          num_forecasted_peak_events: undefined,
          renewable_energy_credit: undefined,
          system_peak_load: undefined,
          system_peak_threshold: undefined,
          transmission_loss: undefined,
        };
      }
      return data;
    },
    onSuccess: (data) => {
      if (data) {
        setProgram(data);
      }
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 404) {
        history.push('/');
      }
    },
    toast: {
      error: 'Could not load program settings',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { data: ISOs, loading: loadingISOs } = useRequestEffect<
    SelectInterface[]
  >({
    url: '/api/dsp/program/isos',
    method: 'get',
    refetchOnChange: [],
    initialData: [],
    dataTransform: (data: Array<string>) =>
      data.map((label) => ({ label, value: label })),
    toast: {
      error: 'Could not load list of ISOs.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const updateProp = (key: ProgramKey, value: any) => {
    if (program === null || program[key] === value) {
      // No change happened
      return;
    }
    setEdited(true);
    setProgram({
      ...program,
      [key]: value,
    });
  };

  const updateMarketInfoProp = (key: MarketKey, value: any) => {
    if (program?.system_market_info[key] === value) {
      // No change happened
      return;
    }

    setEdited(true);
    // @ts-expect-error
    setProgram({
      ...program,
      system_market_info: {
        // @ts-expect-error
        ...program.system_market_info,
        [key]: value,
      },
    });
  };

  const { makeRequest: runSave, loading: saving } = useRequest(
    `/api/dsp/program/${programID}`
  );

  const saveProgram = async () => {
    // Cannot send the workspace_name & program_id fields otherwise a 400 occurs
    const toSave: any = {
      ...program,
    };
    delete toSave.workspace_name;
    delete toSave.program_id;
    delete toSave.iso_id;
    delete toSave.system_market_info.id;
    delete toSave.system_market_info.program_id;
    await runSave({
      method: 'patch',
      body: toSave,
      toast: {
        error: 'Could not update program settings.',
        success: 'Successfully saved program settings.',
        settings: {
          autoDismiss: true,
        },
      },
      blockRequest: undefined,
      dataTransform: undefined,
      onError: undefined,
      onSuccess: (data: any) => {
        setSaveMessages({});
        setProgram(data);
        setEdited(false);
        updateProgram(data.program_id, data);
      },
    });
  };

  const loading = programLoading || loadingISOs;
  const valid = !!program?.name;

  const updateMarketObjectiveSettings = (
    newMarketObjective?: marketObjective
  ) => {
    setMarketObjective(newMarketObjective);
    if (newMarketObjective === marketObjective.LOSS) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'ANY');
      updateProp('market_control_strategy', 'LOSS_OPTIMIZE_ENROLLED_ASSETS');
    } else if (newMarketObjective === marketObjective.COST) {
      updateProp('financial_model', 'DLMP');
      updateProp('battery_control', 'ANY');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    } else if (newMarketObjective === marketObjective.PV) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'PARTICIPANT');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    } else if (newMarketObjective === marketObjective.PV_BESS) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'GRIDOS');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    }
  };

  useEffect(() => {
    if (!program) {
      return;
    }

    // See if we match one of the market objectives & select if we do
    const financialModel = program.financial_model;
    const batteryControl = program.battery_control;
    const marketControlStrategy = program.market_control_strategy;
    let newMarketObjective = undefined;

    if (
      financialModel === 'LMPD' &&
      batteryControl === 'ANY' &&
      marketControlStrategy === 'LOSS_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.LOSS;
    } else if (
      financialModel === 'DLMP' &&
      batteryControl === 'ANY' &&
      marketControlStrategy === 'COST_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.COST;
    } else if (
      financialModel === 'LMPD' &&
      batteryControl === 'PARTICIPANT' &&
      marketControlStrategy === 'COST_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.PV;
    } else if (
      financialModel === 'LMPD' &&
      batteryControl === 'GRIDOS' &&
      marketControlStrategy === 'COST_OPTIMIZE_ENROLLED_ASSETS'
    ) {
      newMarketObjective = marketObjective.PV_BESS;
    }

    if (selectedMarketObjective !== newMarketObjective) {
      setMarketObjective(newMarketObjective);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  if (program && program.financial_model !== 'LMPD') {
    updateProp('skip_nodal_losses_calculation', false);
  }

  return (
    <HeaderLayout
      className="program-settings"
      title="Program Settings"
      titleRightContent={
        <Button
          disabled={!edited || saving || !valid}
          label="Save"
          onClick={() => {
            if (needToShowModal) {
              setShowModal(true);
            } else {
              saveProgram();
            }
          }}
        />
      }
    >
      <Fragment>
        {loading && <LoadingSpinner />}
        {!loading && program && (
          <Tabs
            tabs={[
              { name: 'Basic' },
              { name: 'Financial Model' },
              { name: 'Advanced' },
            ]}
          >
            {(TabPanel) => [
              <TabPanel key="tab0">
                <div className="column">
                  <div className="row">
                    <TextInput
                      disabled={saving}
                      id="name"
                      invalid={!!saveMessages.name}
                      required
                      label="Program Name"
                      onChange={(value) => updateProp('name', value)}
                      placeholder="Name"
                      validationMessage={
                        saveMessages.name ? saveMessages.name[0] : undefined
                      }
                      value={program.name}
                    />
                  </div>
                  <div className="row">
                    <TextInput
                      disabled
                      id="workspace"
                      label="Workspace"
                      placeholder="Workspace Name"
                      value={program.workspace_name}
                    />
                  </div>
                  <div className="row">
                    <Select
                      isClearable={false}
                      isMulti={false}
                      isDisabled={saving}
                      isSearchable
                      label="Currency"
                      options={currencies}
                      onChange={(opt) => updateProp('currency', opt.value)}
                      value={currencies.find(
                        ({ value }) => value === program.currency
                      )}
                    />
                  </div>
                  <div className="row">
                    <TextInput
                      disabled={saving}
                      id="external_account_id"
                      invalid={!!saveMessages.external_account_id}
                      label="External Account ID"
                      onChange={(value) =>
                        updateProp('external_account_id', value)
                      }
                      placeholder="External Account ID"
                      value={program.external_account_id?.toString() || ''}
                      validationMessage={
                        saveMessages.external_account_id
                          ? saveMessages.external_account_id[0]
                          : undefined
                      }
                      resetEditState={saving}
                    />
                  </div>
                  <div className="row">
                    <TextInput
                      disabled={saving}
                      id="external_account_id"
                      invalid={!!saveMessages.external_account_id}
                      label="External Account ID"
                      onChange={(value) =>
                        updateProp('external_account_id', value)
                      }
                      placeholder="External Account ID"
                      value={program.external_account_id?.toString() || ''}
                      validationMessage={
                        saveMessages.external_account_id
                          ? saveMessages.external_account_id[0]
                          : undefined
                      }
                      resetEditState={saving}
                    />
                  </div>
                  <div className="row">
                    <Select
                      isClearable={false}
                      isMulti={false}
                      isDisabled={saving}
                      isSearchable
                      label="Locale"
                      options={locales}
                      onChange={(opt) => updateProp('locale', opt.value)}
                      value={locales.find(
                        ({ value }) => value === program.locale
                      )}
                    />
                  </div>
                  <div className="row">
                    <Select
                      isClearable={false}
                      isMulti={false}
                      isDisabled={saving}
                      isSearchable
                      label="Timezone"
                      options={timezoneOpts}
                      onChange={(opt) => updateProp('timezone', opt.value)}
                      value={timezoneOpts.find(
                        ({ value }) => value === program.timezone
                      )}
                    />
                  </div>
                  <div className="row">
                    <Select
                      isClearable={false}
                      isMulti={false}
                      isDisabled={saving}
                      label="Same Day Event Duration"
                      options={eventDurationOpts}
                      onChange={(opt) =>
                        updateProp('sameday_event_duration', opt.value)
                      }
                      value={eventDurationOpts.find(
                        ({ value }) => value === program.sameday_event_duration
                      )}
                    />
                  </div>
                  <div className="row">
                    <Select
                      isClearable={false}
                      isMulti={false}
                      isDisabled={saving}
                      label="Same Day Event Method"
                      options={eventMethodOpts}
                      onChange={(opt) =>
                        updateProp('sameday_event_method', opt.value)
                      }
                      value={eventMethodOpts.find(
                        ({ value }) => value === program.sameday_event_method
                      )}
                    />
                  </div>
                  <div className="row">
                    <NumberInput
                      id="dispatch_grace_period"
                      label="Dispatch Grace Period"
                      onChange={(value) =>
                        updateProp('dispatch_grace_period', value)
                      }
                      required
                      unit="s"
                      min={0}
                      value={program.dispatch_grace_period}
                    />
                  </div>
                  {!program.constraint_energy_management && (
                    <div className="row">
                      <Select
                        isClearable={false}
                        isMulti={false}
                        isDisabled={saving}
                        label="ISO"
                        options={ISOs}
                        onChange={(opt) => {
                          updateProp('iso', opt.value);
                          setNeedToShowModal(true);
                        }}
                        value={ISOs?.find(({ value }) => value === program.iso)}
                      />
                    </div>
                  )}
                  <div className="row">
                    <Checkbox
                      disabled={saving}
                      checked={program.is_activated}
                      onClick={() =>
                        updateProp('is_activated', !program.is_activated)
                      }
                    />
                    Enable Market
                  </div>
                </div>
              </TabPanel>,
              <TabPanel key="tab1">
                <div className="column">
                  <div className="row">
                    <Select
                      isClearable
                      isMulti={false}
                      isDisabled={saving}
                      label="Default Market Objectives"
                      options={marketObjectiveOpts}
                      onChange={(opt) => {
                        if (opt) {
                          updateMarketObjectiveSettings(
                            marketObjective[
                              opt.value as keyof typeof marketObjective
                            ]
                          );
                        } else {
                          updateMarketObjectiveSettings(undefined);
                        }
                      }}
                      value={marketObjectiveOpts.find(
                        ({ value }) => value === selectedMarketObjective
                      )}
                    />
                  </div>
                  <div className="objective-settings">
                    <div className="row">
                      <RadioButtonGroup
                        id="bcontrol"
                        listType="column"
                        label="Battery Control"
                        onChange={(value) =>
                          updateProp('battery_control', value)
                        }
                        options={batteryControlOpts}
                        value={program.battery_control}
                      />
                    </div>
                    <div className="row">
                      <RadioButtonGroup
                        id="fmodel"
                        listType="column"
                        label="Financial Model"
                        onChange={(value) =>
                          updateProp('financial_model', value)
                        }
                        options={financialModelOpts}
                        value={program.financial_model}
                      />
                    </div>

                    <div className="row">
                      <RadioButtonGroup
                        id="market-control-strategy"
                        listType="column"
                        label="Market Control Strategy"
                        onChange={(value) =>
                          updateProp('market_control_strategy', value)
                        }
                        options={[
                          {
                            id: 'LOSS_OPTIMIZE_ENROLLED_ASSETS',
                            label: 'Loss-Optimize Enrolled Assets',
                          },
                          {
                            id: 'COST_OPTIMIZE_ENROLLED_ASSETS',
                            label: 'Cost-Optimize Enrolled Assets',
                          },
                        ]}
                        value={program.market_control_strategy}
                      />
                    </div>
                  </div>
                </div>
                {program.financial_model === 'LMPD' && (
                  <div className="column">
                    <div className="row">
                      <NumberInput
                        id="agcc"
                        label="Avoided Generation Capacity Cost"
                        onChange={(value) =>
                          updateMarketInfoProp(
                            'avoided_generation_capacity_cost',
                            value
                          )
                        }
                        required
                        unit={`${program.currency}/MW-yr`}
                        value={
                          program.system_market_info
                            .avoided_generation_capacity_cost
                        }
                      />
                    </div>
                    <div className="row">
                      <NumberInput
                        id="pk"
                        label="Forecasted System Peak Events"
                        onChange={(value) =>
                          updateMarketInfoProp(
                            'num_forecasted_peak_events',
                            value
                          )
                        }
                        required
                        value={
                          program.system_market_info.num_forecasted_peak_events
                        }
                      />
                    </div>
                    <div className="row">
                      <NumberInput
                        id="pk-load"
                        label="Forecasted System Peak Load"
                        onChange={(value) =>
                          updateMarketInfoProp('system_peak_load', value)
                        }
                        required
                        unit="MW"
                        value={program.system_market_info.system_peak_load}
                      />
                    </div>
                    <div className="row">
                      <NumberInput
                        id="system-peak"
                        label="System Peak"
                        onChange={(value) =>
                          updateMarketInfoProp('system_peak_threshold', value)
                        }
                        required
                        unit="%"
                        value={program.system_market_info.system_peak_threshold}
                      />
                    </div>
                    <div className="row">
                      <NumberInput
                        id="tx-loss"
                        label="Transmission Loss"
                        onChange={(value) =>
                          updateMarketInfoProp('transmission_loss', value)
                        }
                        required
                        unit="%"
                        value={program.system_market_info.transmission_loss}
                      />
                    </div>
                    <div className="row">
                      <NumberInput
                        id="Rec"
                        label="Renewable Energy Credit (REC)"
                        onChange={(value) =>
                          updateMarketInfoProp('renewable_energy_credit', value)
                        }
                        required
                        unit={`${program.currency}/MWh`}
                        value={
                          program.system_market_info.renewable_energy_credit
                        }
                      />
                    </div>
                  </div>
                )}
              </TabPanel>,
              <TabPanel key="tab2">
                <div className="column">
                  <div className="row">
                    <RadioButtonGroup
                      id="event-confirmation"
                      label="Event Confirmation"
                      listType="column"
                      onChange={(value) =>
                        updateProp('auto_confirm_enabled', value === 'enabled')
                      }
                      options={[
                        {
                          id: 'disabled',
                          label: 'I will confirm events myself',
                        },
                        { id: 'enabled', label: 'Auto-confirm all events' },
                      ]}
                      value={
                        program.auto_confirm_enabled ? 'enabled' : 'disabled'
                      }
                    />
                  </div>
                  <div className="row">
                    <Checkbox
                      disabled={saving}
                      checked={program.allow_reverse_active_powerflow}
                      onClick={() =>
                        updateProp(
                          'allow_reverse_active_powerflow',
                          !program.allow_reverse_active_powerflow
                        )
                      }
                    />
                    Allow Reverse Active Powerflow
                  </div>
                  <div className="row">
                    <Checkbox
                      disabled={saving}
                      checked={program.allow_reverse_reactive_powerflow}
                      onClick={() =>
                        updateProp(
                          'allow_reverse_reactive_powerflow',
                          !program.allow_reverse_reactive_powerflow
                        )
                      }
                    />
                    Allow Reverse Reactive Powerflow
                  </div>
                  <div className="row">
                    <Checkbox
                      disabled={saving}
                      checked={program.generate_nodal_load_forecasts}
                      onClick={() =>
                        updateProp(
                          'generate_nodal_load_forecasts',
                          !program.generate_nodal_load_forecasts
                        )
                      }
                    />
                    Generate Nodal Load Forecasts
                  </div>
                  <div className="row">
                    <Checkbox
                      disabled={saving || !(program.financial_model === 'LMPD')}
                      checked={program.skip_nodal_losses_calculation}
                      onClick={() =>
                        updateProp(
                          'skip_nodal_losses_calculation',
                          !program.skip_nodal_losses_calculation
                        )
                      }
                    />
                    Skip Nodal Losses Calculation
                  </div>
                  <div className="row">
                    <Checkbox
                      disabled={saving}
                      checked={program.constraint_energy_management}
                      onClick={() =>
                        updateProp(
                          'constraint_energy_management',
                          !program.constraint_energy_management
                        )
                      }
                    />
                    Constraint Energy Management
                  </div>
                  <div className="row">
                    <Checkbox
                      disabled={saving}
                      checked={program.is_simulation}
                      onClick={() =>
                        updateProp('is_simulation', !program.is_simulation)
                      }
                    />
                    Simulation
                  </div>
                </div>
              </TabPanel>,
            ]}
          </Tabs>
        )}
      </Fragment>
      <Modal
        active={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => saveProgram()}
        title="Confirm Program ISO Change"
        width="300px"
      >
        <div className="confirm-message">
          <div>{`Are you sure you wish to change the ISO for Program: ${program?.name}? This action will clear the mapped ISO Zones for all enrolled feeders.`}</div>
        </div>
      </Modal>
    </HeaderLayout>
  );
};

export default Settings;
