import React, { useState, Fragment } from 'react';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import HeaderLayout from 'components/HeaderLayout';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import { WeatherStation } from 'types/weather';

import WeatherStationCard from './WeatherStationCard';
import './Weather.scss';

const Weather = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [name, setName] = useState('');

  const {
    data: weatherStations,
    loading,
    refetch: reloadStations,
  } = useRequestEffect<WeatherStation[]>({
    url: '/api/dsp/weather/stations',
    method: 'get',
    toast: {
      error: 'Could not load list of weather stations',
    },
  });
  const onDeleteStation = () => reloadStations();

  const { loading: saving, makeRequest: runSave } = useRequest(
    '/api/dsp/weather/station'
  );
  const addWeatherStation = async (
    stationName: string,
    lat: number,
    long: number
  ) => {
    await runSave({
      method: 'post',
      body: {
        name: stationName,
        latitude: lat,
        longitude: long,
      },
      onSuccess: () => {
        setModalOpen(false);
        setName('');
        setLatitude('');
        setLongitude('');
        reloadStations();
      },
      toast: {
        error: 'Failed to create weather station',
        success: `Successfully created weather station ${stationName}`,
      },
    });
  };

  const valueNotInRange = (value: string, min: number, max: number) => {
    const parsed = parseFloat(value);

    if (isNaN(parsed)) {
      return false;
    }

    return parsed < min || parsed > max;
  };

  const invalidLatitude = valueNotInRange(latitude, -90, 90);
  const invalidLongitude = valueNotInRange(longitude, -180, 180);

  return (
    <HeaderLayout
      className="weather"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
          ]}
          separator="/"
          currentHeader="Weather Stations"
        />
      }
      titleRightContent={
        <Button disabled={modalOpen} onClick={() => setModalOpen(true)}>
          Add New Weather Station
        </Button>
      }
    >
      <div className="weather-station-list">
        {!loading &&
          weatherStations &&
          weatherStations.map((station) => (
            <WeatherStationCard
              deleteStation={onDeleteStation}
              key={station.name}
              station={station}
            />
          ))}
      </div>
      <Modal
        active={modalOpen}
        disableConfirm={!name || invalidLatitude || invalidLongitude}
        labels={{ cancel: 'Cancel', confirm: 'Save' }}
        onCancel={() => setModalOpen(false)}
        onConfirm={async () => {
          const floatLatitude = parseFloat(latitude);
          const floatLongitude = parseFloat(longitude);
          await addWeatherStation(name, floatLatitude, floatLongitude);
        }}
        title="Enter New Weather Station Name"
        width="300px"
      >
        <Fragment>
          <TextInput
            className="create-station-text-input"
            disabled={saving}
            id="Name"
            invalid={!name}
            label="Name"
            onChange={(value) => setName(value)}
            required
            value={name}
          />
          <TextInput
            className="create-station-text-input"
            disabled={saving}
            id="Latitiude"
            invalid={invalidLatitude}
            label="Latitude"
            onChange={(value) => setLatitude(value)}
            required
            validationMessage={
              invalidLatitude
                ? 'Latitude must be a number in the range [-90, 90].'
                : ''
            }
            value={latitude}
          />
          <TextInput
            className="create-station-text-input"
            disabled={saving}
            id="Longitude"
            invalid={invalidLongitude}
            label="Longitude"
            onChange={(value) => setLongitude(value)}
            required
            validationMessage={
              invalidLongitude
                ? 'Longitude must be a number in the range [-180, 180].'
                : ''
            }
            value={longitude}
          />
        </Fragment>
      </Modal>
    </HeaderLayout>
  );
};

export default Weather;
