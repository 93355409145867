import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ISOList from './ISOList';
import AddISO from './routes/AddISO';
import ISO from './routes/ISO';

const DataSourcesContainer = () => {
  return (
    <Switch>
      <Route exact path="/isos">
        <ISOList />
      </Route>
      <Route exact path="/isos/add">
        <AddISO />
      </Route>
      <Route path="/isos/:isoID">
        <ISO />
      </Route>
    </Switch>
  );
};

export default DataSourcesContainer;
