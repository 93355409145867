import flatpickr from 'flatpickr';
import { Instance as FlatPickrInstance } from 'flatpickr/dist/types/instance';
import { Options as FlatPickrOptions } from 'flatpickr/dist/types/options';
import isEqual from 'lodash.isequal';
import { DateTime } from 'luxon';
import React, { Component } from 'react';

import './DateRangePicker.scss';

type DatePickerProps = {
  startDate: DateTime;
  endDate: DateTime;
  dateFormat?: string;
  disabled?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  onChange: (startDate: DateTime, endDate: DateTime) => void;
  options: FlatPickrOptions;
};

type DatePickerState = {
  startDate: DateTime;
  endDate: DateTime;
};

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
 */
class DatePicker extends Component<DatePickerProps, DatePickerState> {
  public static defaultProps = {
    date: null,
    disabled: false,
    options: {},
    dateFormat: 'M j, Y',
  };

  calendar: FlatPickrInstance | null;
  dateInput: HTMLInputElement | null;

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      endDate: this.props.endDate || DateTime.local(),
      startDate: this.props.startDate || DateTime.local(),
    };

    this.calendar = null;
    this.dateInput = null;
  }

  componentDidMount() {
    this.createDateField(this.state.startDate, this.state.endDate);
  }

  componentDidUpdate(prevProps: DatePickerProps) {
    // If calendar is controlled, update the internal date state
    const { calendar, props } = this;
    const { startDate, endDate, options } = props;

    if (
      calendar &&
      (endDate.valueOf() !== prevProps.endDate.valueOf() ||
        startDate.valueOf() !== prevProps.startDate.valueOf())
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        endDate,
        startDate,
      });
      // calendar.setDate(date.toLocaleString());
      calendar.setDate([startDate.valueOf(), endDate.valueOf()]);
      this.createDateField(startDate, endDate);
    }

    // this.createDateField may have recreated the calendar
    // Need to use this.calendar after this point
    if (!isEqual(prevProps.options, options) && this.calendar) {
      Object.keys(options).forEach((key) => {
        // @ts-ignore
        this.calendar.set(key, options[key]);
      });
      this.calendar.redraw();
    }
  }

  componentWillUnmount() {
    if (this.calendar) {
      this.calendar.destroy();
    }
  }

  createDateField = (startDate: DateTime, endDate: DateTime) => {
    const { calendar, dateInput, props } = this;
    let options = { ...props.options };
    const { maxDate, minDate } = props;
    const { dateFormat } = props;

    if (minDate && maxDate) {
      options = {
        ...options,
        minDate: minDate.valueOf(),
        maxDate: maxDate.valueOf(),
      };
    }

    // @ts-ignore
    this.calendar = flatpickr(dateInput, {
      time_24hr: true,
      minuteIncrement: 1,
      dateFormat,
      mode: 'range',
      onChange: (dates: Date[]) =>
        this.handleDateChange(dates.map((d) => DateTime.fromJSDate(d))),
      defaultDate: [startDate.toJSDate(), endDate.toJSDate()],
      ...options,
    });
    if (calendar && calendar.calendarContainer) {
      calendar.calendarContainer.classList.add('DatePicker-calendar-instance');
    }
  };

  handleDateChange = (dates: DateTime[]) => {
    if (dates.length === 1) {
      // This occurs during the intermediate range selection
      // so ignore for now
      return;
    }
    // User selected a range
    const startDate = dates[0];
    const endDate = dates[1];
    this.setState({
      startDate,
      endDate,
    });
    this.props.onChange(startDate, endDate);
  };

  render() {
    const { disabled } = this.props;
    return (
      <div className="date-range-picker">
        <input
          className="date-range-picker__input"
          name="startDate"
          disabled={disabled}
          ref={(div) => {
            this.dateInput = div;
          }}
        />
      </div>
    );
  }
}

export default DatePicker;
