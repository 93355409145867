import React, { ReactNode } from 'react';

import './Badge.scss';

type BadgeProps = {
  badgeType: 'error' | 'success';
  children: string | ReactNode | null;
};

const Badge = ({ badgeType, children }: BadgeProps) => (
  <span className={`badge ${badgeType}`}>{children}</span>
);

export default Badge;
