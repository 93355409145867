import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import Card from 'components/Card';
import IconButton from 'components/IconButton';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';

import { WeatherStation } from 'types/weather';
import './WeatherStationCard.scss';

interface WeatherStationCardProps {
  deleteStation: (id: number) => void;
  station: WeatherStation;
}

const WeatherStationCard = ({
  deleteStation,
  station,
}: WeatherStationCardProps) => {
  const [showModal, setShowModal] = useState(false);

  const { makeRequest: deleteStationRequest, loading: deleting } = useRequest(
    `/api/dsp/weather/station/${station.id}`
  );

  const deleteProgramHandler = async () => {
    await deleteStationRequest({
      method: 'delete',
      toast: {
        error: `Could not delete weather station '${station.name}'.`,
        success: `Successfully removed weather station ${station.name}.`,
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        deleteStation(station.id);
        setShowModal(false);
      },
      onError: () => {
        setShowModal(false);
      },
    });
  };

  return (
    <Fragment>
      <Card
        buttonGroup={
          <IconButton
            disabled={deleting}
            icon="delete"
            onClick={() => setShowModal(true)}
            tooltip="Delete station"
          />
        }
        className="weather-station-card"
        title={
          <Link to={`/measurements/weather/station/${station.name}`}>
            <h3 style={{ fontSize: 36, margin: 0, padding: 0 }}>
              {station.name}
            </h3>
          </Link>
        }
      >
        <TextInput
          id={`${station.id}-lat`}
          disabled
          label="Latitude"
          value={station.latitude.toString()}
        />
        <TextInput
          id={`${station.id}-lon`}
          disabled
          label="Longitude"
          value={station.longitude.toString()}
        />
      </Card>
      <Modal
        active={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => deleteProgramHandler()}
        title="Confirm Weather Station Deletion"
        width="300px"
      >
        <div className="confirm-message">
          <div>{`Are you sure you wish to delete Weather station: ${station.name}? This action is permanent.`}</div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default WeatherStationCard;
