import React from 'react';

import './AnalysisFailedOverlay.scss';

const AnalysisFailedOverlay = () => (
  <div className="analysis-failed-overlay">
    <div className="icon-container">
      <i className="material-icons">warning</i>
    </div>
    <div className="text-container">No data. Analysis has failed.</div>
  </div>
);

export default AnalysisFailedOverlay;
