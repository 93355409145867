import React, { ReactNode } from 'react';
import classNames from 'classnames';

type CardProps = {
  buttonGroup?: ReactNode;
  children?: ReactNode;
  className?: string;
  hideTitle?: boolean;
  renderFooter?: () => ReactNode;
  secondaryTitle?: string;
  showFooter?: boolean;
  title?: ReactNode;
};

const Card = ({
  buttonGroup,
  children,
  className,
  hideTitle,
  renderFooter,
  secondaryTitle,
  showFooter = false,
  title,
}: CardProps) => (
  <div className={classNames('card', className)}>
    <header className={!hideTitle ? 'border' : ''}>
      <div className="card-menu">
        {!hideTitle && (
          <div className="title-menu">
            <h3 className="title">{title}</h3>
            <h4 className="secondary-title">{secondaryTitle}</h4>
          </div>
        )}
      </div>
      {buttonGroup && <div className="button-menu">{buttonGroup}</div>}
    </header>
    {children && (
      <div className={`content ${hideTitle ? 'no-border' : ''}`}>
        {children}
      </div>
    )}
    {showFooter && renderFooter && (
      <div className="footer">{renderFooter()}</div>
    )}
  </div>
);

export default Card;
