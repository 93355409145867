import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import DateRangePicker from 'components/DateRangePicker';
import LMPChart from 'components/LMPChart';
import Select from 'components/Select';
import SystemLoadChart from 'components/SystemLoadChart';
import IconButton from 'components/IconButton';

import fileExportSave from 'helpers/downloadFile';

import './ISOCharts.scss';

type Zone = {
  id: any;
  name: string;
};

type ISOChartsProps = {
  currency: string;
  isoName: string;
  locale: string;
  timezone: string;
  zones: Zone[];
};

const ISOCharts = ({
  currency,
  isoName,
  locale,
  timezone,
  zones,
}: ISOChartsProps) => {
  const sorter = Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  const zoneOptions = zones
    .map(({ id, name }) => ({ label: name, value: id }))
    .sort((a, b) => sorter.compare(a.label, b.label));

  const [startTime, setStartTime] = useState(
    DateTime.local().setZone(timezone).startOf('day')
  );
  const [endTime, setEndTime] = useState(
    DateTime.local().setZone(timezone).endOf('day')
  );
  const [zone, setZone] = useState(
    zoneOptions.length ? zoneOptions[0].value : null
  );

  const nodeName = zones
    .filter(({ id }) => id === zone)
    .reduce((acc, { name }) => {
      if (acc) {
        return acc;
      }
      return name;
    }, '');

  const { loading: downloadingSLF, makeRequest: runExportSLF } = useRequest(
    `/api/dsp/iso/${isoName}/system_load_forecast/export`
  );
  const exportSLF = async () => {
    await runExportSLF({
      method: 'get',
      onSuccess: (data, headers) => {
        fileExportSave(data, headers);
      },
      // Axios params
      params: {
        start_time: startTime.toISO(),
        end_time: endTime.endOf('day').toISO(),
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const { loading: downloadingLMP, makeRequest: runExportLMP } = useRequest(
    `/api/dsp/iso/${isoName}/${nodeName}/export/lmp`
  );
  const exportLMP = async () => {
    await runExportLMP({
      method: 'get',
      onSuccess: (data, headers) => {
        fileExportSave(data, headers);
      },
      // Axios params
      params: {
        start_time: startTime.toISO(),
        end_time: endTime.endOf('day').toISO(),
      },
      timeout: 120000, // 2 min timeout
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  return (
    <div className="iso-charts">
      <div className="input-control-container">
        <DateRangePicker
          endDate={endTime}
          startDate={startTime}
          onChange={(start, end) => {
            setStartTime(start);
            setEndTime(end);
          }}
        />
      </div>
      <div className="input-control-container">
        Download System Load
        <IconButton
          disabled={downloadingSLF}
          icon="get_app"
          onClick={() => exportSLF()}
          tooltip="Download System Load Forecast"
        />
      </div>
      <SystemLoadChart
        start_time={startTime}
        end_time={endTime.endOf('day')}
        isoName={isoName}
        timezone={timezone}
      />
      <div className="input-control-container limited-width">
        <Select
          isClearable={false}
          isMulti={false}
          onChange={({ value }: { value: any }) => setZone(value)}
          options={zoneOptions}
          row
          value={zoneOptions.find((z) => z.value === zone)}
        />
        <IconButton
          disabled={downloadingLMP}
          icon="get_app"
          onClick={() => exportLMP()}
          tooltip="Download LMP"
        />
      </div>
      <LMPChart
        start_time={startTime}
        end_time={endTime.endOf('day')}
        currency={currency}
        isoName={isoName}
        locale={locale}
        nodeName={nodeName}
        timezone={timezone}
        sameDayDuration={3600}
        showZero={false}
      />
    </div>
  );
};

export default ISOCharts;
