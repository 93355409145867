import React, { useState, Fragment, WheelEvent, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import {
  apm,
  Request,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
import LoadingSpinner from 'components/LoadingSpinner';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import Checkbox from 'components/Checkbox';

import { Address, AddressKey, Participant } from 'types/participant';
import './Participant.scss';

interface SelectInterface {
  label: string;
  value: any;
}

const ParticipantComponent = () => {
  const history = useHistory();
  const params: { participantID?: string } = useParams();
  const participantID = params.participantID
    ? parseInt(params.participantID)
    : null;

  // Keep track of the participant & options here so that we can mutate the state during edits
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [unmappedDEROptions, setUnmappedDEROptions] = useState<
    Array<SelectInterface>
  >([]);

  const [edited, setEdited] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessages, setSaveMessages] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [updatingAssets, setUpdatingAssets] = useState(false);
  const [newRdfID, setNewRdfID] = useState(null);

  const participantInfoContainerRef = useRef<HTMLDivElement>(null);
  const scrollParticipantInfo = (scrollEvent: WheelEvent) => {
    if (participantInfoContainerRef && participantInfoContainerRef.current) {
      participantInfoContainerRef.current.scroll(
        0,
        participantInfoContainerRef.current.scrollTop + scrollEvent.deltaY
      );
    }
  };

  const { loading: participantLoading } = useRequestEffect<Participant | null>({
    url: `/api/dsp/participants/${participantID}`,
    initialData: null,
    method: 'get',
    refetchOnChange: [participantID],
    toast: {
      error: 'Failed to participant.',
      settings: {
        autoDismiss: true,
      },
    },
    onError: () => {
      history.push('/participants');
    },
    onSuccess: (data) => {
      if (data) {
        setParticipant(data);
      }
    },
  });

  const { loading: dersLoading } = useRequestEffect({
    url: '/api/dsp/der/unmapped',
    initialData: [],
    method: 'get',
    refetchOnChange: [],
    toast: {
      error: 'Failed to load DERs.',
      settings: {
        autoDismiss: true,
      },
    },
    onSuccess: (data: Array<{ name: string; rdf_id: string }> | undefined) => {
      if (data) {
        setUnmappedDEROptions(
          data.map(({ name, rdf_id }) => ({ label: name, value: rdf_id }))
        );
      }
    },
  });

  const updateKey = (key: string, value: any) => {
    setEdited(true);
    // @ts-ignore
    setParticipant({
      ...participant,
      [key]: value,
    });
  };
  const updateNestedKey = (topKey: string, key: string, value: any) => {
    setEdited(true);
    // @ts-ignore
    setParticipant({
      ...participant,
      [topKey]: {
        // @ts-ignore
        ...participant[topKey],
        [key]: value,
      },
    });
  };
  const updateAddress = (type: string, key: AddressKey, value: any) => {
    setEdited(true);
    // @ts-ignore
    setParticipant({
      ...participant,
      [type]: {
        // @ts-ignore
        ...participant[type],
        address: {
          // @ts-ignore
          ...participant[type].address,
          [key]: value,
        },
      },
    });
  };

  const addressValid = (address: Address) =>
    address.line_1 &&
    address.locality &&
    address.administrative_area &&
    address.country;

  const valid =
    participant?.name &&
    participant?.email.email &&
    participant?.billing_account_id &&
    participant?.company.phone_number &&
    addressValid(participant?.company.address) &&
    participant?.invoicee.name &&
    participant?.invoicee.phone_number &&
    addressValid(participant?.invoicee.address);

  const saveParticipant = async () => {
    const request = new Request(`/api/dsp/participants/${participantID}`);

    setSaving(true);

    try {
      const { data: updatedParticipant } = await request.patch({
        ...participant,
        email: participant?.email.email, // BE API needs this flattened out
      });
      setSaveMessages({});
      setParticipant(updatedParticipant);
      setEdited(false);
    } catch (error) {
      const { response } = error;

      if (response.status === 400) {
        setSaveMessages(response.data.message);
      } else if (response.status === 409) {
        // Happens when the billing account ID already exists
        setSaveMessages({
          billing_account_id: [response.data.message],
        });
      }
      apm.captureError(error);
    }

    setSaving(false);
  };

  const addDERToParticipant = async () => {
    const request = new Request(`/api/dsp/der`);
    setUpdatingAssets(true);

    try {
      const { data: newDER } = await request.post({
        customer_id: participant?.id,
        email: participant?.email.email,
        enabled: true,
        rdf_id: newRdfID,
      });

      // @ts-ignore
      setParticipant({
        ...participant,
        ders: [...(participant?.ders || []), newDER],
      });
      const index = unmappedDEROptions.findIndex(
        (der: SelectInterface) => der.value === newRdfID
      );

      if (index !== -1) {
        const newDERMapOptions = [...unmappedDEROptions];
        newDERMapOptions.splice(index, 1);
        setUnmappedDEROptions(newDERMapOptions);
      }
      setNewRdfID(null);
    } catch (error) {
      apm.captureError(error);
    }

    setUpdatingAssets(false);
  };

  const removeDERFromParticipant = async (derID: number) => {
    const request = new Request(`/api/dsp/der/${derID}`);
    setUpdatingAssets(true);

    try {
      await request.delete();

      const index = participant?.ders.findIndex((der) => der.id === derID);

      if (typeof index === 'undefined') {
        return;
      }

      const newDERs = [...(participant?.ders || [])];
      newDERs.splice(index, 1);

      // @ts-ignore
      setParticipant({
        ...participant,
        ders: newDERs,
      });
    } catch (error) {
      apm.captureError(error);
    }

    setUpdatingAssets(false);
  };

  const loading = participantLoading || dersLoading;

  return (
    <HeaderLayout
      className="participant"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/participants',
              label: <h2 className="title">Market Participants</h2>,
            },
          ]}
          separator="/"
          currentHeader={participant ? participant.name : ''}
        />
      }
      titleRightContent={
        <Button
          disabled={loading || !edited || saving || !valid}
          onClick={() => saveParticipant()}
        >
          Save
        </Button>
      }
    >
      <Fragment>
        {!loading && (
          <div className="column-container">
            <div className="column">
              <div
                className="scrollable-column"
                ref={participantInfoContainerRef}
              >
                <div className="row">
                  <div className="flex-column-header-wrapper">
                    <h3 className="column-header">Company</h3>
                    <div>
                      <Checkbox
                        disabled={saving}
                        id="is_dso"
                        checked={participant?.is_dso || false}
                        onClick={(event) =>
                          updateKey('is_dso', event.currentTarget.checked)
                        }
                      />
                      is DSO
                    </div>
                  </div>
                  <TextInput
                    disabled={saving}
                    id="name"
                    label="Company Name"
                    value={participant?.name || ''}
                    placeholder="Company Name"
                    onChange={(value) => updateKey('name', value)}
                    required
                    invalid={!!saveMessages.name}
                    validationMessage={
                      saveMessages.name ? saveMessages.name[0] : undefined
                    }
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="email"
                    label="Email"
                    value={participant?.email.email || ''}
                    placeholder="Email"
                    onChange={(value) =>
                      updateNestedKey('email', 'email', value)
                    }
                    invalid={!!saveMessages.email}
                    validationMessage={
                      saveMessages.email ? saveMessages.email[0] : undefined
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="billing_account_id"
                    label="Account ID"
                    value={participant?.billing_account_id || ''}
                    placeholder="Account ID"
                    onChange={(value) => updateKey('billing_account_id', value)}
                    invalid={!!saveMessages.billing_account_id}
                    validationMessage={
                      saveMessages.billing_account_id
                        ? saveMessages.billing_account_id[0]
                        : undefined
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="external_account_id"
                    label="External Account ID"
                    value={participant?.external_account_id || ''}
                    placeholder="External Account ID"
                    onChange={(value) =>
                      updateKey('external_account_id', value)
                    }
                    invalid={!!saveMessages.external_account_id}
                    validationMessage={
                      saveMessages.external_account_id
                        ? saveMessages.external_account_id[0]
                        : undefined
                    }
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="phone_number"
                    label="Phone Number"
                    value={participant?.company.phone_number || ''}
                    placeholder="Phone Number"
                    onChange={(value) =>
                      updateNestedKey('company', 'phone_number', value)
                    }
                    required
                    resetEditState={saving}
                    invalid={!!saveMessages.name}
                    validationMessage={
                      saveMessages.name ? saveMessages.name[0] : undefined
                    }
                  />
                  <TextInput
                    disabled={saving}
                    id="line_1"
                    label="Street Address"
                    value={participant?.company.address.line_1 || ''}
                    placeholder="Street Address"
                    onChange={(value) =>
                      updateAddress('company', 'line_1', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="line_2"
                    label=""
                    value={participant?.company.address.line_2 || ''}
                    placeholder="Suite, unit, building, floor, etc."
                    onChange={(value) =>
                      updateAddress('company', 'line_2', value)
                    }
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="locality"
                    label="City"
                    value={participant?.company.address.locality || ''}
                    placeholder="City"
                    onChange={(value) =>
                      updateAddress('company', 'locality', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="administrative_area"
                    label="State / Province"
                    value={
                      participant?.company.address.administrative_area || ''
                    }
                    placeholder="State / Province"
                    onChange={(value) =>
                      updateAddress('company', 'administrative_area', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="postal_code"
                    label="Zip / Postal Code"
                    value={participant?.company.address.postal_code || ''}
                    placeholder="Zip / Postal Code"
                    onChange={(value) =>
                      updateAddress('company', 'postal_code', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="country"
                    label="Country"
                    value={participant?.company.address.country || ''}
                    placeholder="Country"
                    onChange={(value) =>
                      updateAddress('company', 'country', value)
                    }
                    required
                    resetEditState={saving}
                  />
                </div>

                <div className="row">
                  <h3 className="column-header">Invoicee</h3>
                  <TextInput
                    disabled={saving}
                    id="invoicee_name"
                    label="Name"
                    value={participant?.invoicee.name || ''}
                    placeholder="Company Name"
                    onChange={(value) =>
                      updateNestedKey('invoicee', 'name', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_phone_number"
                    label="Phone Number"
                    value={participant?.invoicee.phone_number || ''}
                    placeholder="Phone Number"
                    onChange={(value) =>
                      updateNestedKey('invoicee', 'phone_number', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_line_1"
                    label="Street Address"
                    value={participant?.invoicee.address.line_1 || ''}
                    placeholder="Street Address"
                    onChange={(value) =>
                      updateAddress('invoicee', 'line_1', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_line_2"
                    label=""
                    value={participant?.invoicee.address.line_2 || ''}
                    placeholder="Suite, unit, building, floor, etc."
                    onChange={(value) =>
                      updateAddress('invoicee', 'line_2', value)
                    }
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_locality"
                    label="City"
                    value={participant?.invoicee.address.locality || ''}
                    placeholder="City"
                    onChange={(value) =>
                      updateAddress('invoicee', 'locality', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_administrative_area"
                    label="State / Province"
                    value={
                      participant?.invoicee.address.administrative_area || ''
                    }
                    placeholder="State / Province"
                    onChange={(value) =>
                      updateAddress('invoicee', 'administrative_area', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_postal_code"
                    label="Zip / Postal Code"
                    value={participant?.invoicee.address.postal_code || ''}
                    placeholder="Zip / Postal Code"
                    onChange={(value) =>
                      updateAddress('invoicee', 'postal_code', value)
                    }
                    required
                    resetEditState={saving}
                  />
                  <TextInput
                    disabled={saving}
                    id="invoicee_country"
                    label="Country"
                    value={participant?.invoicee.address.country || ''}
                    placeholder="Country"
                    onChange={(value) =>
                      updateAddress('invoicee', 'country', value)
                    }
                    required
                    resetEditState={saving}
                  />
                </div>
              </div>
            </div>

            <div
              className="column"
              onWheel={(event) => scrollParticipantInfo(event)}
            >
              <h3 className="column-header" style={{ marginBottom: '25px' }}>
                Assets
              </h3>
              <div className="add-der-section">
                <Select
                  disabled={
                    loading || saving || updatingAssets || newRdfID === null
                  }
                  isClearable
                  isMulti={false}
                  options={unmappedDEROptions}
                  onChange={(option) =>
                    setNewRdfID(option ? option.value : null)
                  }
                  value={
                    unmappedDEROptions.find(
                      (opt: SelectInterface) => opt.value === newRdfID
                    ) || null
                  }
                />
                <IconButton
                  disabled={
                    loading || saving || updatingAssets || newRdfID === null
                  }
                  icon="add"
                  onClick={() => addDERToParticipant()}
                  tooltip="Add DER to Participant"
                />
              </div>
              <ul className="customer-asset-list">
                {participant?.ders.map((der) => (
                  <li className="customer-asset-list-item" key={der.rdf_id}>
                    <Link to={`/ders/${der.rdf_id}`}>{der.info.name}</Link>
                    <IconButton
                      disabled={loading || saving || updatingAssets}
                      icon="delete"
                      onClick={() => removeDERFromParticipant(der.id)}
                      tooltip="Remove DER from Participant"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {(loading || saving) && (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default ParticipantComponent;
