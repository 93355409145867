/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint jsx-a11y/no-static-element-interactions: off */
import React, { ReactChild } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';

import './Modal.scss';

interface ModalProps {
  active?: boolean;
  children?: ReactChild;
  disableCancel?: boolean;
  disableConfirm?: boolean;
  height?: string;
  labels?: {
    cancel: string | ReactChild;
    confirm: string | ReactChild;
  };
  onCancel?: () => void;
  onConfirm?: () => void;
  reverseFooterButtons?: boolean;
  showCancel?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
  title?: string;
  width?: string;
}

/**
 * Stateless component that create a modal overlay and a modal body.
 * Status is controlled by container component.
 * Requires 2 callback function:
 *   - onConfirm - For clicks on the confirm button
 *   - onCancel - For clicks on the cancel button or overlay
 * Header and footer can be removed using the props showHeader and showFooter
 * Accepts any valid JSX as children in the modal body
 */

const Modal = ({
  active = false,
  children,
  disableCancel = false,
  disableConfirm = false,
  height = 'auto',
  labels = { cancel: 'Cancel', confirm: 'Confirm' },
  onCancel,
  onConfirm,
  reverseFooterButtons = false,
  showCancel = true,
  showFooter = true,
  showHeader = true,
  title = '',
  width = 'auto',
}: ModalProps) => {
  const cancelBtn = (
    <Button
      className="modal-footer__btn modal-footer__btn--cancel"
      onClick={onCancel}
      label={labels.cancel || 'Cancel'}
      disabled={disableCancel}
      key="cancel-btn"
      type="secondary"
    />
  );

  const confirmBtn = (
    <Button
      onClick={onConfirm}
      className="modal-footer__btn modal-footer__btn--confirm"
      label={labels.confirm || 'Confirm'}
      disabled={disableConfirm}
      key="confirm-btn"
      type="primary"
    />
  );

  const getFooterButtons = () => {
    if (!showCancel) {
      return confirmBtn;
    }
    return reverseFooterButtons
      ? [confirmBtn, cancelBtn]
      : [cancelBtn, confirmBtn];
  };

  return (
    <div className="modal">
      <div
        className={classNames({
          'modal-overlay': true,
          'modal-overlay--active': active,
        })}
        onClick={onCancel}
      />
      <div className="modal-body-wrapper">
        <div
          style={{ width: width, height: height }}
          className={`modal-body modal-body--${active && 'active'}`}
        >
          {showHeader && (
            <div className="modal-header">
              <p>{title}</p>
            </div>
          )}

          <div className="modal-message modal-message">{children}</div>

          {showFooter && (
            <div
              className={classNames({
                'modal-footer': true,
                'modal-footer--centered': !showCancel,
              })}
            >
              {getFooterButtons()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
