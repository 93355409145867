import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import FileForm from 'components/FileForm';
import IconButton from 'components/IconButton';
import LoadingSpinner from 'components/LoadingSpinner';
import NumberInput from 'components/NumberInput';
import Modal from 'components/Modal';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import Tabs from 'components/Tabs';

import TimeZones from 'helpers/timezones';
import useLocaleData from 'hooks/useLocaleData';

import ISOCharts from './ISOCharts';
import './ISO.scss';

const timezoneOpts = TimeZones.map((name) => ({ label: name, value: name }));
const HOUR_OPTIONS = Array(24)
  .fill(undefined)
  .map((v, i) => ({
    label: i,
    value: i,
  }));
const MINUTE_OPTIONS = [
  { label: '00', value: 0 },
  { label: '05', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '35', value: 35 },
  { label: '40', value: 40 },
  { label: '45', value: 45 },
  { label: '50', value: 50 },
  { label: '55', value: 55 },
];

interface Zone {
  created_at: string;
  id: number;
  iso_id: number;
  name: string;
  updated_at: string;
}

interface ISO {
  currency: string;
  dayahead_generation_time: string;
  id: number;
  locale: string;
  name: string;
  sameday_data_delay?: number;
  sameday_generation_lead_time?: number;
  timezone: string;
  zones: Array<Zone>;
}

interface SelectInterface {
  label: string;
  value: string;
}

const ISOComponent = () => {
  const { isoID } = useParams<{ isoID: string }>();
  const [edited, setEdited] = useState(false);
  const [lmpRef, setLMPRef] = useState<TextInput | null>(null);
  const [slfRef, setSLFRef] = useState<TextInput | null>(null);
  const [ISO, setISO] = useState<ISO | null>(null);
  const [inUploadForecastMode, setInUploadForecastMode] = useState(false);
  const [fileForecastData, setFileForecastData] = useState(new FormData());
  const [filenameForecast, setFilenameForecast] = useState('');
  const [hasForecastData, setHasForecastData] = useState(false);
  const [inUploadLMPMode, setInUploadLMPMode] = useState(false);
  const [fileLMPData, setFileLMPData] = useState(new FormData());
  const [filenameLMP, setFilenameLMP] = useState('');
  const [hasLMPData, setHasLMPData] = useState(false);

  const uploadForecastFormRef = useRef<HTMLFormElement | null>(null);
  const uploadLMPFormRef = useRef<HTMLFormElement | null>(null);

  const history = useHistory();

  const { currencies, locales } = useLocaleData();

  const { loading } = useRequestEffect<ISO>({
    url: `/api/dsp/iso/${isoID}`,
    method: 'get',
    refetchOnChange: [isoID],
    onSuccess: (data) => {
      if (data) {
        setISO(data);
      }
    },
    onError: (error: any) => {
      if (error.response && error.response.status === 404) {
        history.push('/isos');
      }
    },
    toast: {
      error: 'Could not load ISO settings',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { makeRequest: runSave, loading: saving } = useRequest(
    `/api/dsp/iso/${isoID}`
  );

  const saveISO = async () => {
    if (ISO === null) {
      return;
    }

    const toSave: any = {
      currency: ISO.currency,
      dayahead_generation_time: ISO.dayahead_generation_time,
      locale: ISO.locale,
      name: ISO.name,
      sameday_data_delay: ISO.sameday_data_delay,
      sameday_generation_lead_time: ISO.sameday_generation_lead_time,
      timezone: ISO.timezone,
    };
    await runSave({
      method: 'patch',
      body: toSave,
      toast: {
        error: 'Could not update ISO settings.',
        success: 'Successfully saved ISO settings.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: (data: any) => {
        setISO(data);
        setEdited(false);
      },
    });
  };

  const deleteISO = async () => {
    if (ISO === null) {
      return;
    }

    await runSave({
      method: 'delete',
      toast: {
        error: 'Could not delete ISO.',
        success: 'Successfully deleted ISO.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        // Redirect back up
        history.push('/isos');
      },
    });
  };

  const cancelUploadForecast = () => {
    setInUploadForecastMode(false);
    setFileForecastData(new FormData());
    setFilenameForecast('');
    setHasForecastData(false);

    if (uploadForecastFormRef.current) {
      uploadForecastFormRef.current.reset();
    }
  };

  const {
    makeRequest: runUploadForecast,
    loading: runningUploadForecast,
  } = useRequest(
    `/api/dsp/iso/${ISO ? ISO.name : ''}/system_load_forecast/import`
  );

  const uploadForecastData = async () => {
    await runUploadForecast({
      method: 'post',
      body: fileForecastData,
      dataTransform: undefined,
      blockRequest: undefined,
      onSuccess: () => {
        cancelUploadForecast(); // exits upload mode
      },
      onError: undefined,
      toast: {
        error: 'Could not upload forecast data.',
        success: 'Successfully uploaded forecast data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const cancelUploadLMP = () => {
    setInUploadLMPMode(false);
    setFileLMPData(new FormData());
    setFilenameLMP('');
    setHasLMPData(false);

    if (uploadLMPFormRef.current) {
      uploadLMPFormRef.current.reset();
    }
  };

  const { makeRequest: runUploadLMP, loading: runningUploadLMP } = useRequest(
    `/api/dsp/iso/${ISO ? ISO.name : ''}/lmp/import`
  );

  const uploadLMPData = async () => {
    await runUploadLMP({
      method: 'post',
      body: fileLMPData,
      dataTransform: undefined,
      blockRequest: undefined,
      onSuccess: () => {
        cancelUploadLMP(); // exits upload mode
      },
      onError: undefined,
      toast: {
        error: 'Could not upload LMP data.',
        success: 'Successfully uploaded LMP data.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { origin } = window.location;
  const lmpEndpoint = `${origin}/api/dsp/iso/${ISO?.name}/locational_marginal_price`;
  const slfEndpoint = `${origin}/api/dsp/iso/${ISO?.name}/system_load_forecast`;
  let daGenerationTime = DateTime.fromFormat('00:00:00', 'H:mm:ss');

  if (ISO) {
    daGenerationTime = DateTime.fromFormat(
      ISO?.dayahead_generation_time,
      'H:mm:ss'
    );
  }

  return (
    <div className="iso">
      <div className="title-bar">
        <Breadcrumbs
          parents={[
            {
              to: '/isos',
              label: <h2 className="title">Data Sources</h2>,
            },
          ]}
          separator=">"
          currentHeader={`ISO: ${ISO?.name || ''}`}
        />
        <div className="title-right">
          <Button
            className="delete-button"
            disabled={saving}
            label="Delete"
            onClick={() => deleteISO()}
          />
          <Button
            disabled={!edited || saving}
            label="Save"
            onClick={() => saveISO()}
          />
        </div>
      </div>
      <div className="content">
        {loading && <LoadingSpinner />}
        {!loading && ISO !== null && (
          <Tabs
            tabs={[
              {
                name: 'Settings',
                disabled: false,
              },
              {
                name: 'Charts',
                disabled: false,
              },
            ]}
          >
            {(TabPanel) => [
              <TabPanel className="settings-tab" key="settings">
                <div className="iso-container">
                  <div className="inputs">
                    <div className="input-container">
                      <TextInput
                        disabled={saving}
                        id="name"
                        invalid={!ISO.name}
                        label="ISO Name"
                        onChange={(value) => {
                          setEdited(true);
                          setISO({
                            ...ISO,
                            name: value,
                          });
                        }}
                        placeholder="Name"
                        required
                        value={ISO.name || ''}
                      />
                    </div>
                    <div className="input-container">
                      <Select
                        isClearable={false}
                        isMulti={false}
                        isDisabled={saving}
                        isSearchable
                        label="Timezone"
                        options={timezoneOpts}
                        onChange={(opt: SelectInterface) => {
                          setEdited(true);
                          setISO({
                            ...ISO,
                            timezone: opt.value,
                          });
                        }}
                        value={timezoneOpts.find(
                          ({ value }) => value === ISO.timezone
                        )}
                      />
                    </div>
                    <div className="input-container">
                      <Select
                        isClearable={false}
                        isMulti={false}
                        isDisabled={saving}
                        isSearchable
                        label="Currency"
                        options={currencies}
                        onChange={(opt: SelectInterface) => {
                          setEdited(true);
                          setISO({
                            ...ISO,
                            currency: opt.value,
                          });
                        }}
                        value={currencies.find(
                          ({ value }) => value === ISO.currency
                        )}
                      />
                    </div>
                    <div className="input-container">
                      <Select
                        isClearable={false}
                        isMulti={false}
                        isDisabled={saving}
                        isSearchable
                        label="Locale"
                        options={locales}
                        onChange={(opt: SelectInterface) => {
                          setEdited(true);
                          setISO({
                            ...ISO,
                            locale: opt.value,
                          });
                        }}
                        value={locales.find(
                          ({ value }) => value === ISO.locale
                        )}
                      />
                    </div>
                    <div className="input-container time-container">
                      <div className="label">Day Ahead Generation Time</div>
                      <div className="time-row">
                        <Select
                          isClearable={false}
                          isMulti={false}
                          isDisabled={saving}
                          label="Hour"
                          options={HOUR_OPTIONS}
                          onChange={(opt) => {
                            setEdited(true);
                            setISO({
                              ...ISO,
                              dayahead_generation_time: daGenerationTime
                                .set({ hour: opt.value })
                                .toFormat('H:mm:ss'),
                            });
                          }}
                          row
                          value={HOUR_OPTIONS.find(
                            ({ value }) => value === daGenerationTime.hour
                          )}
                        />
                        <Select
                          isClearable={false}
                          isMulti={false}
                          isDisabled={saving}
                          label="Minute"
                          options={MINUTE_OPTIONS}
                          onChange={(opt) => {
                            setEdited(true);
                            setISO({
                              ...ISO,
                              dayahead_generation_time: daGenerationTime
                                .set({ minute: opt.value })
                                .toFormat('H:mm:ss'),
                            });
                          }}
                          row
                          value={MINUTE_OPTIONS.find(
                            ({ value }) => value === daGenerationTime.minute
                          )}
                        />
                      </div>
                    </div>
                    <div className="input-container">
                      <NumberInput
                        disabled={loading || saving}
                        id=""
                        label="Same Day Generation Lead Time"
                        min={0}
                        onChange={(value) => {
                          setEdited(true);
                          setISO({
                            ...ISO,
                            sameday_generation_lead_time: value,
                          });
                        }}
                        required
                        unit="s"
                        validationMessage="Number must be a multiple of 300 (5 minutes)"
                        invalid={
                          ISO.sameday_generation_lead_time
                            ? ISO.sameday_generation_lead_time % 300 !== 0
                            : false
                        }
                        value={ISO.sameday_generation_lead_time}
                      />
                    </div>
                    <div className="input-container">
                      <NumberInput
                        disabled={loading || saving}
                        id=""
                        label="Same Day Market Data Delay"
                        min={0}
                        onChange={(value) => {
                          setEdited(true);
                          setISO({
                            ...ISO,
                            sameday_data_delay: value,
                          });
                        }}
                        unit="s"
                        value={ISO.sameday_data_delay}
                      />
                    </div>
                    <div className="link-container">
                      <TextInput
                        disabled
                        id="lmp-endpoint"
                        label="LMP Endpoint"
                        ref={(input) => setLMPRef(input)}
                        value={lmpEndpoint}
                      />
                      <IconButton
                        icon="file_copy"
                        onClick={() => {
                          if (lmpRef) {
                            lmpRef.copyValueToClipboard();
                          }
                        }}
                      />
                      <IconButton
                        icon={inUploadLMPMode ? 'close' : 'create'}
                        onClick={() => {
                          setInUploadLMPMode(!inUploadLMPMode);
                          if (inUploadLMPMode) {
                            // We are exiting upload mode
                            cancelUploadLMP();
                          }
                        }}
                        tooltip={inUploadLMPMode ? 'Close' : 'Upload LMP Data'}
                      />
                    </div>
                    <div className="link-container">
                      <TextInput
                        disabled
                        id="slf-endpoint"
                        label="System Load Endpoint"
                        ref={(input) => setSLFRef(input)}
                        value={slfEndpoint}
                      />
                      <IconButton
                        icon="file_copy"
                        onClick={() => {
                          if (slfRef) {
                            slfRef.copyValueToClipboard();
                          }
                        }}
                      />
                      <IconButton
                        icon={inUploadForecastMode ? 'close' : 'create'}
                        onClick={() => {
                          setInUploadForecastMode(!inUploadForecastMode);
                          if (inUploadForecastMode) {
                            // We are exiting upload mode
                            cancelUploadForecast();
                          }
                        }}
                        tooltip={
                          inUploadForecastMode
                            ? 'Close'
                            : 'Upload Forecast Data'
                        }
                      />
                    </div>
                  </div>
                  <Modal
                    active={inUploadForecastMode}
                    disableCancel={runningUploadForecast}
                    disableConfirm={!hasForecastData || runningUploadForecast}
                    height="250px"
                    labels={{
                      cancel: 'Cancel',
                      confirm: runningUploadForecast ? (
                        <i className="material-icons rotating-icon">refresh</i>
                      ) : (
                        'Save'
                      ),
                    }}
                    onCancel={cancelUploadForecast}
                    onConfirm={() => uploadForecastData()}
                    title="Upload Forecast Data"
                  >
                    <div className="upload-form">
                      <FileForm
                        accept="*.csv"
                        createRef={(form) =>
                          (uploadForecastFormRef.current = form)
                        }
                        id="uploadForecast"
                        onChange={(e) => {
                          //@ts-ignore
                          const { files } = e.target;

                          const forecastFile = files[0];

                          // Assume .csv file is valid, allow API to reject
                          const forecastKey = 'forecast';
                          fileForecastData.set(forecastKey, forecastFile);
                          setFilenameForecast(forecastFile.name);
                          setHasForecastData(true);
                        }}
                      >
                        <div className="select-btn">Select File</div>
                      </FileForm>
                      <div className="filename">{filenameForecast}</div>
                    </div>
                  </Modal>
                  <Modal
                    active={inUploadLMPMode}
                    disableCancel={runningUploadLMP}
                    disableConfirm={!hasLMPData || runningUploadLMP}
                    height="250px"
                    labels={{
                      cancel: 'Cancel',
                      confirm: runningUploadLMP ? (
                        <i className="material-icons rotating-icon">refresh</i>
                      ) : (
                        'Save'
                      ),
                    }}
                    onCancel={cancelUploadLMP}
                    onConfirm={() => uploadLMPData()}
                    title="Upload LMP Data"
                  >
                    <div className="upload-form">
                      <FileForm
                        accept="*.csv"
                        createRef={(form) => (uploadLMPFormRef.current = form)}
                        id="uploadLMP"
                        onChange={(e) => {
                          //@ts-ignore
                          const { files } = e.target;

                          const lmpFile = files[0];
                          const lmpKey = 'lmp';
                          fileLMPData.set(lmpKey, lmpFile);
                          setFilenameLMP(lmpFile.name);
                          setHasLMPData(true);
                        }}
                      >
                        <div className="select-btn">Select File</div>
                      </FileForm>
                      <div className="filename">{filenameLMP}</div>
                    </div>
                  </Modal>
                </div>
              </TabPanel>,
              <TabPanel className="chart-tab" key="charts">
                <div className="chart-container">
                  <ISOCharts
                    currency={ISO.currency}
                    isoName={ISO.name}
                    locale={ISO.locale}
                    timezone={ISO.timezone || ''}
                    zones={ISO.zones || []}
                  />
                </div>
              </TabPanel>,
            ]}
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default ISOComponent;
