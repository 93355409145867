import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ProgramsContext } from 'contexts/ProgramsContext';

import Breadcrumbs from 'components/Breadcrumbs';
import AssetForecastPanel from 'components/AssetForecastPanel';
import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Tabs from 'components/Tabs';

import { FinancialModel } from 'types/program';

import { useAssetEnrollmentInfo } from '../../api';
import AssetConfig from './AssetConfig';
import AssetLMPDSettings from './AssetLMPDSettings';

import './AssetSettings.scss';

const AssetSettings = () => {
  const { programID, assetID } = useParams<{
    programID: string;
    assetID: string;
  }>();

  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);

  const {
    enrollment: der,
    enrollmentLoading: derLoading,
    error: derError,
  } = useAssetEnrollmentInfo(programID, assetID);
  const derType = der?.info?.der_type;
  const hasConfig =
    derType === 'PhotoVoltaic' ||
    derType === 'Wind' ||
    derType === 'Hydro' ||
    derType === 'BESS' ||
    (derType && derType.includes('Load'));
  const hasForecast =
    derType === 'PhotoVoltaic' ||
    derType === 'Wind' ||
    derType === 'Hydro' ||
    (derType && derType.includes('Load (Curtailing)'));
  const hasGeneratedForecast = derType === 'PhotoVoltaic';
  const lmpdDisabled = program?.financial_model !== FinancialModel.LMPD;

  return derLoading || derError ? (
    <LoadingSpinner />
  ) : (
    <HeaderLayout
      className="asset-settings"
      title={
        <Breadcrumbs
          parents={[
            {
              to: `/program/${programID}/enrollment`,
              label: <h2 className="title">Program Enrollment</h2>,
            },
          ]}
          separator=">"
          currentHeader={
            `${der?.info?.name}` +
            (der?.info?.der_type.includes('Load')
              ? ` (${der?.info?.usage_point_type} Load)`
              : ` (${der?.info?.der_type})`)
          }
        />
      }
    >
      <Tabs
        tabs={[
          {
            disabled: !hasConfig,
            disabledTooltip:
              'Only PV, Batteries, and Hydro assets have market operator controlled configuration',
            name: 'Asset Configuration',
          },
          {
            disabled: lmpdDisabled,
            disabledTooltip:
              'Program is not configured for LMP+D financial model.',
            name: 'Asset LMP+D Settings',
          },
          {
            disabled: !hasForecast,
            disabledTooltip:
              'Only PV, Wind, Hydro, and Curtailing DR assets have forecasts',
            name: 'Asset Forecast',
          },
        ]}
      >
        {(TabPanel) => [
          <TabPanel key="tab0">
            {program && der && (
              <AssetConfig
                assetID={assetID}
                assetType={der.info?.der_type}
                programID={programID}
              />
            )}
          </TabPanel>,
          <TabPanel key="tab1">
            {program && (
              <AssetLMPDSettings assetID={assetID} programID={programID} />
            )}
          </TabPanel>,
          <TabPanel key="tab2">
            {program && assetID && (
              <AssetForecastPanel
                derName={der?.info?.name || ''}
                derType={derType}
                hasGeneratedForecast={hasGeneratedForecast}
                program_id={program.program_id}
                rdf_id={assetID}
                timezone={program.timezone}
              />
            )}
          </TabPanel>,
        ]}
      </Tabs>
    </HeaderLayout>
  );
};

export default AssetSettings;
