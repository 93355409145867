import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Participants from './Participants';
import AddParticipant from './routes/AddParticipant';
import Participant from './routes/Participant';

const ParticipantsContainer = () => {
  return (
    <Switch>
      <Route exact path="/participants">
        <Participants />
      </Route>
      <Route exact path="/participants/create">
        <AddParticipant />
      </Route>
      <Route path="/participants/:participantID">
        <Participant />
      </Route>
    </Switch>
  );
};

export default ParticipantsContainer;
