import React, { FunctionComponent } from 'react';

import ChartWrapper from 'components/ChartWrapper';
import { ResolvedBidOffer } from 'types';

import './SupplyStackGraph.scss';
import { ChartDataset, ScatterDataPoint } from 'chart.js';

type SupplyStackGraphProps = {
  currencyFormatter: Intl.NumberFormat;
  currencySymbol: string;
  offers: ResolvedBidOffer[];
  title: string;
  xLabel: string;
};

const SupplyStackGraph: FunctionComponent<SupplyStackGraphProps> = ({
  currencyFormatter,
  currencySymbol,
  offers,
  title,
  xLabel,
}) => {
  offers.sort((a, b) => {
    const price1 = a.price || 0;
    const price2 = b.price || 0;

    if (price1 < price2) {
      return -1;
    } else if (price1 > price2) {
      return 1;
    }
    return 0;
  });

  const data: ScatterDataPoint[] = [];
  let powerSum = 0.0;

  offers.forEach((offer) => {
    data.push({
      x: powerSum,
      y: offer.price ?? NaN,
    });
    powerSum += offer.quantity || 0.0;
  });

  // Add one extra point to extend the line
  data.push({
    x: powerSum,
    y: offers[offers.length - 1]?.price ?? NaN,
  });

  const dataset: ChartDataset = {
    label: 'Supply Stack',
    backgroundColor: '#00467F',
    borderColor: '#00467F',
    fill: false,
    stepped: 'before',
    pointRadius: 0,
    data,
  };

  return (
    <div className="supply-stack-graph">
      <ChartWrapper
        config={{
          type: 'line',
          data: {
            datasets: [dataset],
            labels: [],
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: true,
                position: 'top',
                text: title,
              },
              tooltip: {
                intersect: false,
                mode: 'nearest',
                axis: 'x',
                callbacks: {
                  title: (tooltipItems) => {
                    const { parsed } = tooltipItems[0];
                    return `${(parsed.x / 1e3).toFixed(0)} kW`;
                  },
                  label: (tooltipItem) => {
                    let value = tooltipItem.parsed.y;
                    value = Math.round(value * 100) / 100;
                    return `${currencyFormatter.format(value)} /MW`;
                  },
                },
              },
            },
            scales: {
              x: {
                type: 'linear',
                min: 0,
                suggestedMax: powerSum || 10000,
                title: {
                  display: true,
                  text: xLabel,
                },
                ticks: {
                  // @ts-expect-error
                  callback: (value) => `${(value / 1e3).toFixed(0)}`,
                },
              },
              y: {
                suggestedMin: 0,
                grid: {
                  drawBorder: false,
                },
                title: {
                  display: true,
                  text: `Price (${currencySymbol}/MW)`,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default SupplyStackGraph;
