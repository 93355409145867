import { useState } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

type LocaleData = {
  label: string;
  value: string;
};

type CurrencyAndLocales = {
  currency: LocaleData[];
  locale: LocaleData[];
};

export default function useLocaleData() {
  const [currencies, setCurrencies] = useState<LocaleData[]>([]);
  const [locales, setLocales] = useState<LocaleData[]>([]);

  useRequestEffect<CurrencyAndLocales>({
    url: '/currency_and_locales.json',
    method: 'get',
    onSuccess: (data) => {
      if (data) {
        setCurrencies(data.currency);
        setLocales(data.locale);
      }
    },
  });

  return {
    currencies,
    locales,
  };
}
