import React from 'react';

import './ZoomControls.scss';

interface ZoomControlProps {
  isTilted: boolean;
  orientationChanged: boolean;
  resetOrientation?: () => void;
  resetTilt?: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
}

const ZoomControls = (props: ZoomControlProps) => (
  <div className="zoom-controls" style={{ top: '20px', left: '20px' }}>
    <div className="button-pair">
      <button
        className="zoom-controls__button zoom-controls-plus zoom-controls-top material-icons"
        onClick={props.zoomIn}
        title="Zoom In"
      >
        add
      </button>
      <button
        className="zoom-controls__button zoom-controls-minus zoom-controls-bottom material-icons"
        onClick={props.zoomOut}
        title="Zoom Out"
      >
        remove
      </button>
    </div>

    {props.resetTilt && props.resetOrientation && (
      <div className="button-pair">
        <button
          className="zoom-controls__button zoom-controls-top"
          onClick={props.resetTilt}
          title={props.isTilted ? 'Reset Tilt' : ''}
          disabled={!props.isTilted}
        >
          <i className="material-icons" style={{ transform: 'rotate(90deg)' }}>
            360
          </i>
        </button>
        <button
          className="zoom-controls__button zoom-controls-bottom material-icons"
          onClick={props.resetOrientation}
          title={props.orientationChanged ? 'Reset Orientation' : ''}
          disabled={!props.orientationChanged}
        >
          compass_calibration
        </button>
      </div>
    )}
  </div>
);

export default ZoomControls;
