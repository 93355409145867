import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  apm,
  Request,
  useAuthContext,
} from '@opusonesolutions/gridos-app-framework';

export interface Program {
  constraint_energy_management: boolean;
  currency: string;
  financial_model: 'LMPD' | 'DLMP' | 'PAY_AS_BID' | 'PAY_AS_CLEAR';
  iso: string;
  iso_id: number;
  locale: string;
  name: string;
  program_id: number;
  sameday_event_duration: number;
  timezone: string;
  workspace_name: string;
}

interface ProgramsCtx {
  addProgram(program: Program): void;
  deleteProgram(id: number): void;
  getProgram(id?: string | number | null): Program | null;
  getProgramName(id: number): string | null;
  programs: Program[];
  updateProgram(id: number, program: Program): void;
}

export const ProgramsContext = createContext<ProgramsCtx>({
  addProgram() {},
  deleteProgram() {},
  getProgram() {
    return null;
  },
  getProgramName() {
    return null;
  },
  programs: [],
  updateProgram() {},
});

const programSorter = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const processPrograms = (programs: Program[]): Program[] => {
  // @ts-ignore
  return programs.sort((a, b) => programSorter.compare(a.name, b.name));
};

interface ProgramsContextProviderProps {
  children: ReactNode;
}

const ProgramsContextProvider = ({
  children,
}: ProgramsContextProviderProps) => {
  const { isAuthenticated } = useAuthContext();
  const [programs, setPrograms] = useState<Program[]>([]);
  const onLogin = useRouteMatch('/login');

  useEffect(() => {
    if (onLogin !== null || !isAuthenticated) {
      return;
    }

    let didCancel = false;

    (async () => {
      const request = new Request(`/api/dsp/program`);
      try {
        const { data } = await request.get();

        if (didCancel) {
          return;
        }

        const programs = processPrograms(data);
        setPrograms(programs);
      } catch (error) {
        apm.captureError(error);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [onLogin, isAuthenticated]);

  const addProgram = (program: Program) => {
    const newPrograms = [...programs, program];
    newPrograms.sort((a, b) => programSorter.compare(a.name, b.name));
    setPrograms(newPrograms);
  };

  const deleteProgram = (id: number) => {
    const index = programs.findIndex((p) => p.program_id === id);
    if (index !== -1) {
      const newPrograms = [...programs];
      newPrograms.splice(index, 1);
      setPrograms(newPrograms);
    }
  };

  const getProgram = (id: string | number | null | undefined) => {
    if (id !== null && typeof id !== 'undefined' && id !== '') {
      let parsedID = id;

      if (typeof id === 'string') {
        parsedID = Number.parseInt(id);
      }
      const index = programs.findIndex((p) => p.program_id === parsedID);
      if (index !== -1) {
        return programs[index];
      }
    }
    return null;
  };

  const getProgramName = (id: number) => {
    const index = programs.findIndex((p) => p.program_id === id);
    if (index !== -1) {
      return programs[index].name;
    }
    return null;
  };

  const updateProgram = (id: number, program: Program) => {
    const index = programs.findIndex((p) => p.program_id === id);
    if (index !== -1) {
      const newPrograms = [...programs];
      newPrograms[index] = {
        ...newPrograms[index],
        ...program,
      };
      setPrograms(newPrograms);
    }
  };

  return (
    <ProgramsContext.Provider
      value={{
        addProgram,
        deleteProgram,
        getProgram,
        getProgramName,
        programs,
        updateProgram,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};

export const ProgramsProvider = ProgramsContextProvider;
