const hexagonCoordinates = (coordinates: number[], zoom: number) => {
  let size = 0.0001;

  if (zoom > 18) {
    // Our max zoom is 24, at that zoom we want the size as 0.00001
    // Use linear interpolation between the zooms
    const delta = size - 0.00001;
    const interpolation = (zoom - 18) / 6;
    size = size - interpolation * delta;
  }

  const coords = [
    [coordinates[0] - 0.5 * size, coordinates[1] + (Math.sqrt(3) / 2) * size],
    [coordinates[0] + 0.5 * size, coordinates[1] + (Math.sqrt(3) / 2) * size],
    [coordinates[0] + size, coordinates[1]],
    [coordinates[0] + 0.5 * size, coordinates[1] - (Math.sqrt(3) / 2) * size],
    [coordinates[0] - 0.5 * size, coordinates[1] - (Math.sqrt(3) / 2) * size],
    [coordinates[0] - size, coordinates[1]],
    [coordinates[0] - 0.5 * size, coordinates[1] + (Math.sqrt(3) / 2) * size],
  ];
  return [coords];
};
export default hexagonCoordinates;
