// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import DeckGL, { PathLayer, ScatterplotLayer } from 'deck.gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, { FlyToInterpolator } from 'react-map-gl';

import ForecastTooltip from './ForecastTooltip';
import hexagonCoordinates from './hexagon';
import iconAtlasLight from './icon-map-light.png';
import ICON_MAPPING from './IconMapping';
import Tooltip from './Tooltip';
import ZoomControls from './ZoomControls';

import getDLMPLayers from './layers/dlmp';
import getForecastLayers from './layers/forecast';
import GridOSIconLayer from './layers/GridOSIconLayer';
import getLMPDLayers from './layers/lmpd';
import getPowerLayers from './layers/power';
import getPayAsBidLayers from './layers/payasbid';
import getPayAsClearLayers from './layers/payasclear';

if (window.WebGLRenderingContext) {
  // Tests need this defined otherwise the vertex array polyfill crashes
  // eslint-disable-next-line global-require
  require('oes-vertex-attrib-array-polyfill');
}

const MAX_BAR_HEIGHT = 100;

// The zoom level at which the assets will appear
const ASSET_ZOOM_LEVEL = 12;

const SWITCH_TYPES = new Set(['Cut', 'Disconnector', 'Jumper', 'Switch']);

const getNetworkData = (data) => {
  const iconData = [
    ...Object.values(data.nodeIcons),
    ...Object.values(data.linkIcons),
  ];

  return { data, iconData };
};

const resetState = (inputData, overlayData, mapMode, prevState) => {
  const { data, iconData } = getNetworkData(inputData);

  let latitude = 43.65075134311931;
  let longitude = -79.37286281817677;

  let minLat = Number.POSITIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;
  let minLon = Number.POSITIVE_INFINITY;
  let maxLon = Number.NEGATIVE_INFINITY;

  Object.keys(data.nodes).forEach((nodeID) => {
    const node = data.nodes[nodeID];
    const nodeCoords = node.geometry.coordinates;

    minLat = Math.min(minLat, nodeCoords[1]);
    maxLat = Math.max(maxLat, nodeCoords[1]);
    minLon = Math.min(minLon, nodeCoords[0]);
    maxLon = Math.max(maxLon, nodeCoords[0]);
  });

  if (isFinite(minLat) && isFinite(minLon)) {
    latitude = (minLat + maxLat) / 2;
    longitude = (minLon + maxLon) / 2;
  }

  let maxValue = 1;

  if (mapMode === 'VALUATION') {
    maxValue = Math.max(
      ...Object.values(overlayData).map((v) => v.dlmp || v.lmpd || 0)
    );
  } else if (mapMode === 'PWR') {
    maxValue = Math.max(...Object.values(overlayData).map((v) => v.power || 0));
  } else if (mapMode === 'FORECAST') {
    maxValue = Math.max(...Object.values(overlayData));
  }
  const scaling = MAX_BAR_HEIGHT / maxValue;

  const bearing = (prevState && prevState.bearing) || 0;
  const pitch = (prevState && prevState.pitch) || 0;
  const zoom = (prevState && prevState.zoom) || 16;

  return {
    viewState: {
      bearing,
      latitude,
      longitude,
      pitch,
      zoom,
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator(),
    },
    iconData,
    data,
    scaling,
  };
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = resetState(
      this.props.networkModel,
      this.props.overlayData,
      this.props.mapMode
    );
  }

  componentDidMount() {
    this.resizeListener = window.addEventListener('resize', () => {
      this.handleResize();
    });
  }

  componentDidUpdate(prevProps) {
    const { mapMode, networkModel, overlayData } = this.props;

    if (
      prevProps.networkModel !== networkModel ||
      prevProps.overlayData !== overlayData
    ) {
      this.setState({
        ...resetState(networkModel, overlayData, mapMode, this.state.viewState),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  componentDidCatch(err) {
    console.log(err);
  }

  getContainerName(containerID) {
    let name = '';

    this.props.containers.forEach((substation) => {
      if (substation.id === containerID) {
        name = substation.name;
      }

      substation.feeders.forEach((feeder) => {
        if (feeder.id === containerID) {
          name = feeder.name;
        }
      });
    });

    return name;
  }

  handleResize = () => {
    if (this.mapContainer) {
      const { height } = this.mapContainer.getBoundingClientRect();
      this.setState({
        viewState: {
          ...this.state.viewState,
          height,
          width: window.innerWidth,
        },
      });
    }
  };

  inSelectedContainers = (id) => {
    return this.props.selectedContainers.includes(id);
  };

  isDER = (asset_type) => {
    return (
      asset_type === 'SynchronousMachine' ||
      asset_type === 'Wind' ||
      asset_type === 'PhotoVoltaic' ||
      asset_type === 'Battery' ||
      asset_type === 'EnergySource' ||
      asset_type === 'Hydro' ||
      asset_type === 'EnergyConsumerDr'
    );
  };

  viewEnabled = (asset_type) => {
    return this.isDER(asset_type) || this.props.showAllIcons;
  };

  getLayers = () => {
    const {
      financialModel,
      mapMode,
      overlayData,
      selectedContainers,
      showAllIcons,
    } = this.props;
    const { data, iconData, scaling, viewState } = this.state;

    // Layer that creates the ConnectivityNode instances
    const nodeLayer = new ScatterplotLayer({
      parameters: { depthTest: false },
      id: 'connectivity-node-layer',
      data: Object.values(data.nodes),
      pickable: true,
      opacity: 1,
      stroked: false,
      filled: true,
      radiusScale: 1,
      radiusMaxPixels: 10,
      updateTriggers: {
        getPosition: [data],
        getRadius: [viewState.zoom, selectedContainers],
      },
      getFillColor: [0, 0, 0],
      getPosition: (d) => d.geometry.coordinates,
      getRadius: (d) => {
        if (viewState.zoom < ASSET_ZOOM_LEVEL) return 0;
        if (!this.inSelectedContainers(d.properties.feeder)) return 0;
        return 5;
      },
    });

    // Layer that creates all of the asset icons
    const iconLayer = new GridOSIconLayer({
      id: 'icon-layer',
      data: iconData,
      pickable: true,
      opacity: 1,
      iconAtlas: iconAtlasLight,
      iconMapping: ICON_MAPPING,
      sizeScale: 3,
      sizeMaxPixels: 45,
      getElevation: 0,
      extruded: false,
      updateTriggers: {
        getSize: [viewState.zoom, selectedContainers, showAllIcons],
        getAngle: [iconData],
        getPosition: [iconData],
      },
      getIcon: (d) => {
        if (SWITCH_TYPES.has(d.properties.asset_type)) {
          return d.properties.closed ? 'SwitchClosed' : 'SwitchOpen';
        } else if (d.properties.asset_type === 'Regulator') {
          return d.properties.flipIcon ? 'RegulatorFlipped' : 'Regulator';
        }

        return d.properties.asset_type;
      },
      getPosition: (d) => [...d.geometry.coordinates, 0],
      getSize: (d) => {
        if (viewState.zoom < ASSET_ZOOM_LEVEL) return 0;
        if (!this.inSelectedContainers(d.properties.feeder)) return 0;
        if (!this.viewEnabled(d.properties.asset_type)) return 0;
        return viewState.zoom ** 1.3;
      },
      getAngle: this.getAngle,
    });

    // Creates layer that draws ACLineSegments
    const pathLayer = new PathLayer({
      parameters: { depthTest: false },
      id: 'cable-layer',
      data: [...Object.values(data.lines)],
      pickable: true,
      widthScale: 1,
      widthMinPixels: 0,
      widthMaxPixels: 15,
      updateTriggers: {
        getPath: [data],
        getWidth: [viewState.zoom, selectedContainers],
      },
      getPath: (d) => d.geometry.coordinates,
      getColor: [78, 78, 78, 200],
      getWidth: (d) => {
        if (viewState.zoom < ASSET_ZOOM_LEVEL) return 0;
        if (!this.inSelectedContainers(d.properties.feeder)) return 0;
        return 5;
      },
      getDashArray: (d) => {
        if (d.properties.phase && d.properties.phase.includes('ABC'))
          return [0, 0];
        // 1 or 2 phase line. Dashed
        return [5, 5];
      },
    });

    // Creates layer that draws shunt connectors and link connectors
    const connectorLayer = new PathLayer({
      parameters: { depthTest: false },
      id: 'connector-layer',
      data: [
        ...Object.values(data.nodeConnectors),
        ...Object.values(data.linkConnectors),
      ],
      pickable: false,
      widthScale: 1,
      widthMinPixels: 0,
      widthMaxPixels: 15,
      updateTriggers: {
        getPath: [data],
        getWidth: [viewState.zoom, selectedContainers],
        getColor: [data],
      },
      getPath: (d) => d.geometry.coordinates,
      getColor: [78, 78, 78, 150],
      getWidth: (d) => {
        if (viewState.zoom < ASSET_ZOOM_LEVEL) return 0;
        if (!this.inSelectedContainers(d.properties.feeder)) return 0;
        return 5;
      },
      getDashArray: [1, 1],
    });

    const networkLayers = [pathLayer, connectorLayer, nodeLayer, iconLayer];

    const polygonLayerAssets = {
      type: 'FeatureCollection',
      features: [],
    };

    Object.keys(data.nodeIcons).forEach((shuntDeviceID) => {
      const device = data.nodeIcons[shuntDeviceID];
      const deviceCoords = device.geometry.coordinates;
      let idToUse = shuntDeviceID;
      let nameToUse = device.properties.name;

      if (
        mapMode === 'FORECAST' &&
        device.properties.asset_type === 'EnergySource'
      ) {
        // In forecast mode, feeder forecast is keyed by feeder ID
        idToUse = device.properties.feeder;
        nameToUse = this.getContainerName(idToUse);
      }

      if (overlayData[idToUse] === undefined) {
        return;
      }

      polygonLayerAssets.features.push({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: hexagonCoordinates(deviceCoords, viewState.zoom),
        },
        properties: {
          id: idToUse,
          name: nameToUse,
        },
      });
    });

    if (mapMode === 'VALUATION' && financialModel === 'DLMP') {
      const dlmpLayers = getDLMPLayers(
        polygonLayerAssets,
        scaling,
        overlayData,
        (hoveredObject, pointerX, pointerY) =>
          this.setState({
            hoveredObject,
            pointerX,
            pointerY,
          })
      );
      return networkLayers.concat(dlmpLayers);
    } else if (mapMode === 'VALUATION' && financialModel === 'LMPD') {
      const lmpdLayers = getLMPDLayers(
        polygonLayerAssets,
        scaling,
        overlayData,
        (hoveredObject, pointerX, pointerY) =>
          this.setState({
            hoveredObject,
            pointerX,
            pointerY,
          })
      );
      return networkLayers.concat(lmpdLayers);
    } else if (mapMode === 'VALUATION' && financialModel === 'PAY_AS_BID') {
      const payAsBidLayers = getPayAsBidLayers(
        polygonLayerAssets,
        scaling,
        overlayData,
        (hoveredObject, pointerX, pointerY) =>
          this.setState({
            hoveredObject,
            pointerX,
            pointerY,
          })
      );
      return networkLayers.concat(payAsBidLayers);
    } else if (mapMode === 'VALUATION' && financialModel === 'PAY_AS_CLEAR') {
      const payAsClearLayers = getPayAsClearLayers(
        polygonLayerAssets,
        scaling,
        overlayData,
        (hoveredObject, pointerX, pointerY) =>
          this.setState({
            hoveredObject,
            pointerX,
            pointerY,
          })
      );
      return networkLayers.concat(payAsClearLayers);
    } else if (mapMode === 'PWR') {
      const powerLayers = getPowerLayers(
        polygonLayerAssets,
        scaling,
        overlayData,
        (hoveredObject, pointerX, pointerY) =>
          this.setState({
            hoveredObject,
            pointerX,
            pointerY,
          })
      );
      return networkLayers.concat(powerLayers);
    } else if (mapMode === 'FORECAST') {
      const forecastLayers = getForecastLayers(
        polygonLayerAssets,
        scaling,
        overlayData,
        (hoveredObject, pointerX, pointerY) =>
          this.setState({
            hoveredObject,
            pointerX,
            pointerY,
          })
      );
      return networkLayers.concat(forecastLayers);
    }

    return networkLayers;
  };

  getAngle = ({ properties }) => {
    // Calculate the icon rotation from the terminal positions of the line
    const angle = this.getIconRotation(properties.id);
    let adjusted = angle;
    if (angle < -90) {
      adjusted = angle + 180;
    } else if (angle > 90) {
      adjusted = angle - 180;
    }
    return adjusted;
  };

  // Get the angle to rotate a link device icon
  getIconRotation = (id) => {
    if (
      this.state.data.linkIcons[id] &&
      this.state.data.linkIcons[id].properties.asset_type === 'Regulator'
    ) {
      const asset = this.state.data.linkConnectors[id];
      const coords = asset.geometry.coordinates;
      const y = coords[1][1] - coords[0][1];
      const x = coords[1][0] - coords[0][0];
      const rotation = Math.atan2(y, x);
      return rotation * (180 / Math.PI);
    }
    return 0;
  };

  handleZoom = (zoomValue) => () => {
    this.setState({
      viewState: {
        ...this.state.viewState,
        zoom: this.state.viewState.zoom + zoomValue,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      },
    });
  };

  // Want to strip off height and width here so that the map will always fill it's container
  // https://github.com/uber/react-map-gl/pull/614
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleUpdateViewport = ({ height, width, ...viewState }) => {
    this.setState({ viewState: viewState });
  };

  handleResetViewportProp = (property) =>
    this.setState({
      viewState: {
        ...this.state.viewState,
        [property]: 0,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      },
    });

  render() {
    const background = 'white';

    const {
      children,
      currency,
      financialModel,
      locale,
      mapMode,
      overlayData,
    } = this.props;
    const { hoveredObject, pointerX, pointerY, viewState } = this.state;

    return (
      <div
        style={{
          background,
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
        ref={(container) => {
          this.mapContainer = container;
        }}
      >
        <ZoomControls
          zoomIn={this.handleZoom(1)}
          zoomOut={this.handleZoom(-1)}
          resetTilt={() => this.handleResetViewportProp('pitch')}
          resetOrientation={() => this.handleResetViewportProp('bearing')}
          isTilted={viewState.pitch !== 0}
          orientationChanged={viewState.bearing !== 0}
        />
        <MapGL
          {...viewState}
          height="100%"
          width="100%"
          dragPan
          doubleClickZoom
          dragRotate
          attributionControl={false}
          onViewportChange={this.handleUpdateViewport}
          mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
          transformRequest={(url, resourceType) => {
            if (resourceType === 'Tile' && url.match('cartocdn.com')) {
              return {
                url: `${url}?api_key=QSztUEhQs9PxFht6TLe9Ec`,
              };
            }

            return { url };
          }}
        >
          <DeckGL
            ref={(deck) => {
              this.deck = deck;
            }}
            viewState={viewState}
            layers={this.getLayers()}
            getCursor={() => 'grab'}
          />
          {mapMode !== 'FORECAST' && (
            <Tooltip
              currency={currency}
              hoveredObject={hoveredObject}
              locale={locale}
              financialModel={financialModel}
              nodalPrices={overlayData}
              pointerX={pointerX}
              pointerY={pointerY}
            />
          )}
          {mapMode === 'FORECAST' && (
            <ForecastTooltip
              hoveredObject={hoveredObject}
              overlayData={overlayData}
              pointerX={pointerX}
              pointerY={pointerY}
            />
          )}
          {children}
        </MapGL>
      </div>
    );
  }
}

export default Map;
