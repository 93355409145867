import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import HeaderLayout from 'components/HeaderLayout';

import './Measurements.scss';

const Measurements = () => {
  return (
    <HeaderLayout className="measurements" title="Measurements">
      <Fragment>
        <Card
          className="measurements-link"
          title={
            <Link to="/measurements/feeds">
              <h3 style={{ fontSize: 36 }}>Feeds</h3>
            </Link>
          }
        />
        <Card
          className="measurements-link"
          title={
            <Link to="/measurements/forecasts">
              <h3 style={{ fontSize: 36 }}>Load Forecasts</h3>
            </Link>
          }
        />
        <Card
          className="measurements-link"
          title={
            <Link to="/measurements/mapping">
              <h3 style={{ fontSize: 36 }}>Mapping</h3>
            </Link>
          }
        />
        <Card
          className="measurements-link"
          title={
            <Link to="/measurements/timeseries">
              <h3 style={{ fontSize: 36 }}>Timeseries</h3>
            </Link>
          }
        />
        <Card
          className="measurements-link"
          title={
            <Link to="/measurements/weather">
              <h3 style={{ fontSize: 36 }}>Weather</h3>
            </Link>
          }
        />
      </Fragment>
    </HeaderLayout>
  );
};

export default Measurements;
