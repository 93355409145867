import { GeoJsonLayer } from 'deck.gl';
import {
  LmpdNodalPrice,
  OverlayData,
  OverlayFeature,
  OverlayFeatures,
} from 'types/map';

const getLMPDLayers = (
  polygonLayerAssets: OverlayFeatures,
  scaling: number,
  overlayData: OverlayData<LmpdNodalPrice>,
  onHover: (obj: Record<string, unknown>, x: number, y: number) => void
) => {
  return [
    new GeoJsonLayer<OverlayFeature>({
      id: 'dGeojson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) =>
        scaling *
        (overlayData[n.properties.id].lmp + overlayData[n.properties.id].d),
      getFillColor: (d) => [20, 155, 213],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
    new GeoJsonLayer<OverlayFeature>({
      id: 'lmpGeojson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) => scaling * overlayData[n.properties.id].lmp,
      getFillColor: (d) => [122, 213, 20],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
  ];
};
export default getLMPDLayers;
