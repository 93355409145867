import classNames from 'classnames';
import check from 'check-types';
import React, { Component, FocusEvent, ReactNode, SyntheticEvent } from 'react';

import './NumberInput.scss';

type ShowValidationMessage = 'always' | 'after-edit' | 'never';

type NumberInputProps = {
  id: string;
  className?: string;
  label?: ReactNode;
  gt?: number;
  lt?: number;
  max?: number;
  min?: number;
  step?: string;
  onChange?: (n?: number) => void;
  value?: number;
  unit?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  validationMessage?: Array<string> | string;
  showValidationMessage?: ShowValidationMessage;
  nonZero?: boolean;
  negNonZero?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  name?: string;
  htmlFor?: string;
};

type NumberInputState = {
  edited: boolean;
};

class NumberInput extends Component<NumberInputProps, NumberInputState> {
  static defaultProps = {
    className: '',
    htmlFor: null,
    gt: null,
    lt: null,
    max: null,
    min: null,
    step: 'any',
    unit: null,
    label: null,
    disabled: false,
    nonZero: false,
    negNonZero: false,
    onChange: null,
    required: false,
    invalid: false,
    validationMessage: null,
    showValidationMessage: 'after-edit',
    onBlur: undefined,
    onFocus: undefined,
    name: undefined,
  };

  state = {
    edited: false,
  };

  private parseFloat = (value: number | string | undefined): number => {
    if (check.number(value)) {
      return value;
    }
    return typeof value === 'undefined' ? NaN : parseFloat(value);
  };

  private isInRange = () => {
    const {
      gt,
      invalid,
      lt,
      max,
      min,
      negNonZero,
      nonZero,
      required,
      showValidationMessage,
      value,
    } = this.props;

    if (showValidationMessage === 'never') {
      return true;
    }
    if (!this.state.edited && showValidationMessage === 'after-edit') {
      return true;
    }

    let valid = true;

    const numVal = this.parseFloat(value);
    if (required && !check.number(numVal)) {
      valid = false;
    } else if (nonZero && numVal <= 0) {
      valid = false;
    } else if (negNonZero && numVal >= 0) {
      valid = false;
    } else if (check.number(max) && numVal > max) {
      valid = false;
    } else if (check.number(min) && numVal < min) {
      valid = false;
    } else if (check.number(gt) && numVal <= gt) {
      valid = false;
    } else if (check.number(lt) && numVal >= lt) {
      valid = false;
    } else if (invalid) {
      valid = !invalid;
    }

    return valid;
  };

  private getErrorMessage = (): string => {
    const {
      value,
      min,
      max,
      lt,
      gt,
      invalid,
      validationMessage,
      required,
      nonZero,
      negNonZero,
    } = this.props;
    if (invalid) {
      if (check.array(validationMessage)) {
        return validationMessage.reduce((acc, cur) => `${acc} ${cur}`, '');
      }
      return validationMessage || '';
    }

    const numVal = this.parseFloat(value);
    if (required && Number.isNaN(numVal)) {
      return 'Field is required.';
    } else if (nonZero && numVal <= 0) {
      return 'Value must be greater than 0.';
    } else if (negNonZero && numVal >= 0) {
      return 'Value must be less than 0.';
    } else if (min !== null || max !== null) {
      const minMessage = min !== null ? `smaller than ${min}` : '';
      const maxMessage = max !== null ? `greater than ${max}` : '';
      return `Value must not be ${minMessage}${
        !!minMessage && !!maxMessage ? ' or ' : ''
      }${maxMessage}.`;
    }

    const minMessage = gt !== null ? `smaller than or equal to ${gt}` : '';
    const maxMessage = lt !== null ? `greater than or equal to ${lt}` : '';
    return `Value must not be ${minMessage}${
      !!minMessage && !!maxMessage ? ' or ' : ''
    }${maxMessage}.`;
  };

  render() {
    let errorMessage;
    const valueInRange = this.isInRange();

    if (!valueInRange) {
      errorMessage = this.getErrorMessage();
    }

    const {
      className,
      disabled,
      htmlFor,
      id,
      invalid,
      label,
      max,
      min,
      name,
      onBlur,
      onChange,
      onFocus,
      required,
      step,
      unit,
      value,
    } = this.props;

    return (
      <div
        className={classNames(className, {
          'number-input-group': true,
          'number-input-group--invalid':
            !valueInRange || (this.state.edited && invalid),
        })}
      >
        <div className="number-input">
          {label && (
            <label htmlFor={htmlFor || id} className="number-input__label">
              {label}
            </label>
          )}
          <div className="number-input-container">
            <input
              id={htmlFor || id}
              type="number"
              min={min}
              max={max}
              step={step}
              className={`number-input__input ${
                unit ? 'number-input-with-unit' : ''
              }`}
              value={value}
              disabled={disabled}
              onChange={(e: SyntheticEvent) => {
                if (onChange) {
                  const { value } = e.target as HTMLInputElement;
                  const parsed = value !== '' ? Number(value) : undefined;

                  this.setState({
                    edited: true,
                  });
                  onChange(parsed);
                }
              }}
              required={required}
              title={valueInRange ? undefined : errorMessage}
              onBlur={(valueInRange && onBlur) || undefined}
              onFocus={onFocus}
              name={name}
            />
            {unit && <span className="number-input-unit">{unit}</span>}
          </div>
        </div>
        {!valueInRange && (
          <div className="input-error">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    );
  }
}

export default NumberInput;
