export enum FinancialModel {
  DLMP = 'DLMP',
  LMPD = 'LMPD',
  PAY_AS_BID = 'PAY_AS_BID',
  PAY_AS_CLEAR = 'PAY_AS_CLEAR',
}

export enum MarketControlStrategy {
  LOSS_OPTIMIZE_ENROLLED_ASSETS = 'LOSS_OPTIMIZE_ENROLLED_ASSETS',
  COST_OPTIMIZE_ENROLLED_ASSETS = 'COST_OPTIMIZE_ENROLLED_ASSETS',
}

export type SystemMarketInfo = {
  avoided_generation_capacity_cost?: number;
  id?: number;
  num_forecasted_peak_events?: number;
  program_id?: number;
  renewable_energy_credit?: number;
  system_peak_load?: number;
  system_peak_threshold?: number;
  transmission_loss?: number;
};

export interface Program {
  allow_reverse_active_powerflow: boolean;
  allow_reverse_reactive_powerflow: boolean;
  auto_confirm_enabled: boolean;
  battery_control: string;
  constraint_energy_management: boolean;
  currency: string;
  dispatch_grace_period: number;
  financial_model: FinancialModel;
  generate_nodal_load_forecasts: boolean;
  skip_nodal_losses_calculation: boolean;
  is_activated: boolean;
  is_simulation: boolean;
  iso: string;
  locale: string;
  market_control_strategy: MarketControlStrategy;
  name: string;
  program_id: number;
  sameday_event_duration: number;
  sameday_event_method: string;
  system_market_info: SystemMarketInfo;
  timezone: string;
  workspace_name: string;
  external_account_id: string;
}
