import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';

import './ISOList.scss';
import ISOListItem from './components/ISOListItem';

type IsoZone = {
  updated_at: string;
};

type ISO = {
  id: number;
  name: string;
  zones: IsoZone[];
};

const ISOList = () => {
  const { data: ISOs, loading } = useRequestEffect<ISO[]>({
    url: `/api/dsp/iso`,
    method: 'get',
    toast: {
      error: 'Failed to load ISO list',
    },
    refetchOnChange: [],
  });

  return (
    <HeaderLayout
      className="iso-list"
      title="ISOs"
      titleRightContent={
        <Link className="iso-link" to="/isos/add">
          <Button label="Add New ISO" />
        </Link>
      }
    >
      <Fragment>
        {loading && <LoadingSpinner />}
        {!loading && ISOs && (
          <div className="iso-container">
            {ISOs.map((iso) => (
              <Link className="iso-link" key={iso.name} to={`/isos/${iso.id}`}>
                <ISOListItem
                  entries={iso.zones.map(({ updated_at }) => ({
                    last_status: 'success',
                    last_timestamp: updated_at,
                  }))}
                  title={iso.name}
                />
              </Link>
            ))}
          </div>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default ISOList;
