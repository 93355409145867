import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SingleTag from './routes/SingleTag';
import SingleTimeseries from './routes/SingleTimeseries';
import Timeseries from './Timeseries';

const TimeseriesContainer = () => {
  return (
    <Switch>
      <Route exact path="/measurements/timeseries">
        <Timeseries />
      </Route>
      <Route exact path="/measurements/timeseries/:timeseriesID">
        <SingleTimeseries />
      </Route>
      <Route path="/measurements/timeseries/:timeseriesID/tag/:tagID">
        <SingleTag />
      </Route>
    </Switch>
  );
};

export default TimeseriesContainer;
