import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { AuthContextProvider } from '@opusonesolutions/gridos-app-framework';
import { ProgramsProvider } from 'contexts/ProgramsContext';
import './index.scss';
import App from './App';

ReactDOM.render(
  <Router>
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <AuthContextProvider serviceName="te-ui">
        <ProgramsProvider>
          <App />
        </ProgramsProvider>
      </AuthContextProvider>
    </ToastProvider>
  </Router>,
  document.getElementById('root')
);
