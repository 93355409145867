import flatpickr from 'flatpickr';
import { Instance as FlatPickrInstance } from 'flatpickr/dist/types/instance';
import { Options as FlatPickrOptions } from 'flatpickr/dist/types/options';
import isEqual from 'lodash.isequal';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import classNames from 'classnames';
import './DatePicker.scss';

type DatePickerProps = {
  date: DateTime | null;
  dateFormat?: string;
  defaultDate: DateTime | null;
  disabled?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  onChange: (date: DateTime) => void;
  options: FlatPickrOptions;
  showArrows?: boolean;
  useUTC?: boolean;
};

type DatePickerState = {
  date: DateTime;
};

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
 */
class DatePicker extends Component<DatePickerProps, DatePickerState> {
  public static defaultProps = {
    date: null,
    defaultDate: null,
    disabled: false,
    options: {},
    showArrows: true,
    dateFormat: 'F j, Y',
    useUTC: true,
  };

  calendar: FlatPickrInstance | null;
  dateInput: HTMLInputElement | null;

  showRegularRightArrow = true;
  showRegularLeftArrow = true;

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      date: this.props.defaultDate || this.props.date || DateTime.local(),
    };

    this.calendar = null;
    this.dateInput = null;
    this.updateSelectionArrows(this.state.date);
  }

  componentDidMount() {
    this.createDateField(this.state.date);
  }

  componentDidUpdate(prevProps: DatePickerProps) {
    // If calendar is controlled, update the internal date state
    const { calendar, props } = this;
    const { date, options, maxDate, minDate } = props;

    if (
      date &&
      prevProps.date &&
      date.valueOf() !== prevProps.date.valueOf() &&
      calendar
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ date: date });
      calendar.setDate(date.toLocaleString());
      this.createDateField(date);
    }

    // this.createDateField may have recreated the calendar
    // Need to use this.calendar after this point
    if (!isEqual(prevProps.options, options) && this.calendar) {
      Object.keys(options).forEach((key) => {
        // @ts-ignore
        this.calendar.set(key, options[key]);
      });
      this.calendar.redraw();
    }

    if (date && this.calendar) {
      if (!isEqual(prevProps.options.maxDate, maxDate)) {
        this.calendar.set('maxDate', maxDate ? maxDate.toJSDate() : undefined);
      }
      if (!isEqual(prevProps.options.minDate, minDate)) {
        this.calendar.set('minDate', minDate ? minDate.toJSDate() : undefined);
      }
      this.calendar.redraw();
    }
  }

  componentWillUnmount() {
    if (this.calendar) {
      this.calendar.destroy();
    }
  }

  createDateField = (inputDate: DateTime) => {
    let currentValue = inputDate;
    const now = inputDate.toJSDate();
    const timezoneOffsetInMinutes = now.getTimezoneOffset();

    const { calendar, dateInput, props } = this;
    let options = { ...props.options };
    let { maxDate, minDate } = props;
    const { dateFormat, useUTC } = props;

    if (useUTC) {
      currentValue = currentValue.plus({ minutes: timezoneOffsetInMinutes });
    }

    if (minDate || maxDate) {
      if (useUTC) {
        if (minDate) {
          minDate = minDate.plus({ minutes: timezoneOffsetInMinutes });
        }
        if (maxDate) {
          maxDate = maxDate.plus({ minutes: timezoneOffsetInMinutes });
        }
      }
      if (minDate) {
        options = {
          ...options,
          minDate: minDate.valueOf(),
        };
      }
      if (maxDate) {
        options = {
          ...options,
          maxDate: maxDate.valueOf(),
        };
      }
    }

    // @ts-ignore
    this.calendar = flatpickr(dateInput, {
      time_24hr: true,
      minuteIncrement: 1,
      dateFormat,
      onChange: (date: Date[]) =>
        this.handleDateChange(DateTime.fromJSDate(date[0])),
      defaultDate: currentValue.valueOf(),
      ...options,
    });
    if (calendar && calendar.calendarContainer) {
      calendar.calendarContainer.classList.add('DatePicker-calendar-instance');
    }
  };

  // Add one date to the current date
  addDate = () => {
    const newDate = this.state.date.plus({ days: 1 });
    const { maxDate } = this.props;

    // Ensure that we do not go past maxDate if set...
    if (
      maxDate !== undefined &&
      newDate.startOf('day') > maxDate.startOf('days')
    ) {
      return;
    }
    this.handleDateChange(newDate);
  };

  // Subtract one date from the current date
  subtractDate = () => {
    const newDate = this.state.date.plus({ days: -1 });
    const { minDate } = this.props;

    // Ensure that we do not go past minDate if set.
    if (
      minDate !== undefined &&
      newDate.startOf('day') < minDate.startOf('days')
    ) {
      return;
    }
    this.handleDateChange(newDate);
  };

  handleDateChange = (date: DateTime) => {
    this.updateSelectionArrows(date);
    this.setState({ date });
    this.props.onChange(date);
  };

  updateSelectionArrows = (date: DateTime) => {
    const { maxDate, minDate } = this.props;

    this.showRegularLeftArrow =
      minDate === undefined ||
      minDate.startOf('days').valueOf() !== date.startOf('day').valueOf();
    this.showRegularRightArrow =
      maxDate === undefined ||
      maxDate.startOf('days').valueOf() !== date.startOf('day').valueOf();
  };

  render() {
    const { disabled, showArrows } = this.props;

    return (
      <div className="date-picker">
        {showArrows && (
          <button
            className={classNames('date-picker__btn', {
              'reached-limit': !this.showRegularLeftArrow,
            })}
            onClick={this.subtractDate}
            disabled={disabled}
            type="button"
          >
            <i className="material-icons" aria-hidden="true">
              keyboard_arrow_left
            </i>
          </button>
        )}
        <input
          className="date-picker__input"
          name="startDate"
          disabled={disabled}
          ref={(div) => {
            this.dateInput = div;
          }}
        />
        {showArrows && (
          <button
            className={classNames('date-picker__btn', {
              'reached-limit': !this.showRegularRightArrow,
            })}
            onClick={this.addDate}
            disabled={disabled}
            type="button"
          >
            <i className="material-icons" aria-hidden="true">
              keyboard_arrow_right
            </i>
          </button>
        )}
      </div>
    );
  }
}

export default DatePicker;
