import { Feature, FeatureCollection, Polygon } from 'geojson';

export type MapFinancialModel = 'DLMP' | 'LMPD' | 'PAY_AS_BID' | 'PAY_AS_CLEAR';
export enum MapMode {
  VALUATION = 'VALUATION',
  FORECAST = 'FORECAST',
  PWR = 'PWR',
}
export type OverlayFeature = Feature<
  Polygon,
  {
    id: string;
    name: string;
  }
>;
//@ts-ignore
export type OverlayFeatures = FeatureCollection<OverlayFeature>;

export interface DlmpNodalPrice {
  capacity?: number;
  congestion: number;
  dispatch: number;
  dlmp: number;
  energy: number;
  generation: number;
  loss: number;
  power: number;
  offer?: number;
}

export interface LmpdNodalPrice {
  d: number;
  lmp: number;
  lmpd: number;
  power: number;
}

export interface PayasbidNodalPrice {
  price: number;
  power: number;
}

export interface PayasclearNodalPrice {
  price: number;
  power: number;
}

export type OverlayData<O> = { [key: string]: O };
