// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { apm, Request } from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
import DatePicker from 'components/DatePicker';
import LoadingSpinner from 'components/LoadingSpinner';

import './DERPriceChart.scss';

const DER = ({ der_rdf_id }) => {
  const [date, setDate] = useState(DateTime.local().startOf('day'));
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    let didCancel = false;

    async function fetchDERPrices() {
      setLoading(true);
      try {
        // Get all of the programs this DER is in, so that we can load the appropriate pricing events
        const programRequest = new Request(
          `/api/dsp/program/asset/${der_rdf_id}`
        );
        const { data: programList } = await programRequest.get();

        const opts = {
          params: {
            from_start_time: date.toISO(),
            to_start_time: date.endOf('day').toISO(),
          },
        };
        const peRequest = programList.map(
          (p) =>
            new Request(
              `/api/dsp/program/${p.program_id}/pricing_events/asset/${der_rdf_id}`
            )
        );
        const responses = await Promise.all(
          peRequest.map((req) => req.get(opts))
        );

        if (didCancel) {
          // Cancelled before the request finished so do nothing
          return;
        }

        const datasets = [];
        const colorSpacing = 360 / (2 * programList.length);

        programList.forEach((program, index) => {
          const { data } = responses[index];

          datasets.push({
            label: `${program.name} - Day Ahead`,
            data: data
              .filter((pe) => pe.market_type === 'DAYAHEAD')
              .map((pe) => ({
                x: DateTime.fromISO(pe.start_time).valueOf(),
                y: parseFloat(pe.unit_price),
              }))
              .sort((a, b) => a.x - b.x),
            borderColor: `hsl(${2 * index * colorSpacing}, 100%, 25%)`,
            backgroundColor: `hsl(${2 * index * colorSpacing}, 100%, 25%)`,
            borderWidth: 2,
            fill: false,
            stepped: 'before',
          });

          datasets.push({
            label: `${program.name} - Same Day`,
            data: data
              .filter((pe) => pe.market_type === 'SAMEDAY')
              .map((pe) => ({
                x: DateTime.fromISO(pe.start_time).valueOf(),
                y: parseFloat(pe.unit_price),
              }))
              .sort((a, b) => a.x - b.x),
            borderColor: `hsl(${(2 * index + 1) * colorSpacing}, 100%, 25%)`,
            backgroundColor: `hsl(${
              (2 * index + 1) * colorSpacing
            }, 100%, 25%)`,
            borderWidth: 2,
            fill: false,
            stepped: 'before',
          });
        });

        setChartData({ datasets });
        setLoading(false);
      } catch (error) {
        apm.captureError(error);
      }
    }

    fetchDERPrices();
    return () => {
      didCancel = true;
    };
  }, [der_rdf_id, date]);

  return (
    <div className="der-price-chart">
      <h3 className="price-chart-header">Recent Price Signals</h3>
      <div className="input-container">
        <DatePicker date={date} onChange={(d) => setDate(d)} />
      </div>
      <div className="price-chart-container">
        {loading && <LoadingSpinner />}
        {!loading && (
          <ChartWrapper
            config={{
              type: 'line',
              data: chartData,
              options: {
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x',
                    callbacks: {
                      label: function (tooltipItem) {
                        const { parsed, label } = tooltipItem;
                        let value = parsed.y;
                        value = Math.round(value * 100) / 100;
                        return `${label}: ${value.toLocaleString()} $/MWh`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    min: date.startOf('day').valueOf(),
                    max: date.startOf('day').plus({ days: 1 }).valueOf(),
                    type: 'time',
                    distribution: 'linear',
                    offset: true,
                    ticks: {
                      major: {
                        enabled: true,
                        fontStyle: 'bold',
                      },
                      source: 'auto',
                      autoSkip: true,
                      autoSkipPadding: 75,
                      maxRotation: 0,
                      sampleSize: 100,
                    },
                  },
                  y: {
                    suggestedMin: 0, // Handles negative prices better

                    grid: {
                      drawBorder: false,
                    },
                    title: {
                      display: true,
                      text: 'Price ($ / MWh)',
                    },
                    ticks: {
                      callback: (v) => `$ ${v.toFixed(2)}`,
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DER;
