import { AxiosRequestConfig } from 'axios';
import check from 'check-types';
import { DateTime } from 'luxon';
import React, { useContext, useState, Fragment } from 'react';

import { useParams } from 'react-router-dom';

import {
  apm,
  Request,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Card from 'components/Card';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import HeaderLayout from 'components/HeaderLayout';
import NumberInput from 'components/NumberInput';
import { ProgramsContext } from 'contexts/ProgramsContext';
import Select from 'components/Select';

import fileExportSave from 'helpers/downloadFile';

import './Reports.scss';

interface SelectOption {
  isDisabled?: boolean;
  label: string;
  value: any;
}

const CALENDAR_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MONTH_OPTIONS = CALENDAR_MONTHS.map((m, i) => ({
  label: m,
  value: i + 1,
}));

type CardProps = {
  downloading: boolean;
  downloadReport: (
    url: string,
    params?: {
      start_date?: string;
      end_date?: string;
      start_time?: string;
      end_time?: string;
    }
  ) => void;
  feederOptions?: SelectOption[];
  programId: string;
};

const SettlementCard = ({
  downloading,
  downloadReport,
  programId,
}: CardProps) => {
  const now = DateTime.local();

  const [settlementMonth, setSettlementMonth] = useState(now.month - 1); // Luxon indexes months from 1, not 0
  const [settlementYear, setSettlementYear] = useState<number | undefined>(
    now.year
  );

  const downloadSettlementReport = () => {
    if (check.number(settlementYear)) {
      const url =
        `/api/dsp/program/${programId}/settlement/report` +
        `?year=${settlementYear}&month=${settlementMonth + 1}`;
      downloadReport(url);
    }
  };

  return (
    <Card
      className="settlement-card"
      renderFooter={() => (
        <Button
          disabled={downloading || check.undefined(settlementYear)}
          onClick={downloadSettlementReport}
        >
          Download
        </Button>
      )}
      showFooter
      title="Settlement Report"
    >
      <div className="card-content">
        <Select
          isClearable={false}
          isMulti={false}
          label="Month"
          options={MONTH_OPTIONS}
          onChange={(m) => setSettlementMonth(CALENDAR_MONTHS.indexOf(m.label))}
          row
          value={MONTH_OPTIONS[settlementMonth]}
        />
        <NumberInput
          className="year-input"
          id="settlement-number-input"
          label="Year"
          min={now.year - 50}
          max={now.year}
          onChange={(y) => setSettlementYear(y)}
          required
          value={settlementYear}
        />
      </div>
    </Card>
  );
};

const MarketTransactionCard = ({
  downloading,
  downloadReport,
  programId,
}: CardProps) => {
  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programId);
  const now = DateTime.local();
  const maxDate = now.plus({ days: 1 });

  const [transactionStartDate, setTransactionStartDate] = useState(now);
  const [transactionEndDate, setTransactionEndDate] = useState(maxDate);

  const downloadMarketTransactionReport = () => {
    if (program) {
      const url = `/api/dsp/program/${programId}/market_transactions/report`;
      const params = {
        start_date: transactionStartDate
          .setZone(program.timezone)
          .toFormat('yyyy-LL-dd'),
        end_date: transactionEndDate
          .setZone(program.timezone)
          .toFormat('yyyy-LL-dd'),
      };

      downloadReport(url, params);
    }
  };

  return (
    <Card
      renderFooter={() => (
        <Button
          disabled={downloading || transactionStartDate > transactionEndDate}
          onClick={downloadMarketTransactionReport}
        >
          Download
        </Button>
      )}
      showFooter
      title="Market Transaction Report"
    >
      <div className="card-content">
        <div className="card-row">
          <div className="card-row-label">Start</div>
          <DatePicker
            date={transactionStartDate}
            maxDate={maxDate}
            onChange={(d: DateTime) => setTransactionStartDate(d)}
          />
        </div>
        <div className="card-row">
          <div className="card-row-label">End</div>
          <DatePicker
            date={transactionEndDate}
            maxDate={maxDate}
            onChange={(d: DateTime) => setTransactionEndDate(d)}
          />
        </div>
      </div>
    </Card>
  );
};

enum MarketType {
  'Dayahead' = 'dayahead',
  'SameDay' = 'sameday',
}

const MARKET_TYPES: SelectOption[] = [
  { label: 'Same Day', value: MarketType.SameDay },
  { label: 'Day Ahead', value: MarketType.Dayahead },
];

const AnalysisReportCard = ({
  downloading,
  downloadReport,
  feederOptions,
  programId,
}: CardProps) => {
  const [feederId, setFeederId] = useState('');
  const [marketType, setMarketType] = useState(MarketType.SameDay);
  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programId);

  const [analysisStartDate, setAnalysisStartDate] = useState(
    DateTime.local().startOf('hour')
  );
  const [analysisEndDate, setAnalysisEndDate] = useState(
    DateTime.local().startOf('hour').plus({ hours: 1 })
  );

  const maxDateSameDay = analysisEndDate.minus({ hours: 1 });
  const minDateSameDay = analysisStartDate.plus({ hours: 1 });

  const maxDateDayAhead = analysisEndDate.minus({ days: 1 });
  const minDateDayAhead = analysisStartDate.plus({ days: 1 });

  const handleMarketChange = (market: MarketType) => {
    if (market === marketType) {
      return;
    }
    if (program) {
      setMarketType(market);
      if (market === MarketType.SameDay) {
        setAnalysisStartDate(analysisStartDate.startOf('hour'));
        setAnalysisEndDate(
          analysisStartDate.plus({ hours: 1 }).startOf('hour')
        );
      } else {
        setAnalysisStartDate(analysisStartDate.startOf('day'));
        setAnalysisEndDate(analysisStartDate.plus({ days: 1 }).startOf('day'));
      }
    }
  };

  const downloadAnalysisReport = () => {
    if (program) {
      const url =
        `/api/dsp/program/${programId}/feeder/${feederId}/analysis-report` +
        `/OPF/market_type/${marketType}`;

      const params = {
        start_time: analysisStartDate.toFormat('yyyy-LL-dd HH:mm'),
        end_time: analysisEndDate.toFormat('yyyy-LL-dd HH:mm'),
      };
      downloadReport(url, params);
    }
  };

  return (
    <Card
      renderFooter={() => (
        <Button
          disabled={
            downloading || !feederId || analysisStartDate > analysisEndDate
          }
          onClick={downloadAnalysisReport}
        >
          Download
        </Button>
      )}
      showFooter
      title="OPF Analysis Report"
    >
      <div className="card-content">
        {feederOptions && (
          <div className="card-row">
            <Select
              isClearable={false}
              isMulti={false}
              label="Feeder"
              onChange={({ value: feederId }) => setFeederId(feederId)}
              options={feederOptions}
              row
              value={
                feederOptions
                  ? feederOptions.find((f) => f.value === feederId)
                  : undefined
              }
            />
          </div>
        )}
        <div className="card-row">
          <Select
            isClearable={false}
            isMulti={false}
            label="Market"
            onChange={({ value: marketType }) => handleMarketChange(marketType)}
            options={MARKET_TYPES}
            row
            value={MARKET_TYPES.find((m) => m.value === marketType)}
          />
        </div>
        <div className="card-content">
          <div className="card-row">
            <div className="card-row-label">Start</div>
            <DatePicker
              date={analysisStartDate}
              onChange={(d: DateTime) => setAnalysisStartDate(d)}
              options={{
                enableSeconds: false,
                enableTime: true,
                formatDate: (date) => {
                  const dt = DateTime.fromJSDate(date).setZone(
                    program ? program.timezone : 'UTC'
                  );
                  return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
                },
                minuteIncrement: 60,
              }}
              maxDate={
                marketType === MarketType.SameDay
                  ? maxDateSameDay
                  : maxDateDayAhead
              }
              useUTC={false}
            />
          </div>
          <div className="card-row">
            <div className="card-row-label">End</div>
            <DatePicker
              date={analysisEndDate}
              onChange={(d: DateTime) => setAnalysisEndDate(d)}
              options={{
                enableSeconds: false,
                enableTime: true,
                formatDate: (date) => {
                  const dt = DateTime.fromJSDate(date).setZone(
                    program ? program.timezone : 'UTC'
                  );
                  return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
                },
                minuteIncrement: 60,
              }}
              minDate={
                marketType === MarketType.SameDay
                  ? minDateSameDay
                  : minDateDayAhead
              }
              useUTC={false}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

const Reports = () => {
  const initialFeederOptions: SelectOption[] = [];

  const [downloading, setDownloading] = useState(false);
  const [feederOptions, setFeederOptions] = useState(initialFeederOptions);

  const { programID: programId } = useParams<{ programID: string }>();

  useRequestEffect<any>({
    url: `/api/dsp/program/${programId}/feeder`,
    method: 'get',
    initialData: {},
    refetchOnChange: [programId],
    onSuccess: (data) => {
      if (data) {
        setFeederOptions(
          data.feeders
            .filter((feeder: { enrolled: boolean }) => feeder.enrolled)
            .map(({ id, name }: { id: string; name: string }) => ({
              label: name,
              value: id,
            }))
        );
      }
    },
    toast: {
      error: 'Could not load feeder list.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const downloadReport = async (
    url: string,
    params: Record<string, unknown> = {}
  ) => {
    setDownloading(true);

    const request = new Request(url);
    const options: AxiosRequestConfig = {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
      params,
    };

    try {
      const { data, headers, status } = await request.get(options);

      if (status !== 204) {
        fileExportSave(data, headers);
      }
    } catch (error) {
      apm.captureError(error);
    }

    setDownloading(false);
  };

  return (
    <HeaderLayout className="reports" title="Program Reports">
      <Fragment>
        <SettlementCard
          downloading={downloading}
          downloadReport={downloadReport}
          programId={programId || ''}
        />
        <MarketTransactionCard
          downloading={downloading}
          downloadReport={downloadReport}
          programId={programId || ''}
        />
        <AnalysisReportCard
          downloading={downloading}
          downloadReport={downloadReport}
          feederOptions={feederOptions}
          programId={programId || ''}
        />
      </Fragment>
    </HeaderLayout>
  );
};

export default Reports;
