const ICON_MAPPING = {
  AsynchronousMachine: { x: 600, y: 0, width: 200, height: 200 },
  CHP: { x: 600, y: 400, width: 200, height: 200 },
  ElectricVehicleChargingStation: { x: 0, y: 400, width: 200, height: 200 },
  ElectricVehicleChargingStationAC: { x: 200, y: 400, width: 200, height: 200 },
  ElectricVehicleChargingStationDC: { x: 400, y: 400, width: 200, height: 200 },
  EnergyConsumer: { x: 805, y: 0, width: 160, height: 195, anchorY: 0 },
  EnergyConsumerDr: { x: 1020, y: 400, width: 160, height: 200, anchorY: 0 },
  EnergySource: { x: 1610, y: 200, width: 202, height: 202 },
  EquivalentSubstation: { x: 400, y: 200, width: 202, height: 202 },
  Hydro: { x: 800, y: 400, width: 200, height: 200 },
  SynchronousMachine: { x: 600, y: 0, width: 200, height: 200 },
  SwitchOpen: { x: 605, y: 205, width: 200, height: 200, anchorY: 180 },
  SwitchClosed: { x: 800, y: 200, width: 200, height: 200, anchorY: 180 },
  RegulatorFlipped: { x: 1400, y: 0, width: 200, height: 200, anchorY: 180 },
  Regulator: { x: 1405, y: 200, width: 200, height: 200, anchorY: 180 },
  PowerTransformer: { x: 1000, y: 200, width: 200, height: 200, anchorY: 180 },
  Recloser: { x: 1205, y: 0, width: 200, height: 200, anchorY: 180 },
  Sectionaliser: { x: 1600, y: 0, width: 200, height: 200, anchorY: 180 },
  PhotoVoltaic: { x: 1000, y: 0, width: 200, height: 200 },
  Battery: { x: 0, y: 0, width: 200, height: 200 },
  ShuntCapacitor: { x: 0, y: 200, width: 200, height: 200 },
  ShuntReactor: { x: 200, y: 200, width: 200, height: 200 },
  Wind: { x: 1200, y: 200, width: 200, height: 200 },
  Breaker: { x: 200, y: 0, width: 200, height: 200, anchorY: 180 },
  Fuse: { x: 400, y: 0, width: 195, height: 195, anchorY: 180 },
};

export default ICON_MAPPING;
