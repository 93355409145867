import React, { ReactChild, Fragment } from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbParent = {
  to: string;
  label: string | ReactChild;
};

type BreadcrumbsProps = {
  parents: Array<BreadcrumbParent>;
  currentHeader: string;
  separator: string | ReactChild;
};

const BreadCrumbs = ({
  parents,
  currentHeader,
  separator = '/',
}: BreadcrumbsProps) => {
  const sep =
    typeof separator === 'string' ? (
      <p className="breadcrumbs-separator">{separator}</p>
    ) : (
      separator
    );
  return (
    <div className="breadcrumbs-container">
      {parents.map(({ to, label }) => (
        <Fragment key={to}>
          <Link to={to}>
            {typeof label === 'string' ? (
              <h2 className="breadcrumbs-label">{label}</h2>
            ) : (
              label
            )}
          </Link>
          {sep}
        </Fragment>
      ))}
      <h2 className="breadcrumbs-label">{currentHeader}</h2>
    </div>
  );
};

export default BreadCrumbs;
