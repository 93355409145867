import React, { Fragment, useMemo } from 'react';

import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import IconButton from 'components/IconButton';
import Select from 'components/Select';

import { MeasurementMapping, SelectOption } from './MappingTypes';

import './MappingCard.scss';
import { TimeseriesTag } from 'contexts/MeasurementContext';

const MappingCard = ({
  mapping,
  onDelete,
  tag,
  timeseriesOptions,
}: {
  mapping: MeasurementMapping;
  onDelete: CallableFunction;
  tag: TimeseriesTag;
  timeseriesOptions: SelectOption[];
}) => {
  const tagOptions = useMemo(() => {
    return [{ label: tag.name, value: tag.id }];
  }, [tag]);

  const HeaderButtons = (
    <Fragment>
      <IconButton
        icon="delete"
        tooltip={'Delete'}
        onClick={() => onDelete(mapping.id)}
      />
    </Fragment>
  );

  return (
    <Card
      className="mapping-card"
      title={tag.measurement_type}
      showFooter={false}
      buttonGroup={HeaderButtons}
    >
      <div className="mapping-row">
        <div className="select-column">
          <Select
            className="mapping-select"
            isClearable={false}
            isMulti={false}
            isDisabled
            id="source"
            label="Timeseries"
            onChange={() => {}}
            options={timeseriesOptions}
            placeholder="No Source Selected"
            value={timeseriesOptions.find(
              (s) => s.value === mapping.timeseries_id
            )}
          />
        </div>
        <div className="select-column">
          <Select
            className="mapping-select"
            isClearable={false}
            isMulti={false}
            isDisabled
            id="tag"
            label="Tag"
            onChange={() => {}}
            options={tagOptions}
            placeholder="No Tag Selected"
            value={tagOptions.find((t) => t.value === mapping.tag_id)}
          />
        </div>
      </div>
      <div className="mapping-row">
        <div className="select-column">
          <label>Load Positive?</label>
          <Checkbox checked={mapping.in_load_sign_convention} disabled />
        </div>
      </div>
    </Card>
  );
};

export default MappingCard;
