import { DateTime } from 'luxon';
import React, { useState, FunctionComponent, useEffect } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
import LoadingSpinner from 'components/LoadingSpinner';
import useLocaleFormatter from 'hooks/useLocaleFormatter';

interface LMPValue {
  price: number;
  start_time: string;
}

interface LMPChartProps {
  start_time: DateTime;
  end_time: DateTime;
  currency: string;
  isoName: string;
  locale: string;
  nodeName: string;
  timezone: string;
  sameDayDuration: number;
  showZero: boolean;
}

interface LMPResponse {
  prices: LMPValue[];
}

type Point = {
  x: number;
  y: number;
};

const LMPChart: FunctionComponent<LMPChartProps> = ({
  start_time,
  end_time,
  currency,
  isoName,
  locale,
  nodeName,
  timezone,
  sameDayDuration,
  showZero,
}) => {
  const [dayAhead, setDayAhead] = useState<Point[]>([]);
  const [sameDay, setSameDay] = useState<Point[]>([]);

  useEffect(() => {
    if (showZero) {
      // Initialize all ISO data to zero

      const da: Point[] = [];
      const sd: Point[] = [];

      let time = start_time.startOf('day');
      while (time <= end_time.endOf('day')) {
        da.push({
          x: time.valueOf(),
          y: 0.0,
        });
        time = time.plus({ hours: 1 });
      }

      time = start_time.startOf('day');
      while (time <= end_time.endOf('day')) {
        sd.push({
          x: time.valueOf(),
          y: 0.0,
        });
        time = time.plus({ seconds: sameDayDuration });
      }

      setDayAhead(da);
      setSameDay(sd);
    }
  }, [showZero, sameDayDuration, start_time, end_time]);

  const { loading: loadingDA } = useRequestEffect<LMPResponse>({
    url: `/api/dsp/iso/${isoName}/${nodeName}/lmp/dayahead`,
    method: 'get',
    refetchOnChange: [isoName, nodeName, start_time, end_time],
    blockRequest: () =>
      !isoName || !nodeName || showZero || !start_time || !end_time,
    onSuccess: (data) => {
      if (data) {
        setDayAhead(
          data.prices.map(({ price, start_time }) => ({
            x: DateTime.fromISO(start_time).valueOf(),
            y: price,
          }))
        );
      }
    },
    params: {
      start_time: start_time.toISO(),
      end_time: end_time.toISO(),
    },
  });
  const { loading: loadingSD } = useRequestEffect<LMPResponse>({
    url: `/api/dsp/iso/${isoName}/${nodeName}/lmp/sameday`,
    method: 'get',
    refetchOnChange: [isoName, nodeName, start_time, end_time],
    blockRequest: () =>
      !isoName || !nodeName || showZero || !start_time || !end_time,
    onSuccess: (data) => {
      if (data) {
        setSameDay(
          data.prices.map(({ price, start_time }) => ({
            x: DateTime.fromISO(start_time).valueOf(),
            y: price,
          }))
        );
      }
    },

    params: {
      start_time: start_time.toISO(),
      end_time: end_time.toISO(),
    },
  });
  const { currencyFormatter } = useLocaleFormatter(currency, locale);

  if (loadingDA || loadingSD) {
    return (
      <div className="single-chart">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="single-chart">
      <ChartWrapper
        config={{
          type: 'line',
          data: {
            datasets: [
              {
                data: dayAhead,
                label: 'Day Ahead',
                backgroundColor: '#00467F',
                borderColor: '#00467F',
                fill: false,
                stepped: 'before',
              },
              {
                data: sameDay,
                label: 'Same Day',
                backgroundColor: '#7f0046',
                borderColor: '#7f0046',
                fill: false,
                stepped: 'before',
              },
            ],
            labels: [],
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                intersect: false,
                mode: 'nearest',
                axis: 'x',
                callbacks: {
                  title: (tooltipItems) => {
                    let title = '';
                    if (tooltipItems.length > 0) {
                      const item = tooltipItems[0];
                      const dateTime = DateTime.fromMillis(
                        item.parsed.x
                      ).setZone(timezone);
                      title = dateTime.toFormat('DDD hh:mm:ss a ZZ');
                    }

                    return title;
                  },
                  label: (tooltipItem) => {
                    const { parsed, label } = tooltipItem;
                    let value = parsed.y;
                    value = Math.round(value * 100) / 100; // 2 decimal places
                    return `${isoName} ${nodeName} ${label}: ${currencyFormatter.format(
                      value
                    )} /MWh`;
                  },
                },
              },
            },
            scales: {
              x: {
                adapters: {
                  date: {
                    zone: timezone,
                  },
                },
                min: start_time.valueOf(),
                max: end_time.valueOf(),
                type: 'time',
                offset: true,
                title: {
                  display: true,
                  text: `Time (${timezone})`,
                },
                ticks: {
                  major: {
                    enabled: true,
                  },
                  source: 'auto',
                  autoSkip: true,
                  autoSkipPadding: 75,
                  maxRotation: 0,
                  sampleSize: 100,
                },
              },
              y: {
                suggestedMin: 0, // Handles negative prices better
                grid: {
                  drawBorder: false,
                },
                title: {
                  display: true,
                  text: `LMP (${currency}/MWh)`,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LMPChart;
