import { DateTime } from 'luxon';
import React, { useContext, useEffect, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import DatePicker from 'components/DatePicker';
import HeaderLayout from 'components/HeaderLayout';
import SearchInput from 'components/SearchInput';
import Select from 'components/Select';
import { ProgramsContext } from 'contexts/ProgramsContext';
import { useContainers } from 'hooks/useContainers';
import { useDERs } from 'hooks/useDERs';
import useQueryState, {
  getDateTimeFromParam,
  serializeDateTime,
} from 'hooks/useQueryState';
import {
  ResolvedBidOffer,
  MarketType,
  SelectOption,
  BidOfferType,
} from 'types';

import { PricingEvent } from '../OperationalView/pricingEvents';
import SupplyStackGraph from './SupplyStackGraph';
import './BidsOffers.scss';
import useLocaleFormatter from 'hooks/useLocaleFormatter';

const MARKET_TYPES: SelectOption[] = [
  { label: 'Same Day', value: MarketType.SAMEDAY },
  { label: 'Day Ahead', value: MarketType.DAYAHEAD },
];

function isMarketType(value: string): value is keyof typeof MarketType {
  return value in MarketType;
}

const getMarketType = (market: string | null) => {
  if (market === null) {
    return null;
  }

  return isMarketType(market) ? MarketType[market] : null;
};

const BidsOffers = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [feeder, setFeeder] = useQueryState<string | null>(
    queryParams.get('feeder'),
    'feeder'
  );
  const [market, setMarket] = useQueryState<MarketType>(
    getMarketType(queryParams.get('market')) || MarketType.SAMEDAY,
    'market'
  );

  const { programID } = useParams<{ programID: string }>();
  const { containers } = useContainers(programID);

  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);
  const duration =
    market === MarketType.DAYAHEAD ? 3600 : program?.sameday_event_duration;

  const [eventStartTime, setEventStartTime] = useQueryState(
    getDateTimeFromParam(
      queryParams,
      'time',
      DateTime.local()
        .setZone(program?.timezone || 'UTC')
        .startOf('hour')
    ),
    'time',
    serializeDateTime
  );

  const toggleMarket = (marketType: MarketType) => {
    if (market !== marketType) {
      setMarket(marketType);
      setEventStartTime(eventStartTime.startOf('hour'));
    }
  };

  const feeder_options = containers
    ? Object.values(containers)
        .filter((c) => c.type === 'feeder')
        .map((c) => ({
          label: c.name,
          value: c.id,
        }))
    : [];

  const { data: offers, loading: offersLoading } = useRequestEffect<
    ResolvedBidOffer[]
  >({
    urlGen: () =>
      `/api/dsp/program/${programID}/bid_offer/feeder/${feeder}/market/${market}`,
    initialData: [],
    method: 'get',
    params: {
      start_time: eventStartTime.toISO(),
    },
    refetchOnChange: [programID, feeder, market, eventStartTime],
    toast: {
      error: 'Failed to load bids & offers',
      settings: {
        autoDismiss: true,
      },
    },
    blockRequest: () => !programID || !feeder,
  });

  const { DERs, DERsLoading } = useDERs(programID);

  const { data: events, loading: eventsLoading } = useRequestEffect({
    url: `/api/dsp/program/${programID}/pricing_event`,
    initialData: {},
    method: 'get',
    params: {
      from_start_time: eventStartTime.toISO(),
      to_start_time: eventStartTime.plus({ seconds: duration }).toISO(),
      market_types: market,
    },
    refetchOnChange: [programID, eventStartTime, market],
    dataTransform: (data: PricingEvent[]) => {
      const eventsByID: { [key: string]: PricingEvent } = {};
      data.forEach((pe) => {
        eventsByID[pe.der_rdf_id] = pe;
      });
      return eventsByID;
    },
  });

  useEffect(() => {
    if (program) {
      setEventStartTime((t) => t.setZone(program.timezone));
    }
  }, [program, setEventStartTime]);

  useEffect(() => {
    if (containers) {
      const feederIDs = Object.values(containers)
        .filter((c) => c.type === 'feeder')
        .map((f) => f.id);
      setFeeder(feederIDs.length ? feederIDs[0] : null);
    }
  }, [containers, setFeeder]);

  const { currencyFormatter, currencySymbol } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );

  return (
    <HeaderLayout className="bids-offers" title="Bids / Offers">
      {program && (
        <Fragment>
          <div className="control-container">
            <Select
              isClearable={false}
              isMulti={false}
              label="Feeder"
              onChange={(opt) => {
                if (opt.value !== feeder) {
                  setFeeder(opt.value);
                }
              }}
              options={feeder_options}
              row
              value={feeder_options.find((f) => f.value === feeder)}
            />
            <Select
              isClearable={false}
              isMulti={false}
              label="Market"
              onChange={(opt) => toggleMarket(opt.value)}
              options={MARKET_TYPES}
              row
              value={
                market === MarketType.SAMEDAY
                  ? MARKET_TYPES[0]
                  : MARKET_TYPES[1]
              }
            />
            <div className="event-selector-container">
              <span>Event:</span>
              <DatePicker
                date={eventStartTime}
                onChange={(newTime) => {
                  setEventStartTime(newTime);
                }}
                options={{
                  enableSeconds: false,
                  enableTime: true,
                  formatDate: (date) => {
                    const dt = DateTime.fromJSDate(date).setZone(
                      program.timezone
                    );
                    return dt.toFormat('LLL d, yyyy H:mm');
                  },
                  minuteIncrement:
                    market === 'SAMEDAY'
                      ? program.sameday_event_duration / 60
                      : 60,
                }}
                useUTC={true}
              />
            </div>
          </div>
          <div className="chart-container">
            <SupplyStackGraph
              currencyFormatter={currencyFormatter}
              currencySymbol={currencySymbol}
              offers={(offers || []).filter(
                (offer) => offer.type === BidOfferType.OFFER
              )}
              title="Offers"
              xLabel="Offer Quantity (kW)"
            />
            <SupplyStackGraph
              currencyFormatter={currencyFormatter}
              currencySymbol={currencySymbol}
              offers={(offers || []).filter(
                (offer) => offer.type === BidOfferType.BID
              )}
              title="Bids"
              xLabel="Bid Quantity (kW)"
            />
          </div>
          <div className="table-container">
            <ReactTable
              data={offers}
              loading={offersLoading || DERsLoading || eventsLoading}
              showPageSizeOptions={false}
              pageSize={8}
              columns={[
                {
                  Header: 'DER',
                  accessor: (offer) => (DERs || {})[offer.der_rdf_id]?.name,
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  // eslint-disable-next-line react/display-name
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="DER"
                    />
                  ),
                  id: 'der',
                  sortable: true,
                },
                {
                  Header: 'Bid (Price)',
                  accessor: (offer) =>
                    offer.type === BidOfferType.BID &&
                    typeof offer.price !== 'undefined'
                      ? `${currencyFormatter.format(offer.price)} MWh`
                      : null,
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  // eslint-disable-next-line react/display-name
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="Bid Price"
                    />
                  ),
                  id: 'bid_price',
                  sortable: true,
                },
                {
                  Header: 'Bid (Quantity)',
                  accessor: (offer) =>
                    offer.type === BidOfferType.BID
                      ? `${(offer.quantity || 0) / 1e3} kW`
                      : null,
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  // eslint-disable-next-line react/display-name
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="Bid Quantity"
                    />
                  ),
                  id: 'bid_quantity',
                  sortable: true,
                },
                {
                  Header: 'Offer (Price)',
                  accessor: (offer) =>
                    offer.type === BidOfferType.OFFER &&
                    typeof offer.price !== 'undefined'
                      ? `${currencyFormatter.format(offer.price)}/MW`
                      : null,
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  // eslint-disable-next-line react/display-name
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="Offer Price"
                    />
                  ),
                  id: 'offer_price',
                  sortable: true,
                },
                {
                  Header: 'Offer (Quantity)',
                  accessor: (offer) =>
                    offer.type === BidOfferType.OFFER
                      ? `${(offer.quantity || 0) / 1e3} kW`
                      : null,
                  filterable: true,
                  filterMethod: (filter: any, row: any) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  // eslint-disable-next-line react/display-name
                  Filter: (cellInfo) => (
                    <SearchInput
                      onChange={(e) => cellInfo.onChange(e.target.value)}
                      placeholder="Offer Quantity"
                    />
                  ),
                  id: 'offer_quantity',
                  sortable: true,
                },
                {
                  Header: 'Cleared',
                  accessor: (offer) =>
                    parseFloat(
                      (events || {})[offer.der_rdf_id]?.power_required
                    ) !== 0.0,
                  id: 'cleared',
                  resizable: false,
                  sortable: true,
                  width: 75,
                  // eslint-disable-next-line react/display-name
                  Cell: (props) => (
                    <span
                      className="center-container"
                      style={{ color: '#6D982A' }}
                    >
                      <i className="material-icons">
                        {props.value ? 'check_circle' : ''}
                      </i>
                    </span>
                  ),
                },
              ]}
              className="-striped -highlight"
              showPaginationBottom
            />
          </div>
        </Fragment>
      )}
    </HeaderLayout>
  );
};

export default BidsOffers;
