import React from 'react';

import IconButton from 'components/IconButton';
import { MapMode, MapFinancialModel } from 'types/map';
import './Legend.scss';

interface LegendProps {
  currency?: string;
  financialModel: MapFinancialModel;
  locale?: string;
  mapMode: MapMode;
  changeMapMode: (mode: MapMode) => void;
}

const Legend = ({
  currency,
  financialModel,
  mapMode,
  changeMapMode,
}: LegendProps) => {
  let content = null;
  if (mapMode === 'VALUATION' && financialModel === 'DLMP') {
    content = (
      <>
        <h3 className="title">{`DLMP (${currency}/MWh)`}</h3>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#7AD514' }} />
          Energy
        </span>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#FF6E64' }} />
          Residual (Losses + Voltage)
        </span>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#FDDC37' }} />
          Congestion
        </span>
      </>
    );
  }

  if (mapMode === 'VALUATION' && financialModel === 'LMPD') {
    content = (
      <>
        <h3 className="title">{`LMP+D (${currency}/MWh)`}</h3>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#7AD514' }} />
          Bulk System Value Stream
        </span>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#149BD5' }} />
          Distribution System Value Stream
        </span>
      </>
    );
  }

  if (mapMode === 'VALUATION' && financialModel === 'PAY_AS_BID') {
    content = (
      <>
        <h3 className="title">{`Pay as bid (${currency}/MWh)`}</h3>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#7AD514' }} />
          Market Clearing Price
        </span>
      </>
    );
  }

  if (mapMode === 'VALUATION' && financialModel === 'PAY_AS_CLEAR') {
    content = (
      <>
        <h3 className="title">{`Pay as clear (${currency}/MWh)`}</h3>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#7AD514' }} />
          Market Clearing Price
        </span>
      </>
    );
  }

  if (mapMode === 'FORECAST') {
    content = (
      <>
        <h3 className="title">Forecast (kW)</h3>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#7AD514' }} />
          Forecast Power
        </span>
      </>
    );
  } else if (mapMode === 'PWR') {
    content = (
      <>
        <h3 className="title">Committed Generation (kW)</h3>
        <span className="legend-item">
          <span className="color" style={{ backgroundColor: '#7AD514' }} />
          Forecast Power
        </span>
      </>
    );
  }

  return (
    <div className="legend">
      <div className="legend-header">
        <IconButton
          active={mapMode === MapMode.VALUATION}
          className="map-button"
          height="30px"
          icon="monetization_on"
          onClick={() => changeMapMode(MapMode.VALUATION)}
          tooltip="View Valuations"
          width="30px"
        />
        <IconButton
          active={mapMode === MapMode.FORECAST}
          className="map-button"
          height="30px"
          icon="timeline"
          onClick={() => changeMapMode(MapMode.FORECAST)}
          tooltip="View Forecasts"
          width="30px"
        />
        <IconButton
          active={mapMode === MapMode.PWR}
          className="map-button"
          height="30px"
          icon="power"
          onClick={() => changeMapMode(MapMode.PWR)}
          tooltip="View Commitments"
          width="30px"
        />
      </div>
      {content}
    </div>
  );
};

export default Legend;
