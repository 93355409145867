import React, { ReactChild, useState } from 'react';
import {
  NavLink as BaseNavLink,
  useRouteMatch,
  NavLinkProps as BaseNavLinkProps,
} from 'react-router-dom';
import classNames from 'classnames';

import {
  useAuthContext,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import Tooltip from 'components/Tooltip';

import './Navigation.scss';

type NavMenuItemData = {
  icon: string;
  label: string;
  link: string;
  requiresProgram?: boolean;
  permissions?: string[];
};

type NavMenuSection = {
  children: NavMenuItemData[];
  title?: string;
};

type NavMenuData = {
  sections: NavMenuSection[];
};

interface NavLinkProps extends BaseNavLinkProps {
  expanded: boolean;
  tooltip: ReactChild;
}

const NavLink = ({ expanded, tooltip, ...props }: NavLinkProps) =>
  !expanded ? (
    <Tooltip content={tooltip} placement="right" className="side-bar-tooltip">
      <BaseNavLink
        {...props}
        exact
        activeClassName="nav-button--selected"
      ></BaseNavLink>
    </Tooltip>
  ) : (
    <BaseNavLink
      {...props}
      exact
      activeClassName="nav-button--selected"
    ></BaseNavLink>
  );

const Navigation = () => {
  const [expanded, setExpanded] = useState({
    navContainer: false,
    buttonText: false,
  });

  const programMatch = useRouteMatch<{ programID: string }>(
    '/program/:programID'
  );
  const { data: navData, loading } = useRequestEffect<NavMenuData>({
    method: 'get',
    url: '/nav_menu.json',
  });

  const toggleMenu = () => {
    const { navContainer, buttonText } = expanded;

    // If we're trying to collapse we can do so immediately
    if (navContainer) {
      setExpanded({
        navContainer: false,
        buttonText: false,
      });
    } else {
      // If we're expanding the menu we want to delay the updating of button text
      setExpanded({
        navContainer: true,
        buttonText,
      });

      setTimeout(() => {
        setExpanded({
          navContainer: true,
          buttonText: true,
        });
      }, 400);
    }
  };

  const { isAuthEnabled, hasAllPermissions } = useAuthContext();

  return (
    <div
      className={classNames({
        navigation: true,
        'navigation--expanded': expanded.navContainer,
      })}
    >
      <div className="navigation-scroll-container">
        {!loading &&
          navData &&
          navData.sections.map((section) => {
            return (
              <>
                {section.title && (
                  <h3 className="nav-section-title">
                    {expanded.navContainer ? section.title : null}
                  </h3>
                )}
                {section.children.map((child) => {
                  const needsProgramSelected = !!child.requiresProgram;
                  let disabled = false;
                  let to = child.link;

                  if (to.charAt(0) !== '/') {
                    to = `/${to}`;
                  }

                  if (needsProgramSelected) {
                    // If these links require the program, we need to substitute the programID
                    disabled = programMatch === null;
                    to =
                      programMatch === null
                        ? '/'
                        : `/program/${programMatch.params.programID}${to}`;
                  }

                  if (
                    child.permissions &&
                    isAuthEnabled &&
                    !hasAllPermissions(child.permissions)
                  ) {
                    // This link does not have permission to appear.
                    return null;
                  }

                  return (
                    <NavLink
                      expanded={expanded.navContainer}
                      key={child.link}
                      to={to}
                      tooltip={child.label}
                      isActive={(_, { pathname }) =>
                        child.link === '/' || child.link === ''
                          ? // The trailing slash is optional so need to match both
                            pathname === to || `${pathname}/` === to
                          : pathname.startsWith(to)
                      }
                    >
                      <div
                        className={classNames({
                          'nav-button': true,
                          'nav-button--disabled': disabled,
                        })}
                      >
                        <i className="material-icons">{child.icon}</i>
                        <p className="nav-button-label">{child.label}</p>
                      </div>
                    </NavLink>
                  );
                })}
                <div className="separator" />
              </>
            );
          })}
      </div>
      <div className="expand-button-wrapper">
        <Tooltip
          content={!expanded.navContainer ? 'Expand Menu' : ''}
          className="expand-button-tooltip"
          placement="right"
        >
          <Button className="expand-button" onClick={toggleMenu}>
            {expanded.buttonText ? '<< Collapse Menu' : '>>'}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Navigation;
