export function getCurrencySymbol(formatter: Intl.NumberFormat) {
  const parts = formatter.formatToParts(0);

  let symbol = '';
  let i;

  for (i = 0; i < parts.length; ++i) {
    const part = parts[i];
    if (part.type === 'currency') {
      symbol = part.value;
      break;
    }
  }

  return symbol;
}
