import React, { useContext, useState, Fragment, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useRequest } from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import DatePicker from 'components/DatePicker';
import DateRangePicker from 'components/DateRangePicker';
import HeaderLayout from 'components/HeaderLayout';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import { ProgramsContext } from 'contexts/ProgramsContext';

import { MarketType } from 'types';

import ClearMarketDataCard from './ClearMarketDataCard';
import './ManualEvents.scss';

interface SelectOption {
  isDisabled?: boolean;
  label: string;
  value: any;
}

const ManualEvents = () => {
  const MARKET_TYPES: SelectOption[] = [
    { label: 'Same Day', value: MarketType.SAMEDAY },
    { label: 'Day Ahead', value: MarketType.DAYAHEAD },
  ];

  const [marketType, setMarketType] = useState(MarketType.SAMEDAY);
  const [pVmarketType, setPVMarketType] = useState(MarketType.SAMEDAY);

  const {
    loading: generatingForecasts,
    makeRequest: runPVGenerate,
  } = useRequest('/api/dsp/admin/pv_forecast');

  const generatePVForecasts = async () => {
    await runPVGenerate({
      method: 'get',
      params: {
        market_type: pVmarketType.valueOf().toLowerCase(),
      },
      toast: {
        success: 'Successfully generated PV forecasts',
        error: 'Could not generate PV forecasts',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { loading: trainingForecasters, makeRequest: runTraining } = useRequest(
    '/api/dsp/admin/load_forecaster/train'
  );

  const trainForecasters = async () => {
    await runTraining({
      method: 'get',
      toast: {
        success: 'Successfully ran model training process.',
        error: 'Model training process failed.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const { loading: syncingDERInfo, makeRequest: runSyncDERInfo } = useRequest(
    '/api/dsp/admin/sync_der_info'
  );

  const syncDERInfo = async () => {
    await runSyncDERInfo({
      method: 'post',
      toast: {
        success: 'Successfully triggered DER Info sync',
        error: 'Could not trigger DER info sync',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const {
    loading: runningSettlement,
    makeRequest: callSettlementTask,
  } = useRequest('/api/dsp/admin/settlement/trigger');

  const [settlementStartTime, setStartTime] = useState(
    DateTime.local().plus({ months: -1 }).startOf('day')
  );
  const [settlementEndTime, setEndTime] = useState(
    DateTime.local().endOf('day')
  );

  const runSettlement = async () => {
    await callSettlementTask({
      method: 'get',
      params: {
        start: settlementStartTime.toISO(),
        end: settlementEndTime.toISO(),
      },
      toast: {
        success: 'Successfully ran settlement.',
        error: 'Settlement process failed.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const [eventStartTime, setEventStartTime] = useState(
    DateTime.local().startOf('hour')
  );
  const [eventEndTime, setEventEndTime] = useState(
    DateTime.local().startOf('hour').plus({ hours: 1 })
  );
  const [programID, setProgramID] = useState<number>();
  const { programs, getProgram } = useContext(ProgramsContext);

  const programOptions = programs.map(({ program_id, name }) => ({
    label: name,
    value: program_id,
  }));

  useEffect(() => {
    if (programOptions?.length > 0) {
      setProgramID(programOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  const program = getProgram(programID);

  /**
   * Handles updating the start time and the end time if the new start time occurs after the end time.
   * @param {DateTime} newStartTime - new start time
   */
  function handleStartTimeChange(newStartTime: DateTime) {
    const newEndTime = newStartTime.plus({
      milliseconds: eventEndTime.toMillis() - eventStartTime.toMillis(),
    });
    setEventStartTime(newStartTime);
    if (
      eventEndTime < newStartTime ||
      (eventEndTime > newStartTime &&
        eventEndTime.toMillis() - newStartTime.toMillis()) > 3600000
    ) {
      setEventEndTime(newEndTime);
    }
  }

  const handleMarketChange = (market: MarketType) => {
    if (program) {
      setMarketType(market);
      if (market === MarketType.SAMEDAY) {
        setEventStartTime(
          DateTime.local()
            .setZone(program.timezone)
            .plus({ hours: 1 })
            .startOf('hour')
        );
        setEventEndTime(
          DateTime.local()
            .setZone(program.timezone)
            .plus({ hours: 2 })
            .startOf('hour')
        );
      } else {
        setEventStartTime(
          eventStartTime
            .setZone(program.timezone)
            .plus({ days: 1 })
            .startOf('day')
        );
        setEventEndTime(
          eventStartTime
            .setZone(program.timezone)
            .plus({ days: 2 })
            .startOf('day')
        );
      }
    }
  };

  const getUrl = (marketType: MarketType): string =>
    `/api/dsp/admin/pricing_events/${marketType.valueOf().toLowerCase()}`;

  const {
    loading: generatingPricingEvents,
    makeRequest: runPricingEvents,
  } = useRequest(getUrl(marketType));

  const generatePricingEvents = async () => {
    await runPricingEvents({
      body: {
        start: eventStartTime,
        end: eventEndTime,
        program_id: programID,
      },
      method: 'post',
      toast: {
        success: 'Successfully triggered pricing events.',
        error: 'Could not generate pricing events.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  const invalidPricingEventOptions = eventEndTime <= eventStartTime;

  return (
    <HeaderLayout
      className="manual-events"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/admin',
              label: <h2 className="title">Admin Panel</h2>,
            },
          ]}
          separator="/"
          currentHeader="Manual Events"
        />
      }
    >
      <Fragment>
        <Card className="event-card" title="Generate PV Forecasts">
          <Select
            label="Market"
            onChange={(opt) => setPVMarketType(opt.value)}
            isMulti={false}
            isClearable={false}
            options={MARKET_TYPES}
            row
            value={MARKET_TYPES.find((m) => m.value === pVmarketType)}
          />
          <Button
            disabled={generatingForecasts}
            onClick={() => generatePVForecasts()}
          >
            Generate
            {generatingForecasts && (
              <i className="material-icons rotating-icon">refresh</i>
            )}
          </Button>
        </Card>
        {programs && (
          <>
            <Card className="event-card" title="Generate Pricing Events">
              <Select
                label="Program"
                onChange={(opt) => setProgramID(opt.value)}
                isMulti={false}
                isClearable={false}
                options={programOptions}
                row
                value={programOptions.find((m) => m.value === programID)}
              />
              <Select
                label="Market"
                onChange={(opt) => handleMarketChange(opt.value)}
                isMulti={false}
                isClearable={false}
                options={MARKET_TYPES}
                row
                value={MARKET_TYPES.find((m) => m.value === marketType)}
              />

              <span className="date-label">Start Time</span>
              <DatePicker
                date={eventStartTime}
                onChange={(start) => {
                  handleStartTimeChange(start);
                }}
                options={{
                  enableSeconds: false,
                  enableTime: true,
                  formatDate: (date) => {
                    const dt = DateTime.fromJSDate(date).setZone(
                      program ? program.timezone : 'UTC'
                    );
                    return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
                  },
                  minuteIncrement:
                    marketType === 'SAMEDAY' && program
                      ? program.sameday_event_duration / 60
                      : 60,
                }}
                useUTC={false}
              />

              <span className="date-label">End Time</span>
              <DatePicker
                date={eventEndTime}
                onChange={(end) => {
                  setEventEndTime(end);
                }}
                options={{
                  enableSeconds: false,
                  enableTime: true,
                  formatDate: (date) => {
                    const dt = DateTime.fromJSDate(date).setZone(
                      program ? program.timezone : 'UTC'
                    );
                    return dt.toFormat('LLL d, yyyy H:mm ZZZZ');
                  },
                  minuteIncrement:
                    marketType === 'SAMEDAY' && program
                      ? program.sameday_event_duration / 60
                      : 60,
                }}
                useUTC={false}
                minDate={eventStartTime}
                maxDate={
                  marketType === 'SAMEDAY'
                    ? eventStartTime.plus({ hours: 1 })
                    : undefined
                }
              />
              <Tooltip
                content={
                  invalidPricingEventOptions
                    ? 'Start time must be before the end time.'
                    : ''
                }
              >
                <Button
                  disabled={
                    generatingPricingEvents || invalidPricingEventOptions
                  }
                  onClick={() => generatePricingEvents()}
                >
                  Generate
                </Button>
              </Tooltip>
            </Card>
            <ClearMarketDataCard />
          </>
        )}
        <Card className="event-card" title="Sync DER Info">
          <Button disabled={syncingDERInfo} onClick={() => syncDERInfo()}>
            Sync
          </Button>
        </Card>
        <Card className="event-card" title="Run Settlement">
          <div className="settlement-range">
            <DateRangePicker
              endDate={settlementStartTime}
              startDate={settlementEndTime}
              onChange={(start, end) => {
                setStartTime(start);
                setEndTime(end);
              }}
            />
          </div>
          <Button disabled={runningSettlement} onClick={() => runSettlement()}>
            Run
            {runningSettlement && (
              <i className="material-icons rotating-icon">refresh</i>
            )}
          </Button>
        </Card>
        <Card className="event-card" title="Train Load Forecasters">
          <Button
            disabled={trainingForecasters}
            onClick={() => trainForecasters()}
          >
            Train
            {trainingForecasters && (
              <i className="material-icons rotating-icon">refresh</i>
            )}
          </Button>
        </Card>
      </Fragment>
    </HeaderLayout>
  );
};

export default ManualEvents;
