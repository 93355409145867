import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, {
  useContext,
  useMemo,
  useState,
  Fragment,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import {
  Request,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import DatePicker from 'components/DatePicker';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
import SearchInput from 'components/SearchInput';
import { ProgramsContext } from 'contexts/ProgramsContext';
import fileExportSave from 'helpers/downloadFile';
import { useContainers } from 'hooks/useContainers';
import useQueryState, {
  getDateFromParam,
  serializeDate,
} from 'hooks/useQueryState';

import './SimulationDebug.scss';
import Select from 'components/Select';

type SimulationDebug = {
  created_at: string;
  data_bucket: string;
  feeder_id: string;
  id: number;
  key: string;
  market_type: 'DAYAHEAD' | 'SAMEDAY';
  objective: 'COST' | 'LOSS' | 'QSTS';
  program_id: number;
  simulation_start_time: string;
  simulation_success: boolean;
  simulation_time_points: number;
  simulation_timestep: number;
  wall_clock_time: string;
};

const SimulationDebugComponent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initialProgID = params.get('program_id');

  const [date, setDate] = useQueryState(
    getDateFromParam(params, 'date', DateTime.local().startOf('day')),
    'date',
    serializeDate
  );
  const [downloading, setDownloading] = useState(false);
  const [programID, setProgramID] = useQueryState<number | null>(
    initialProgID ? parseInt(initialProgID) : null,
    'program_id'
  );

  const { programs } = useContext(ProgramsContext);

  // Set to first program
  useEffect(() => {
    if (programs && programs.length && programID === null) {
      setProgramID(programs[0].program_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);
  const programOptions = useMemo(() => {
    return programs.map((p) => ({
      label: p.name,
      value: p.program_id,
    }));
  }, [programs]);

  const { containers = {}, containersLoading } = useContainers(programID);

  const { data: simulations, loading, refetch: refetchData } = useRequestEffect<
    SimulationDebug[]
  >({
    method: 'get',
    url: '/api/dsp/admin/simulation_debug_data',
    blockRequest: () => programID === null,
    params: {
      start_time: date.toISO(),
      end_time: date.plus({ days: 1 }).startOf('day').toISO(),
      program_id: programID,
    },
    initialData: [],
    refetchOnChange: [date, programID],
    toast: {
      error: 'Could not load simulations',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const handleDownload = async (debugData: SimulationDebug) => {
    setDownloading(true);

    try {
      const request = new Request(
        `/api/files/${debugData.data_bucket}/${debugData.key}`
      );

      const response = await request.get({
        timeout: 120000, // 2 min timeout because file is big
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache, no-store',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      const parts = debugData.key.split('/');
      const filename = parts[parts.length - 1];
      fileExportSave(response.data, response.headers, filename);
      // eslint-disable-next-line no-empty
    } catch (error) {}

    setDownloading(false);
  };

  return (
    <HeaderLayout
      className="simulation-debug"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/admin',
              label: <h2 className="title">Admin Panel</h2>,
            },
          ]}
          separator="/"
          currentHeader="Simulation Debug"
        />
      }
      titleRightContent={
        <Fragment>
          <div className="select-container">
            <Select
              label="Program"
              isClearable={false}
              isMulti={false}
              onChange={({ value }) => setProgramID(value)}
              options={programOptions}
              row
              value={
                programOptions
                  ? programOptions.find((p) => p.value === programID)
                  : undefined
              }
            />
          </div>
          <DatePicker date={date} onChange={(d: DateTime) => setDate(d)} />
          <IconButton
            disabled={loading || downloading}
            icon="refresh"
            onClick={() => refetchData()}
            tooltip="Refresh Debug Data"
          />
        </Fragment>
      }
    >
      <ReactTable
        data={simulations}
        loading={loading || containersLoading}
        columns={[
          {
            Header: 'Feeder',
            accessor: (row) => (containers[row.feeder_id] || {}).name,
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Feeder"
              />
            ),
            id: 'feeder_name',
            sortable: true,
          },
          {
            Header: 'Market Type',
            accessor: 'market_type',
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Market Type"
              />
            ),
            sortable: true,
          },
          {
            Header: 'Objective',
            accessor: 'objective',
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Simulation Objective"
              />
            ),
            sortable: true,
          },
          {
            Header: 'Status',
            accessor: 'simulation_success',
            resizable: false,
            sortable: true,
            width: 75,
            Cell: (props) => (
              <span className="center-container">
                <i
                  className={classNames({
                    'material-icons': true,
                    'icon-success': props.value,
                    'icon-error': !props.value,
                  })}
                >
                  {props.value ? 'check_circle' : 'error'}
                </i>
              </span>
            ),
          },
          {
            Header: 'Created At',
            accessor: (p) => DateTime.fromISO(p.created_at),
            Cell: (props) => (
              <span>{props.value.toLocal().toFormat('DDD hh:mm a ZZ')}</span>
            ),
            filterable: false,
            id: 'created_at',
            sortable: true,
          },
          {
            Header: 'Running Time',
            accessor: (p) => {
              // Value from python has microseconds. Need to 0 those out
              const dt = DateTime.fromFormat(p.wall_clock_time, 'H:mm:ss');
              return dt;
            },
            filterable: false,
            id: 'running_time',
            resizable: false,
            sortable: true,
            width: 150,
            Cell: (props) => <span>{props.value.toFormat('H:mm:ss')}</span>,
          },
          {
            Header: 'Analysis Start Time',
            accessor: (p) => DateTime.fromISO(p.simulation_start_time),
            Cell: (props) => (
              <span>{props.value.toLocal().toFormat('DDD hh:mm a ZZ')}</span>
            ),
            filterable: false,
            id: 'sim_time',
            sortable: true,
          },
          {
            Header: 'Analysis Timepoints',
            accessor: 'simulation_time_points',
            filterable: true,
            id: 'simulation_time_points',
            filterMethod: (filter: any, row: any) =>
              row[filter.id] === parseInt(filter.value, 10),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Number of Timepoints"
              />
            ),
            sortable: true,
          },
          {
            Header: 'Analysis Timestep',
            id: 'simulation_timestep',
            accessor: (r) => `${r.simulation_timestep}s`,
            filterable: true,
            filterMethod: (filter: any, row: any) =>
              row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
            Filter: (cellInfo) => (
              <SearchInput
                onChange={(e) => cellInfo.onChange(e.target.value)}
                placeholder="Timestep"
              />
            ),
            sortable: true,
          },
          {
            id: 'download',
            Cell: (props) => (
              <span className="center-container">
                <IconButton
                  disabled={downloading}
                  icon="get_app"
                  onClick={() => handleDownload(props.original)}
                  tooltip="Download Debug Data"
                />
              </span>
            ),
            width: 60,
            resizable: false,
          },
        ]}
        className="-striped -highlight"
        showPaginationBottom
      />
    </HeaderLayout>
  );
};

export default SimulationDebugComponent;
