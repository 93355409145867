import React, { useState } from 'react';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import IconButton from 'components/IconButton';
import './SettingsMenu.scss';

const SettingsMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthEnabled, logout, productDetails } = useAuthContext();

  return (
    <div className="settings">
      <IconButton
        className={`settings__btn ${menuOpen ? 'settings__btn--active' : ''}`}
        icon="settings"
        onClick={() => setMenuOpen(!menuOpen)}
        tooltip="Settings"
      />
      <div
        className={`settings-container ${
          menuOpen ? 'settings-container--active' : ''
        }`}
      >
        <div className="settings-tile">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/GridOS TEMS DSP User Guide V1.0.pdf"
          >
            <span>Help</span>
            <i className="material-icons">open_in_new</i>
          </a>
        </div>
        <div className="settings-tile">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://opusonesolutions.atlassian.net/servicedesk/customer/portal/8"
          >
            <span>Report an Issue</span>
            <i className="material-icons">open_in_new</i>
          </a>
        </div>
        {isAuthEnabled && (
          <div className="settings-tile">
            <button
              className="settings__btn settings-logout__btn"
              onClick={() => logout()}
            >
              <i className="material-icons" style={{ marginRight: '20px' }}>
                exit_to_app
              </i>
              <span>Log Out</span>
            </button>
          </div>
        )}
        <div className="settings-separator" />
        <div className="settings-version-container">
          <img
            className="settings-logo"
            src="/gridos_darkgrey.svg"
            alt="GridOS logo"
          />
          <p className="settings-version">Version: {productDetails.version}</p>
        </div>
      </div>
    </div>
  );
};

export default SettingsMenu;
