import classNames from 'classnames';
import React, { ReactChild, ReactNode } from 'react';

import './HeaderLayout.scss';

type HeaderLayoutProps = {
  children: ReactNode;
  className?: string;
  title: ReactChild;
  titleRightContent?: ReactChild;
};

const HeaderLayout = ({
  children,
  className,
  title,
  titleRightContent,
}: HeaderLayoutProps) => {
  if (typeof title === 'string') {
    title = <h2 className="title">{title}</h2>;
  }

  return (
    <div className={classNames('header-layout', className)}>
      <div className="title-bar">
        {title}
        <div className="title-right-container">{titleRightContent}</div>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default HeaderLayout;
