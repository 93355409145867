import { useMemo, useState } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

export interface DER {
  apparent_power: number | null;
  capacity: number | null;
  class: string;
  der_rdf_id: string;
  der_type: string;
  enrolled: boolean;
  feeder: { id: string; name: string | null };
  gen_capacity: number | null;
  location: [string, string];
  name: string;
  phases: number | null;
  power_factor: number | null;
  rated_voltage: number | null;
  reactive_power: number | null;
  real_power: number | null;
  usage_point_type: string | null;
}

interface DERResponse {
  [id: string]: DER;
}

export function useDERs(programID?: string) {
  const [DERs, setDERs] = useState<DERResponse>({});

  const {
    loading: DERsLoading,
    refetch: refetchDERs,
  } = useRequestEffect<DERResponse>({
    url: `/api/dsp/program/${programID}/asset`,
    method: 'get',
    refetchOnChange: [programID],
    onSuccess: (data) => {
      if (data) {
        setDERs(data);
      }
    },
    toast: {
      error: 'There was an unexpected error fetching your Assets!',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const updateDEREnrollmentState = useMemo(
    () => (id: string, enrolled: boolean) => {
      setDERs({
        ...DERs,
        [id]: {
          ...DERs[id],
          enrolled,
        },
      });
    },
    [DERs]
  );

  return {
    DERs,
    DERsLoading,
    refetchDERs,
    updateDEREnrollmentState,
  };
}
