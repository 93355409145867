import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import Map from 'components/Map';
import { DER } from 'types/participant';

import DERPriceChart from './DERPriceChart';
import './DER.scss';

const DERComponent = () => {
  const [mapData, setMapData] = useState({
    lines: {},
    linkConnectors: {},
    linkIcons: {},
    nodes: {},
    nodeConnectors: {},
    nodeIcons: {},
  });

  // derID is the rdf ID in this case
  const { derID } = useParams<{ derID: string }>();

  const { data: der, loading } = useRequestEffect<DER>({
    url: `/api/dsp/der/rdf/${derID}`,
    method: 'get',
    refetchOnChange: [derID],
    toast: {
      error: 'Failed to load DER',
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }

      let asset_type = data.info.der_type;
      if (
        asset_type !== 'PhotoVoltaic' &&
        asset_type !== 'Wind' &&
        asset_type !== 'Hydro'
      ) {
        // Use CIM class in this case
        asset_type = data.info.class;
      }

      // Use functional form of setMapData so that we
      // can use the existing value without making it a dep
      setMapData((m) => ({
        ...m,
        // Need to add a fake node so that the map recenters
        nodes: {
          [data.id]: {
            type: 'Feature',
            properties: {
              id: data.id,
              asset_type: 'ConnectivityNode',
              // Mark the feeder as the DER ID so that
              // we can always display the asset on the map
              feeder: derID,
            },
            geometry: {
              type: 'Point',
              coordinates: data.info.location.map((v) => parseFloat(v)),
            },
          },
        },
        nodeIcons: {
          [data.id]: {
            type: 'Feature',
            properties: {
              id: data.id,
              name: data.info.name,
              feeder: derID,
              asset_type,
            },
            geometry: {
              type: 'Point',
              coordinates: data.info.location.map((v) => parseFloat(v)),
            },
          },
        },
      }));
    },
  });

  return (
    <HeaderLayout
      className="der"
      title={`DER: ${der && der.info ? der.info.name : ''}`}
    >
      <Fragment>
        {loading && <LoadingSpinner />}
        {!loading && der && (
          <Fragment>
            <div className="column-container">
              <div className="column">
                <div className="row">
                  <label className="field-label">Type:</label>
                  <span className="field">{der.info.der_type}</span>
                </div>
                <div className="row">
                  <label className="field-label">Feeder:</label>
                  <span className="field">{der.info.feeder.name}</span>
                </div>
                <div className="row">
                  <label className="field-label">Power:</label>
                  <span className="field">{`${Math.floor(
                    der.info.real_power / 1000
                  )} kW`}</span>
                </div>
                <div className="row">
                  <label className="field-label">Reactive Power:</label>
                  <span className="field">{`${Math.floor(
                    der.info.reactive_power / 1000
                  )} kVAr`}</span>
                </div>
                <div className="row">
                  <label className="field-label">Rated Power:</label>
                  <span className="field">{`${Math.floor(
                    der.info.apparent_power / 1000
                  )} kVA`}</span>
                </div>
                <div className="row">
                  <label className="field-label">Rated Voltage:</label>
                  <span className="field">{`${(
                    der.info.rated_voltage / 1000
                  ).toFixed(2)} kV`}</span>
                </div>
              </div>
              <div className="column">
                <Map
                  networkModel={mapData}
                  overlayData={{}}
                  selectedContainers={[derID]}
                />
              </div>
            </div>
            <div className="charts">
              <DERPriceChart der_rdf_id={derID} />
            </div>
          </Fragment>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default DERComponent;
