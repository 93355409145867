import React from 'react';

import './Home.scss';

const Home = () => {
  return (
    <div className="home-route">
      <h2 className="cta">To get started, select or create a program</h2>
    </div>
  );
};

export default Home;
