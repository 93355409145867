import React, { Component } from 'react';
import classnames from 'classnames';
import Select, {
  ActionMeta,
  Props,
  OptionTypeBase,
  ValueType,
} from 'react-select';

import './Select.scss';

type Nullable<X> = X | null;

interface WrappedSelectProps<OptionType> extends Props<OptionType> {
  row?: boolean;
  label?: React.ReactNode;
  onChange(opt: ValueType<OptionType>, action: ActionMeta<OptionType>): void;
}

interface MultiSelectProps<OptionType> extends WrappedSelectProps<OptionType> {
  isClearable: false;
  isMulti: true;
  onChange: (
    opt: ReadonlyArray<OptionType>,
    action: ActionMeta<OptionType>
  ) => void;
}

interface NullableMultiSelectProps<OptionType>
  extends WrappedSelectProps<OptionType> {
  isClearable: true;
  isMulti: true;
  onChange: (
    opt: Nullable<ReadonlyArray<OptionType>>,
    action: ActionMeta<OptionType>
  ) => void;
}

interface SingleSelectProps<OptionType> extends WrappedSelectProps<OptionType> {
  isClearable: false;
  isMulti: false;
  onChange: (opt: OptionType, action: ActionMeta<OptionType>) => void;
}

interface NullableSingleSelectProps<OptionType>
  extends WrappedSelectProps<OptionType> {
  isClearable: true;
  isMulti?: false;
  onChange: (opt: Nullable<OptionType>, action: ActionMeta<OptionType>) => void;
}

function isSingleNullableValue<T>(
  opt: ValueType<T>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is Nullable<T> {
  return !isMulti && !!isClearable;
}

function isMultiNullableValue<T>(
  opt: ValueType<T>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is Nullable<ReadonlyArray<T>> {
  return !!isMulti && !!isClearable;
}

function isSingleValue<T>(
  opt: ValueType<T>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is T {
  return !isMulti && !isClearable;
}

function isMultiValue<T>(
  opt: ValueType<T>,
  isMulti?: boolean,
  isClearable?: boolean
): opt is ReadonlyArray<T> {
  return !!isMulti && !isClearable;
}

type SelectProps<T> =
  | SingleSelectProps<T>
  | MultiSelectProps<T>
  | NullableSingleSelectProps<T>
  | NullableMultiSelectProps<T>;

class WrappedSelect<T extends OptionTypeBase> extends Component<
  SelectProps<T>
> {
  static defaultProps = {
    isClearable: false,
    isMulti: false,
    styles: {
      clearIndicator: (provided: Record<string, unknown>) => ({
        ...provided,
        padding: '5px 8px',
      }),
      control: (provided: Record<string, unknown>) => ({
        ...provided,
        borderColor: '#D4D4D4',
        height: '30px',
        minHeight: 'unset',
      }),
      dropdownIndicator: (provided: Record<string, unknown>) => ({
        ...provided,
        padding: '5px 8px',
      }),
      indicatorSeparator: (provided: Record<string, unknown>) => ({
        ...provided,
        marginBottom: '5px',
        marginTop: '3px',
      }),
      menu: (provided: Record<string, unknown>) => ({
        ...provided,
        zIndex: 10,
      }),
      valueContainer: (provided: Record<string, unknown>) => ({
        ...provided,
        height: '30px',
        padding: '0px 8px',
      }),
    },
  };

  render() {
    const { props } = this;
    return (
      <div
        className={classnames({
          'wrapped-select': true,
          'wrapped-select--row': !!props.row,
        })}
      >
        {props.label && <label className="label">{props.label}</label>}
        <Select
          className="base-select"
          {...props}
          onChange={(opt, action) => {
            if (typeof opt === 'undefined') {
              opt = null;
            }

            if (props.onChange) {
              if (
                isSingleNullableValue(opt, props.isMulti, props.isClearable)
              ) {
                // Call Nullable<T> override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }

              if (isMultiNullableValue(opt, props.isMulti, props.isClearable)) {
                // Call Nullable<T[]> override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }

              if (isSingleValue(opt, props.isMulti, props.isClearable)) {
                // Call T override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }

              if (isMultiValue(opt, props.isMulti, props.isClearable)) {
                // Call T[] override
                // @ts-ignore because the compiler is confused about the onChange prop types
                props.onChange(opt, action);
              }
            }
          }}
        />
      </div>
    );
  }
}
export default WrappedSelect;
