import { CimJSON, CimObject, NetworkModel } from 'types/cim';
import { getMultiRef, getSingleRef } from './references';
import { isRegulator } from './regulator';

function getCimName(node: CimObject, id: string) {
  return node.attributes['IdentifiedObject.name'] || id;
}

function getNodeCoordinates(node: CimObject, cim: CimJSON) {
  const cncID = getSingleRef(
    node,
    'ConnectivityNode.ConnectivityNodeContainer'
  );
  const connectivityNodesContainer = cncID && cim.objects[cncID];
  let locationID;

  if (
    connectivityNodesContainer &&
    connectivityNodesContainer.class === 'ConnectivityNodeContainer'
  ) {
    locationID = getSingleRef(
      connectivityNodesContainer,
      'PowerSystemResource.Location'
    );
  } else {
    // Look for a Connector (Junction or BusbarSection)
    let i = 0;
    let busBarLocation;
    const terminals = getMultiRef(node, 'ConnectivityNode.Terminals');

    for (i = 0; i < terminals.length; i += 1) {
      const t = cim.objects[terminals[i]];
      // in the case of meshed feeders, some of the terminals may not be present in the network
      // as they are part of another feeder
      if (t) {
        const eq = cim.objects[getSingleRef(t, 'Terminal.ConductingEquipment')];
        if (eq !== undefined) {
          if (eq.class === 'Junction') {
            locationID = getSingleRef(eq, 'PowerSystemResource.Location');
            break;
          } else if (eq.class === 'BusbarSection') {
            busBarLocation = getSingleRef(eq, 'PowerSystemResource.Location');
          }
        }
      }
    }

    // If there is a Junction location use it, then fallback to the BusbarSection location
    locationID = locationID || busBarLocation;
  }
  const location = cim.objects[locationID || ''];
  const positionID = getMultiRef(location, 'Location.PositionPoints')[0];
  const position = cim.objects[positionID];
  return [
    parseFloat(position.attributes['PositionPoint.xPosition']),
    parseFloat(position.attributes['PositionPoint.yPosition']),
  ];
}

function getLinePhase(line: CimObject, cim: CimJSON) {
  const phases = getMultiRef(line, 'ACLineSegment.ACLineSegmentPhases').map(
    (aclpID: string) => {
      const phase: string =
        cim.objects[aclpID].attributes['ACLineSegmentPhase.phase'];
      return phase;
    }
  );
  phases.sort();
  return phases.join('');
}

function getLinkCoordinates(
  linkDevice: CimObject,
  cim: CimJSON,
  includeIntermediatePoints: boolean
) {
  let terminals = getMultiRef(linkDevice, 'ConductingEquipment.Terminals').map(
    (terminalID) => cim.objects[terminalID]
  );
  terminals = terminals.sort((t1, t2) => {
    const aSeqNum = t1.attributes['ACDCTerminal.sequenceNumber'];
    const bSeqNum = t2.attributes['ACDCTerminal.sequenceNumber'];
    if (aSeqNum < bSeqNum) {
      return -1;
    } else if (aSeqNum > bSeqNum) {
      return 1;
    }
    return 0;
  });
  const nodes = terminals.map(
    (terminal) =>
      cim.objects[getSingleRef(terminal, 'Terminal.ConnectivityNode')]
  );

  const coordinates = [getNodeCoordinates(nodes[0], cim)];

  if (includeIntermediatePoints) {
    // Check location
    const locationID = getSingleRef(linkDevice, 'PowerSystemResource.Location');
    const location = cim.objects[locationID];

    if (location) {
      let points = getMultiRef(location, 'Location.PositionPoints').map(
        (positionID) => cim.objects[positionID]
      );
      points = points.sort((a, b) => {
        const aSeqNum = a.attributes['PositionPoint.sequenceNumber'];
        const bSeqNum = b.attributes['PositionPoint.sequenceNumber'];
        if (aSeqNum < bSeqNum) {
          return -1;
        } else if (aSeqNum > bSeqNum) {
          return 1;
        }
        return 0;
      });
      points.forEach((point) => {
        coordinates.push([
          parseFloat(point.attributes['PositionPoint.xPosition']),
          parseFloat(point.attributes['PositionPoint.yPosition']),
        ]);
      });
    }
  }

  coordinates.push(getNodeCoordinates(nodes[1], cim));
  return coordinates;
}

function getShuntCoordinates(
  shuntDevice: CimObject,
  cim: CimJSON
): Array<number> | null {
  const locationID = getSingleRef(shuntDevice, 'PowerSystemResource.Location');
  const location = cim.objects[locationID];

  if (!location) {
    return null;
  }

  const point =
    cim.objects[getMultiRef(location, 'Location.PositionPoints')[0]];
  if (!point) {
    return null;
  }

  return [
    parseFloat(point.attributes['PositionPoint.xPosition']),
    parseFloat(point.attributes['PositionPoint.yPosition']),
  ];
}

function getShuntAssetType(device: CimObject, cim: CimJSON): string {
  const klass = device.class;
  let type = klass;

  if (klass === 'AsynchronousMachine') {
    // May be a Wind (WindGeneratingUnit) or plain
    const genUnit =
      cim.objects[getSingleRef(device, 'RotatingMachine.GeneratingUnit')];
    if (genUnit && genUnit.class === 'WindGeneratingUnit') {
      type = 'Wind';
    }
  } else if (klass === 'Inverter') {
    // May be a battery or PV
    const units = getMultiRef(
      device,
      'PowerElectronicsConnection.PowerElectronicsUnit'
    ).map((id) => cim.objects[id]);
    const types = new Set(units.map((unit) => unit.class));

    type = types.has('PhotoVoltaicUnit') ? 'PhotoVoltaic' : 'Battery';
  } else if (klass === 'LinearShuntCompensator') {
    // May be a shunt capacitor or reactor
    const phaseIDs =
      getMultiRef(device, 'ShuntCompensator.ShuntCompensatorPhase') || [];

    if (phaseIDs.length === 0) {
      // Balanced
      const b = device.attributes['LinearShuntCompensator.bPerSection'];
      type = b >= 0 ? 'ShuntCapacitor' : 'ShuntReactor';
    } else {
      const phases = phaseIDs.map((id) => cim.objects[id]);
      const all_positive = phases.every((shuntCompensatorPhase) => {
        return (
          shuntCompensatorPhase.attributes[
            'LinearShuntCompensatorPhase.bPerSection'
          ] >= 0
        );
      });
      type = all_positive ? 'ShuntCapacitor' : 'ShuntReactor';
    }
  } else if (klass === 'SynchronousMachine') {
    // Might be a hydro plant
    const genUnit =
      cim.objects[getSingleRef(device, 'RotatingMachine.GeneratingUnit')];
    if (genUnit && genUnit.class === 'HydroGeneratingUnit') {
      type = 'Hydro';
    }
  } else if (klass === 'EnergyConsumer') {
    // May be a DR load
    const usagePoint = getSingleRef(device, 'Equipment.UsagePoints');
    const UsagePointGroup =
      cim.objects[usagePoint].references['UsagePoint.UsagePointGroups']?.[0];
    if (UsagePointGroup) {
      type = 'EnergyConsumerDr';
    }
  }

  return type;
}

function getLinkAssetDetails(
  device: CimObject,
  cim: CimJSON
): { asset_type: string; closed?: boolean } {
  const klass = device.class;
  const details = { asset_type: klass, closed: false };

  if (klass === 'PowerTransformer') {
    // Could be a transformer or a regulator
    details.asset_type = isRegulator(device, cim)
      ? 'Regulator'
      : 'PowerTransformer';
  } else {
    // Switch of some type. Set closed attribute
    const phaseIDs = getMultiRef(device, 'Switch.SwitchPhase') || [];

    if (phaseIDs.length === 0) {
      // Balanced case
      details.closed = device.attributes['Switch.open'] === false;
    } else {
      const phases = phaseIDs.map((id) => cim.objects[id]);
      details.closed = phases.every(
        (switchPhase) => switchPhase.attributes['SwitchPhase.closed'] === true
      );
    }
  }

  return details;
}

const SHUNT_CLASSES = [
  'AsynchronousMachine',
  'EnergyConsumer',
  'EnergySource',
  'EquivalentSubstation',
  'Inverter',
  'LinearShuntCompensator',
  'SynchronousMachine',
];
const LINK_CLASSES = [
  'Breaker',
  'Cut',
  'Disconnector',
  'Fuse',
  'Jumper',
  'PowerTransformer',
  'Recloser',
  'Sectionaliser',
  'Switch',
];

export function convertCimToGeoJson(cim: CimJSON | null) {
  const model: NetworkModel = {
    lines: {},
    linkConnectors: {},
    linkIcons: {},
    nodes: {},
    nodeConnectors: {},
    nodeIcons: {},
  };

  if (cim === null) {
    return model;
  }

  // NODES
  (cim.classes.ConnectivityNode || []).forEach((nodeID) => {
    const node = cim.objects[nodeID];
    if (!node) {
      return;
    }

    model.nodes[nodeID] = {
      type: 'Feature',
      properties: {
        id: nodeID,
        name: getCimName(node, nodeID),
        feeder: getSingleRef(
          node,
          'ConnectivityNode.ConnectivityNodeContainer'
        ),
      },
      geometry: {
        type: 'Point',
        coordinates: getNodeCoordinates(node, cim),
      },
    };
  });

  // LINES
  (cim.classes.ACLineSegment || []).forEach((lineID) => {
    const line = cim.objects[lineID];
    if (!line) {
      return;
    }

    model.lines[lineID] = {
      type: 'Feature',
      properties: {
        id: lineID,
        name: getCimName(line, lineID),
        feeder: getSingleRef(line, 'Equipment.EquipmentContainer'),
        phase: getLinePhase(line, cim),
      },
      geometry: {
        type: 'LineString',
        coordinates: getLinkCoordinates(line, cim, true),
      },
    };
  });

  // Shunt Devices
  SHUNT_CLASSES.forEach((klass) => {
    (cim.classes[klass] || []).forEach((id) => {
      const device = cim.objects[id];

      if (!device) {
        return;
      }

      const terminalID = getMultiRef(
        device,
        'ConductingEquipment.Terminals'
      )[0];
      const terminal = cim.objects[terminalID];
      const node =
        cim.objects[getSingleRef(terminal, 'Terminal.ConnectivityNode')];
      const nodeCoordinates = getNodeCoordinates(node, cim);
      const iconCoordinates = getShuntCoordinates(device, cim);

      if (iconCoordinates !== null) {
        // The icon is offset from the node. Show a connector
        model.nodeConnectors[id] = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [nodeCoordinates, iconCoordinates],
          },
          properties: {
            id,
            feeder: getSingleRef(device, 'Equipment.EquipmentContainer'),
          },
        };
      }

      model.nodeIcons[id] = {
        type: 'Feature',
        properties: {
          id,
          name: getCimName(device, id),
          feeder: getSingleRef(device, 'Equipment.EquipmentContainer'),
          asset_type: getShuntAssetType(device, cim),
        },
        geometry: {
          type: 'Point',
          coordinates: iconCoordinates ? iconCoordinates : nodeCoordinates,
        },
      };
    });

    // Link Devices
    LINK_CLASSES.forEach((klass) => {
      (cim.classes[klass] || []).forEach((id) => {
        const device = cim.objects[id];

        if (!device) {
          return;
        }

        const coordinates = getLinkCoordinates(device, cim, false);
        model.linkConnectors[id] = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates,
          },
          properties: {
            id,
            feeder: getSingleRef(device, 'Equipment.EquipmentContainer'),
          },
        };
        model.linkIcons[id] = {
          type: 'Feature',
          properties: {
            id,
            name: getCimName(device, id),
            feeder: getSingleRef(device, 'Equipment.EquipmentContainer'),
            ...getLinkAssetDetails(device, cim),
          },
          geometry: {
            type: 'Point',
            coordinates: [
              (coordinates[0][0] + coordinates[1][0]) / 2,
              (coordinates[0][1] + coordinates[1][1]) / 2,
            ],
          },
        };
      });
    });
  });

  return model;
}
