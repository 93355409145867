import React, { ReactNode } from 'react';
import './FileForm.scss';

type FileFormProps = {
  accept?: string;
  children: ReactNode | Element;
  className?: string;
  createRef: (instance: HTMLFormElement | null) => void;
  disabled?: boolean;
  id: string;
  onChange: (event: React.FormEvent<HTMLFormElement>) => void;
};

// Create a button like file form that wraps a custom element in the label.
const FileForm = (props: FileFormProps) => (
  <form
    encType="multipart/form-data"
    className={`file-form-btn ${props.className} ${
      props.disabled && 'file-form-btn--disabled'
    }`}
    onChange={props.onChange}
    ref={props.createRef}
  >
    <input
      className="file-form-input"
      type="file"
      name="files[]"
      id={props.id}
      accept={props.accept}
    />
    <label className="file-form-input__label" htmlFor={props.id}>
      {props.children}
    </label>
  </form>
);

FileForm.defaultProps = {
  accept: '*',
  className: '',
  disabled: false,
  createRef: () => null,
};

export default FileForm;
