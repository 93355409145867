import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Weather from './Weather';
import WeatherStation from './routes/WeatherStation';

const WeatherContainer = () => {
  return (
    <Switch>
      <Route exact path="/measurements/weather">
        <Weather />
      </Route>
      <Route path="/measurements/weather/station/:stationName">
        <WeatherStation />
      </Route>
    </Switch>
  );
};

export default WeatherContainer;
