import React from 'react';
import './Checkbox.scss';

type CheckboxProps = {
  backgroundColor?: string;
  borderColor?: string;
  checked?: boolean;
  className?: string;
  dash?: boolean;
  disabled?: boolean;
  focusable: boolean;
  id: string;
  onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};

/**
 * Creates a custom styled controlled checked checkbox
 * background color and unchecked border color can be customized
 */
const Checkbox = (props: CheckboxProps) => {
  const showCustomBG =
    !props.disabled && props.checked && props.backgroundColor;
  const showCustomBorder =
    !props.disabled && !props.checked && props.borderColor;
  return (
    <div
      className={`custom-checkbox
        ${props.disabled ? 'custom-checkbox--disabled' : ''}
        ${props.className}
        ${
          props.checked
            ? `custom-checkbox--checked ${props.className}--checked`
            : ''
        }`}
    >
      <input
        className="check-input"
        type="checkbox"
        tabIndex={props.focusable ? undefined : -1}
        id={props.id}
        readOnly
        checked={props.checked}
        onClick={props.onClick}
        disabled={props.disabled}
      />
      <label
        className="check-container"
        htmlFor={props.id}
        style={{
          backgroundColor: showCustomBG ? props.backgroundColor : undefined,
          borderColor: showCustomBorder ? props.borderColor : undefined,
        }}
      >
        {props.dash ? (
          <div className="dash" style={{ opacity: props.checked ? 1 : 0 }} />
        ) : (
          <div className="check" style={{ opacity: props.checked ? 1 : 0 }} />
        )}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  value: ' ',
  checked: false,
  id: 'checkbox',
  backgroundColor: null,
  borderColor: null,
  focusable: true,
  onClick: null,
  disabled: false,
  className: null,
  dash: false,
};

export default Checkbox;
