import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import {
  useAuthContext,
  ProtectedRoute,
} from '@opusonesolutions/gridos-app-framework';

import Header from 'components/Header';
import Navigation from 'components/Navigation/Navigation';

import Admin from 'routes/Admin';
import CreateProgram from 'routes/CreateProgram';
import DERs from 'routes/DERs';
import Home from 'routes/Home';
import ISOs from 'routes/ISOs';
import Login from 'routes/Login';
import Measurements from 'routes/Measurements';
import Participants from 'routes/Participants';
import Program from 'routes/Program';

import './App.scss';

function App() {
  const { isLoading, isAuthenticated } = useAuthContext();
  // If the program ID is in the URL, init it into the state
  // useRouteMatch returns null if no match
  const loginMatch = useRouteMatch('/login');
  const programMatch = useRouteMatch('/program/:programID');
  const [programId, setProgramId] = useState(
    // @ts-ignore
    programMatch ? Number(programMatch.params.programID) : null
  );
  const history = useHistory();

  useEffect(() => {
    if (programMatch !== null) {
      // @ts-ignore
      setProgramId(Number(programMatch.params.programID));
    } else {
      setProgramId(null);
    }
  }, [programMatch]);

  return (
    <div className="te">
      <Header
        onLoginPage={loginMatch !== null}
        programId={programId}
        setSelectedProgram={(newProgramId) => {
          setProgramId(newProgramId);

          if (newProgramId === null) {
            history.push('/');
          } else {
            // Keep relative paths when switching programs
            let newPath = `/program/${newProgramId}`;

            if (history.location.pathname.includes('/program/')) {
              // This happens when we're on a route like /program/0/settings. Want to retain
              // the subpath to a new program
              newPath = history.location.pathname.replace(
                `/program/${programId}`,
                newPath
              );
              // Want to strip off any program specific subroutes
              // (i.e. /program/:programId/containers/:feederId -> /program/:programId/containers)
              // @ts-ignore
              newPath = newPath.match(/\/program\/([^/]+\/?){1,2}/)[0];
            }
            history.push(newPath);
          }
        }}
      />
      <div className="container">
        {loginMatch === null && !isLoading && isAuthenticated && <Navigation />}
        <Switch>
          <ProtectedRoute exact path="/">
            <Home />
          </ProtectedRoute>
          <ProtectedRoute path="/participants">
            <Participants />
          </ProtectedRoute>
          <ProtectedRoute path="/ders">
            <DERs />
          </ProtectedRoute>
          <ProtectedRoute path="/isos">
            <ISOs />
          </ProtectedRoute>
          <Route path="/login">
            <Login />
          </Route>
          <ProtectedRoute path="/measurements">
            <Measurements />
          </ProtectedRoute>
          <ProtectedRoute path="/program/:programID">
            <Program />
          </ProtectedRoute>
          <ProtectedRoute path="/create-program">
            <CreateProgram />
          </ProtectedRoute>
          <ProtectedRoute path="/admin">
            <Admin />
          </ProtectedRoute>
        </Switch>
      </div>
    </div>
  );
}

export default App;
