import { DateTime } from 'luxon';

export enum BidOfferType {
  BID = 'BID',
  OFFER = 'OFFER',
}

export const isBidOfferType = (item: any): item is BidOfferType => {
  if (item === BidOfferType.BID || item === BidOfferType.OFFER) {
    return true;
  }
  return false;
};

export enum MarketType {
  DAYAHEAD = 'DAYAHEAD',
  SAMEDAY = 'SAMEDAY',
}

export interface BidOffer {
  der_id: number;
  der_rdf_id: string;
  duration: number;
  endTime: DateTime;
  marketType: MarketType;
  price?: number;
  quantity?: number;
  startTime: DateTime;
  type: BidOfferType;
}

export interface ResolvedBidOffer {
  id: number;
  der_id: number;
  der_rdf_id: string;
  duration: number;
  marketType: MarketType;
  price?: number;
  quantity?: number;
  type: BidOfferType;
}

export interface SelectOption {
  isDisabled?: boolean;
  label: string;
  value: any;
}
