import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import HeaderLayout from 'components/HeaderLayout';
import NumberInput from 'components/NumberInput';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Select from 'components/Select';
import TextInput from 'components/TextInput';

import { ProgramsContext } from 'contexts/ProgramsContext';

import TimeZones from 'helpers/timezones';

import './CreateProgram.scss';

const timezoneOpts = TimeZones.map((name) => ({ label: name, value: name }));
const financialModelOpts = [
  { label: 'DLMP', id: 'DLMP' },
  { label: 'LMP+D', id: 'LMPD' },
  { label: 'Pay as bid', id: 'PAY_AS_BID' },
  { label: 'Pay as clear', id: 'PAY_AS_CLEAR' },
];
const eventDurationOpts = [
  { label: '1 Hour', value: 3600 },
  { label: '30 Minute', value: 1800 },
  { label: '15 Minute', value: 900 },
  { label: '5 Minute', value: 300 },
];
const eventMethodOpts = [
  { label: 'Batch', value: 'BATCH' },
  { label: 'Sequential', value: 'SEQUENTIAL' },
];
const batteryControlOpts = [
  { label: 'Any', id: 'ANY' },
  { label: 'GridOS', id: 'GRIDOS' },
  { label: 'Participant', id: 'PARTICIPANT' },
];
const marketObjectiveOpts = [
  { label: 'Minimize Losses', value: 'LOSS' },
  { label: 'Minimize Costs', value: 'COST' },
  { label: 'Minimize PV Curtailments', value: 'PV' },
  { label: 'Minimize PV Curtailments via BESS', value: 'PV_BESS' },
];

enum marketObjective {
  'LOSS' = 'LOSS',
  'COST' = 'COST',
  'PV' = 'PV',
  'PV_BESS' = 'PV_BESS',
}

type CreateProgramKey =
  | 'auto_confirm_enabled'
  | 'is_activated'
  | 'iso'
  | 'market_control_strategy'
  | 'name'
  | 'sameday_event_duration'
  | 'sameday_event_method'
  | 'timezone'
  | 'workspace_name'
  | 'financial_model'
  | 'dispatch_grace_period'
  | 'battery_control'
  | 'allow_reverse_active_powerflow'
  | 'allow_reverse_reactive_powerflow'
  | 'generate_nodal_load_forecasts'
  | 'skip_nodal_losses_calculation';

interface CreateProgram {
  auto_confirm_enabled: boolean;
  constraint_energy_management: boolean;
  currency: string;
  is_activated?: boolean;
  iso: string;
  locale: string;
  market_control_strategy:
    | 'LOSS_OPTIMIZE_ENROLLED_ASSETS'
    | 'COST_OPTIMIZE_ENROLLED_ASSETS';
  name: string;
  sameday_event_duration: number;
  sameday_event_method: string;
  timezone: string;
  workspace_name?: string;
  financial_model: 'LMPD' | 'DLMP' | 'PAY_AS_BID' | 'PAY_AS_CLEAR';
  dispatch_grace_period: number;
  battery_control: string;
  allow_reverse_active_powerflow: boolean;
  allow_reverse_reactive_powerflow: boolean;
  generate_nodal_load_forecasts: boolean;
  skip_nodal_losses_calculation: boolean;
}

interface Program extends CreateProgram {
  iso_id: number;
  program_id: number;
  workspace_name: string;
}

interface SelectInterface {
  label: string;
  value: string;
}

const DEFAULT_PROGRAM: CreateProgram = {
  auto_confirm_enabled: false,
  constraint_energy_management: false,
  currency: 'USD',
  is_activated: true,
  iso: '',
  locale: 'en-US',
  market_control_strategy: 'LOSS_OPTIMIZE_ENROLLED_ASSETS',
  name: '',
  sameday_event_duration: 3600,
  timezone: 'America/Toronto',
  financial_model: 'LMPD',
  sameday_event_method: 'BATCH',
  dispatch_grace_period: 0,
  battery_control: 'ANY',
  allow_reverse_active_powerflow: false,
  allow_reverse_reactive_powerflow: true,
  generate_nodal_load_forecasts: false,
  skip_nodal_losses_calculation: false,
};

const CreateProgramComponent = () => {
  const history = useHistory();
  const [program, setProgram] = useState<CreateProgram>({ ...DEFAULT_PROGRAM });
  const [selectedMarketObjective, setMarketObjective] = useState<
    marketObjective | undefined
  >(undefined);
  const [saveMessages, setSaveMessages] = useState<{
    [key: string]: Array<string>;
  }>({});

  const { addProgram } = useContext(ProgramsContext);

  const { data: workspaces, loading: loadingWorkspaces } = useRequestEffect<
    SelectInterface[]
  >({
    url: '/api/workspace',
    method: 'get',
    refetchOnChange: [],
    initialData: [],
    dataTransform: (data: { workspaces: Array<string> }) =>
      data.workspaces.map((w) => ({ label: w, value: w })),
    toast: {
      error: 'Could not load list of workspaces',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { data: ISOs, loading: loadingISOs } = useRequestEffect<
    SelectInterface[]
  >({
    url: '/api/dsp/program/isos',
    method: 'get',
    refetchOnChange: [],
    initialData: [],
    dataTransform: (data: Array<string>) =>
      data.map((label) => ({ label, value: label })),
    toast: {
      error: 'Could not load list of ISOs.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const updateProp = (key: CreateProgramKey, value: any) => {
    if (program === null || program[key] === value) {
      // No change happened
      return;
    }
    setProgram({
      ...program,
      [key]: value,
    });
  };

  const { makeRequest: runCreateProgram, loading: saving } = useRequest(
    '/api/dsp/program'
  );

  const createProgram = async () => {
    await runCreateProgram({
      method: 'post',
      onSuccess: (data: Program) => {
        setSaveMessages({});
        addProgram(data);
        history.push(`/program/${data.program_id}/settings`);
      },
      onError: (error: any) => {
        if (error.response && error.response.status === 400) {
          setSaveMessages(error.response.data.message);
        }
      },
      toast: {
        error: 'Could not create program',
        success: 'New program created successfully!',
        settings: {
          autoDismiss: true,
        },
      },
      body: program,
      dataTransform: undefined,
      blockRequest: undefined,
    });
  };

  const disabled = loadingWorkspaces || loadingISOs || saving;

  useEffect(() => {
    if (selectedMarketObjective === marketObjective.LOSS) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'ANY');
      updateProp('market_control_strategy', 'LOSS_OPTIMIZE_ENROLLED_ASSETS');
    } else if (selectedMarketObjective === marketObjective.COST) {
      updateProp('financial_model', 'DLMP');
      updateProp('battery_control', 'ANY');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    } else if (selectedMarketObjective === marketObjective.PV) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'PARTICIPANT');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    } else if (selectedMarketObjective === marketObjective.PV_BESS) {
      updateProp('financial_model', 'LMPD');
      updateProp('battery_control', 'GRIDOS');
      updateProp('market_control_strategy', 'COST_OPTIMIZE_ENROLLED_ASSETS');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarketObjective, program]);

  useEffect(() => {
    if (program.financial_model !== 'LMPD') {
      updateProp('skip_nodal_losses_calculation', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  return (
    <HeaderLayout
      className="create-program"
      title="Create Program"
      titleRightContent={
        <Button
          disabled={
            saving ||
            !program.name ||
            !program.workspace_name ||
            !program.iso ||
            disabled
          }
          label="Save"
          onClick={() => createProgram()}
        />
      }
    >
      <div className="form-container">
        <div className="row">
          <TextInput
            disabled={saving}
            id="name"
            invalid={!!saveMessages.name}
            label="Program Name"
            onChange={(value) => updateProp('name', value)}
            placeholder="Name"
            required
            validationMessage={
              saveMessages.name ? saveMessages.name[0] : undefined
            }
            value={program.name}
          />
        </div>
        <div className="row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={disabled}
            label="Workspace"
            options={workspaces}
            onChange={(opt: SelectInterface) =>
              updateProp('workspace_name', opt.value)
            }
            value={workspaces?.find(
              ({ value }) => value === program.workspace_name
            )}
          />
        </div>
        <div className="row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={disabled}
            label="Timezone"
            options={timezoneOpts}
            onChange={(opt: SelectInterface) =>
              updateProp('timezone', opt.value)
            }
            isSearchable
            value={timezoneOpts.find(
              ({ value }) => value === program.workspace_name
            )}
          />
        </div>
        <div className="row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={disabled}
            label="Same Day Event Duration"
            options={eventDurationOpts}
            onChange={(opt: { value: number }) =>
              updateProp('sameday_event_duration', opt.value)
            }
            value={eventDurationOpts.find(
              ({ value }) => value === program.sameday_event_duration
            )}
          />
        </div>
        <div className="row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={saving}
            label="Same Day Event Method"
            options={eventMethodOpts}
            onChange={(opt) => updateProp('sameday_event_method', opt.value)}
            value={eventMethodOpts.find(
              ({ value }) => value === program.sameday_event_method
            )}
          />
        </div>
        <div className="row">
          <NumberInput
            id="dispatch_grace_period"
            label="Dispatch Grace Period"
            disabled={saving}
            onChange={(value) => updateProp('dispatch_grace_period', value)}
            min={0}
            required
            unit="s"
            value={program.dispatch_grace_period}
          />
        </div>
        <div className="row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={disabled}
            label="ISO"
            options={ISOs}
            onChange={(opt: SelectInterface) => updateProp('iso', opt.value)}
            value={ISOs?.find(({ value }) => value === program.iso)}
          />
        </div>
        <div className="row">
          <Select
            isClearable={false}
            isMulti={false}
            isDisabled={saving}
            label="Default Market Objectives"
            options={marketObjectiveOpts}
            onChange={(opt: SelectInterface) => {
              setMarketObjective(
                marketObjective[opt.value as keyof typeof marketObjective]
              );
            }}
            value={marketObjectiveOpts.find(
              ({ value }) => value === selectedMarketObjective
            )}
          />
        </div>
        <div className="objective-settings">
          <div className="row">
            <RadioButtonGroup
              id="bcontrol"
              listType="column"
              label="Battery Control"
              onChange={(value) => updateProp('battery_control', value)}
              options={batteryControlOpts}
              value={program.battery_control}
            />
          </div>
          <div className="row">
            <RadioButtonGroup
              id="fmodel"
              listType="column"
              label="Financial Model"
              onChange={(value) => updateProp('financial_model', value)}
              options={financialModelOpts}
              value={program.financial_model}
            />
          </div>
          <div className="row">
            <RadioButtonGroup
              id="control-strategy"
              listType="column"
              label="Market Control Strategy"
              onChange={(value) => updateProp('market_control_strategy', value)}
              options={[
                {
                  id: 'LOSS_OPTIMIZE_ENROLLED_ASSETS',
                  label: 'Loss-Optimize Enrolled Assets',
                },
                {
                  id: 'COST_OPTIMIZE_ENROLLED_ASSETS',
                  label: 'Cost-Optimize Enrolled Assets',
                },
              ]}
              value={program.market_control_strategy}
            />
          </div>
        </div>
        <div className="row">
          <RadioButtonGroup
            id="event-confirm"
            label="Event Confirmation"
            listType="column"
            onChange={(value) =>
              updateProp('auto_confirm_enabled', value === 'enabled')
            }
            options={[
              {
                id: 'disabled',
                label: 'Permissioned users will confirm events',
              },
              {
                id: 'enabled',
                label:
                  'GridOS will automatically confirm events based on acceptance by users',
              },
            ]}
            value={program.auto_confirm_enabled ? 'enabled' : 'disabled'}
          />
        </div>
        <div className="row">
          <Checkbox
            disabled={saving}
            checked={program.allow_reverse_active_powerflow}
            onClick={() =>
              updateProp(
                'allow_reverse_active_powerflow',
                !program.allow_reverse_active_powerflow
              )
            }
          />
          Allow Reverse Active Powerflow
        </div>
        <div className="row">
          <Checkbox
            disabled={saving}
            checked={program.allow_reverse_reactive_powerflow}
            onClick={() =>
              updateProp(
                'allow_reverse_reactive_powerflow',
                !program.allow_reverse_reactive_powerflow
              )
            }
          />
          Allow Reverse Reactive Powerflow
        </div>
        <div className="row">
          <Checkbox
            disabled={saving}
            checked={program.generate_nodal_load_forecasts}
            onClick={() =>
              updateProp(
                'generate_nodal_load_forecasts',
                !program.generate_nodal_load_forecasts
              )
            }
          />
          Generate Nodal Load Forecasts
        </div>
        <div className="row">
          <Checkbox
            disabled={saving || !(program.financial_model === 'LMPD')}
            checked={program.skip_nodal_losses_calculation}
            onClick={() =>
              updateProp(
                'skip_nodal_losses_calculation',
                !program.skip_nodal_losses_calculation
              )
            }
          />
          Skip Nodal Losses Calculation
        </div>
      </div>
    </HeaderLayout>
  );
};

export default CreateProgramComponent;
