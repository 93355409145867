import React, { createContext, useState, ReactNode } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Request,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

export type MeasurementType =
  | 'APPARENT_POWER'
  | 'CURRENT'
  | 'ENERGY'
  | 'REACTIVE_POWER'
  | 'REAL_POWER'
  | 'VOLTAGE';

export type Timeseries = {
  id: string;
  name: string;
};

export type TimeseriesTag = {
  id: string;
  name: string;
  timeseries_id: string;
  measurement_type: MeasurementType;
  unit_symbol?: string;
  unit_multiplier?: number;
  phase?: string;
};

export type TagValue = {
  id: string;
  tag_id: string;
  time_sampled: string;
  value: number;
};

interface MeasurementsContextProps {
  getTimeseries(): Timeseries[];
  getTimeseriesById(id: string): Timeseries | undefined;
  addTimeseries(name: string): void;
  deleteTimeseries(id: string): void;
  updateTimeseries(id: string, ts: Timeseries): void;

  deleteTimeseriesTag(
    timeseriesID: string,
    tagID: string,
    tagName: string
  ): void;
}

export const MeasurementsContext = createContext<MeasurementsContextProps>({
  getTimeseries() {
    return [];
  },
  getTimeseriesById(id) {
    return undefined;
  },
  async addTimeseries() {},
  async deleteTimeseries() {},
  async updateTimeseries() {},
  async deleteTimeseriesTag() {},
});

const timeseriesSorter = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const sortTimeseries = (series: Timeseries[]): Timeseries[] => {
  return series.sort((a, b) => timeseriesSorter.compare(a.name, b.name));
};

interface MeasurementsContextProviderProps {
  children: ReactNode;
}

const MeasurementsContextProvider = ({
  children,
}: MeasurementsContextProviderProps) => {
  const { addToast } = useToasts();
  const [timeseries, setTimeseries] = useState<Timeseries[]>([]);

  useRequestEffect<Timeseries[]>({
    url: '/api/dsp/measurements/timeseries',
    method: 'get',
    onSuccess: (data) => {
      if (data) {
        setTimeseries(sortTimeseries(data));
      }
    },
  });

  const { makeRequest: runAddTimeseries } = useRequest<Timeseries[]>(
    '/api/dsp/measurements/timeseries'
  );

  const addTimeseries = async (name: string) => {
    await runAddTimeseries({
      method: 'post',
      body: [
        {
          name,
        },
      ],
      toast: {
        success: `Successfully added timeseries '${name}'.`,
        error: (error) => {
          return error?.response?.status === 409
            ? 'Timeseries name already exists'
            : 'Failed to create timeseries';
        },
      },
      onSuccess: (data) => {
        if (data) {
          const newTimeseries = [...timeseries, ...data];
          setTimeseries(sortTimeseries(newTimeseries));
        }
      },
    });
  };

  const deleteTimeseries = async (id: string) => {
    let index = timeseries.findIndex((ts) => ts.id === id);
    if (index !== -1) {
      const request = new Request(`/api/dsp/measurements/timeseries/${id}`);
      const { name } = timeseries[index];

      try {
        await request.delete();
        addToast(`Deleted timeseries '${name}'`, { appearance: 'success' });

        // The index may have changed since we had to wait for the delete.
        // Re-get the index
        index = timeseries.findIndex((ts) => ts.id === id);
        if (index !== -1) {
          const newTimeseries = [...timeseries];
          newTimeseries.splice(index, 1);
          setTimeseries(newTimeseries);
        }
      } catch {
        addToast(`Failed to delete timeseries '${name}'.`, {
          appearance: 'error',
        });
      }
    }
  };

  const getTimeseries = () => timeseries;
  const getTimeseriesById = (id: string) => {
    return timeseries.find((ts) => ts.id === id);
  };

  const updateTimeseries = async (id: string, ts: Timeseries) => {
    let index = timeseries.findIndex((ts) => ts.id === id);
    if (index !== -1) {
      const request = new Request(`/api/dsp/measurements/timeseries/${id}`);
      const { name } = timeseries[index];

      try {
        const { data: newTS } = await request.patch(ts);
        addToast(`Updated timeseries '${name}'`, { appearance: 'success' });

        // The index may have changed since we had to wait for the delete.
        // Re-get the index
        index = timeseries.findIndex((ts) => ts.id === id);
        if (index !== -1) {
          const newTimeseries = [...timeseries];
          newTimeseries[index] = newTS;

          // Name may have changed, need to re-sort the data
          setTimeseries(sortTimeseries(newTimeseries));
        }
      } catch {
        addToast(`Failed to update timeseries '${name}'.`, {
          appearance: 'error',
        });
      }
    }
  };

  const deleteTimeseriesTag = async (
    timeseriesID: string,
    tagID: string,
    tagName: string
  ) => {
    const request = new Request(
      `/api/dsp/measurements/timeseries/${timeseriesID}/tag/${tagID}`
    );
    try {
      await request.delete();
      addToast(`Deleted tag '${tagName}'`, { appearance: 'success' });
    } catch {
      addToast(`Failed to delete tag '${tagName}'.`, { appearance: 'error' });
    }
  };

  return (
    <MeasurementsContext.Provider
      value={{
        addTimeseries,
        deleteTimeseries,
        getTimeseries,
        getTimeseriesById,
        updateTimeseries,

        // Tag APIs
        deleteTimeseriesTag,
      }}
    >
      {children}
    </MeasurementsContext.Provider>
  );
};

export const MeasurementsProvider = MeasurementsContextProvider;
