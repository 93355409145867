import React, { memo, ReactNode } from 'react';
import { DateTime } from 'luxon';

import Badge from 'components/Badge';
import Tooltip from 'components/Tooltip';

import './ISOListItem.scss';

type ISOListEntry = {
  last_status: string;
  last_timestamp: string;
};

type ISOListItemProps = {
  entries: ISOListEntry[];
  title: ReactNode;
};

function getSummary(entries: ISOListEntry[] = []) {
  return entries.reduce(
    (acc, entry) => {
      if (!entry || !entry.last_status || !entry.last_timestamp) {
        return acc;
      }

      const timestamp = DateTime.fromISO(entry.last_timestamp);
      if (timestamp > acc.mostRecentUpdate) {
        acc.mostRecentUpdate = timestamp;
      }

      if (entry.last_status === 'success') {
        acc.success += 1;
      } else {
        acc.fail += 1;
      }

      return acc;
    },
    { fail: 0, mostRecentUpdate: DateTime.fromMillis(0), success: 0 }
  );
}

function ISOListItem({ entries, title }: ISOListItemProps) {
  const summary = getSummary(entries);

  return (
    <div className="iso-list-item">
      <p className="col name">{title}</p>

      <p className="col status">
        {summary.success > 0 && (
          <>
            <Badge badgeType="success">Success</Badge>
            <span>{`${summary.success} Zone(s)`}</span>
          </>
        )}

        {summary.fail > 0 && (
          <>
            <Badge badgeType="error">Failed</Badge>
            <span>{`${summary.fail} Zone(s)`}</span>
          </>
        )}

        {!summary.fail && !summary.success && <span>No Zones Detected</span>}
      </p>

      <p className="col updated">
        {summary.mostRecentUpdate > DateTime.fromMillis(0) && (
          <Tooltip
            content={summary.mostRecentUpdate.toFormat('DDD hh:mm:ss a ZZ')}
          >
            <span>{`Updated ${summary.mostRecentUpdate.toFormat('DDD')}`}</span>
          </Tooltip>
        )}
      </p>
    </div>
  );
}

export default memo(ISOListItem);
