import { ChartDataset } from 'chart.js';
import { DateTime } from 'luxon';
import React, { useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import ChartWrapper from 'components/ChartWrapper';
import DateRangePicker from 'components/DateRangePicker';
import HeaderLayout from 'components/HeaderLayout';
import Select from 'components/Select';
import { ProgramsContext } from 'contexts/ProgramsContext';

import useQueryState, {
  getDateFromParam,
  serializeDate,
} from 'hooks/useQueryState';

import './LoadForecast.scss';
import { SelectOption } from 'types';

type ForecastData = {
  load: {
    [key: string]: {
      pA: number;
      pB: number;
      pC: number;
      pABC: number;
      qA: number;
      qB: number;
      qC: number;
      qABC: number;
    };
  };
  timestamp: string;
};

const getQueryParamAsInt = (params: URLSearchParams, key: string) => {
  const value = params.get(key);

  if (value === null) {
    return value;
  }
  const parsed = parseInt(value);
  return isNaN(parsed) ? null : parsed;
};

const alphabeticalSorter = Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const LoadForecast = () => {
  const { getProgram, programs } = useContext(ProgramsContext);
  const programOptions = useMemo(
    () =>
      programs.map(({ program_id, name }) => ({
        label: name,
        value: program_id,
      })),
    [programs]
  );

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [selectedProgramID, setSelectedProgramID] = useQueryState(
    getQueryParamAsInt(params, 'programID'),
    'programID'
  );
  const [selectedLoadID, setSelectedLoadID] = useQueryState(
    params.get('loadID'),
    'loadID'
  );
  const [endDate, setEndDate] = useQueryState(
    getDateFromParam(params, 'endDate', DateTime.local().endOf('day')).endOf(
      'day'
    ),
    'endDate',
    serializeDate
  );
  const [startDate, setStartDate] = useQueryState(
    getDateFromParam(params, 'startDate', DateTime.local().startOf('day')),
    'startDate',
    serializeDate
  );

  const program = getProgram(selectedProgramID);
  const timezone = program?.timezone;
  const workspace = program?.workspace_name;
  const { data: loadOptions, loading: loadingLoadList } = useRequestEffect<
    SelectOption[]
  >({
    url: `/api/workspace/${workspace}/branch/master/class/EnergyConsumer`,
    method: 'get',
    refetchOnChange: [selectedProgramID],
    blockRequest: () => program === null,
    initialData: [],
    dataTransform: (data) => {
      if (!data) {
        return [];
      }

      return Object.keys(data)
        .map((id) => ({
          label: data[id].attributes['IdentifiedObject.name'] || '',
          value: id,
        }))
        .sort((a, b) => alphabeticalSorter.compare(a.label, b.label));
    },
    toast: {
      error: 'Failed to load list of loads from network model.',
      settings: {
        autoDismiss: true,
      },
    },
  });
  useEffect(() => {
    if (loadOptions && loadOptions.length && selectedLoadID === null) {
      // No load was selected, select the first item in the list
      setSelectedLoadID(loadOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOptions]);

  const { data: sameDayDataset } = useRequestEffect<ChartDataset<'line'>>({
    url: `/api/measurement/program/${selectedProgramID}/forecasts/short_term`,
    method: 'get',
    refetchOnChange: [selectedProgramID, startDate, endDate, selectedLoadID],
    params: {
      start_time: startDate.startOf('day').toISO(),
      end_time: endDate.endOf('day').toISO(),
      rdf_id: selectedLoadID,
      market_type: 'sameday',
    },
    blockRequest: () => !selectedProgramID || !selectedLoadID,
    dataTransform: (data: Array<ForecastData>) => {
      return {
        label: 'GridOS: Same Day',
        backgroundColor: '#7f0046',
        borderColor: '#7f0046',
        fill: false,
        stepped: 'before',
        pointRadius: 0,
        data: (data || []).map((forecastData) => {
          const value = selectedLoadID
            ? forecastData.load[selectedLoadID].pABC
            : NaN;
          return {
            x: DateTime.fromISO(forecastData.timestamp).valueOf(),
            y: value,
          };
        }),
      };
    },
    toast: {
      error: 'Could not load GridOS sameday forecast data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { data: dayAheadDataset } = useRequestEffect<ChartDataset<'line'>>({
    url: `/api/measurement/program/${selectedProgramID}/forecasts/short_term`,
    method: 'get',
    refetchOnChange: [selectedProgramID, startDate, endDate, selectedLoadID],
    params: {
      start_time: startDate.startOf('day').toISO(),
      end_time: endDate.endOf('day').toISO(),
      rdf_id: selectedLoadID,
      market_type: 'dayahead',
    },
    blockRequest: () => !selectedProgramID || !selectedLoadID,
    dataTransform: (data: Array<ForecastData>) => {
      return {
        label: 'GridOS: Day Ahead',
        backgroundColor: '#00467F',
        borderColor: '#00467F',
        fill: false,
        stepped: 'before',
        pointRadius: 0,
        data: (data || []).map((forecastData) => {
          const value = selectedLoadID
            ? forecastData.load[selectedLoadID].pABC
            : NaN;
          return {
            x: DateTime.fromISO(forecastData.timestamp).valueOf(),
            y: value,
          };
        }),
      };
    },
    toast: {
      error: 'Could not load GridOS dayahead forecast data',
      settings: {
        autoDismiss: true,
      },
    },
  });

  return (
    <HeaderLayout
      className="load-forecast"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/measurements',
              label: <h2 className="title">Measurements</h2>,
            },
          ]}
          separator="/"
          currentHeader="Load Forecasts"
        />
      }
    >
      <div className="control-row">
        <div className="control-wrapper">
          <Select
            isClearable={true}
            isMulti={false}
            label="Program"
            onChange={(opt) => {
              if (opt) {
                setSelectedProgramID(opt.value);
              } else {
                setSelectedProgramID(null);
              }
            }}
            options={programOptions}
            row
            value={programOptions.find(
              (opt) => opt.value === selectedProgramID
            )}
          />
        </div>
        <div className="control-wrapper">
          <Select
            isClearable={true}
            isDisabled={loadingLoadList}
            isMulti={false}
            label="Load"
            onChange={(opt) => {
              if (opt) {
                setSelectedLoadID(opt.value);
              } else {
                setSelectedLoadID(null);
              }
            }}
            options={loadOptions}
            row
            value={(loadOptions || []).find(
              (opt) => opt.value === selectedLoadID
            )}
          />
        </div>
        <div className="date-wrapper">
          <DateRangePicker
            endDate={endDate}
            startDate={startDate}
            onChange={(startDate, endDate) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
        </div>
      </div>
      {timezone && dayAheadDataset && sameDayDataset && (
        <ChartWrapper
          config={{
            type: 'line',
            data: {
              datasets: [dayAheadDataset, sameDayDataset],
              labels: [],
            },
            options: {
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  intersect: false,
                  mode: 'nearest',
                  axis: 'x',
                  callbacks: {
                    title: (tooltipItems) => {
                      let title = '';
                      if (tooltipItems.length > 0) {
                        const item = tooltipItems[0];
                        const dateTime = DateTime.fromMillis(
                          item.parsed.x
                        ).setZone(timezone);
                        title = dateTime.toFormat('DDD hh:mm:ss a ZZ');
                      }

                      return title;
                    },
                    label: (tooltipItem) => {
                      const { parsed, label } = tooltipItem;
                      let value = parsed.y;
                      value = Math.round(value * 100) / 100 / 1e3;
                      return `${label}: ${value.toLocaleString()} kW`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  adapters: {
                    date: {
                      zone: timezone,
                    },
                  },
                  min: startDate.startOf('day').setZone(timezone).valueOf(),
                  max: endDate.endOf('day').setZone(timezone).valueOf(),
                  type: 'time',
                  offset: true,
                  title: {
                    display: true,
                    text: `Time (${timezone})`,
                  },
                  ticks: {
                    major: {
                      enabled: true,
                    },
                    source: 'auto',
                    autoSkip: true,
                    autoSkipPadding: 75,
                    maxRotation: 0,
                    sampleSize: 100,
                  },
                },
                y: {
                  suggestedMin: 0,
                  suggestedMax: 1e3,
                  grid: {
                    drawBorder: false,
                  },
                  title: {
                    display: true,
                    text: 'Forecasted Load (kW)',
                  },
                  ticks: {
                    // @ts-expect-error
                    callback: (value: number) => `${(value / 1e3).toFixed(2)}`,
                  },
                },
              },
            },
          }}
        />
      )}
    </HeaderLayout>
  );
};

export default LoadForecast;
