import React from 'react';

import Breadcrumbs from 'components/Breadcrumbs';
import HeaderLayout from 'components/HeaderLayout';

import ServiceStatusTile from './ServiceStatusTile';
import './ServiceDashboard.scss';

const ServiceDashboard = () => (
  <HeaderLayout
    className="service-dashboard"
    title={
      <Breadcrumbs
        parents={[
          {
            to: '/admin',
            label: <h2 className="title">Admin Panel</h2>,
          },
        ]}
        separator="/"
        currentHeader="Service Status"
      />
    }
  >
    <ServiceStatusTile serviceName="Auth" servicePath="/auth" />
    <ServiceStatusTile
      serviceName="CIM Datastore"
      servicePath="/cim-datastore"
    />
    <ServiceStatusTile
      serviceName="DSP Datastore"
      servicePath="/dsp-datastore"
    />
    <ServiceStatusTile serviceName="Orchestrator" servicePath="" />
  </HeaderLayout>
);

export default ServiceDashboard;
