import { DateTime } from 'luxon';
import React, { useState, FunctionComponent } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
import LoadingSpinner from 'components/LoadingSpinner';

import './SystemLoadChart.scss';

interface SLFValue {
  created_at: string;
  iso_id: number;
  load: number;
  market_type: string;
  time: string;
}

interface Point {
  x: number;
  y: number;
}

const processForecast = (forecast: SLFValue[]) => {
  const dayAheadForecast: Point[] = forecast
    .filter((el) => {
      return el.market_type === 'DAYAHEAD';
    })
    .map(({ load, time }) => ({
      x: DateTime.fromISO(time).valueOf(),
      y: load * 1e-6,
    }));

  const sameDayForecast: Point[] = forecast
    .filter((el) => {
      return el.market_type === 'SAMEDAY';
    })
    .map(({ load, time }) => ({
      x: DateTime.fromISO(time).valueOf(),
      y: load * 1e-6,
    }));

  return { dayAheadForecast, sameDayForecast };
};

type SystemLoadChartProps = {
  start_time: DateTime;
  end_time: DateTime;
  isoName: string;
  timezone: string;
};

const SystemLoadChart: FunctionComponent<SystemLoadChartProps> = ({
  start_time,
  end_time,
  isoName,
  timezone,
}) => {
  const [dayAheadSystemLoad, setDayAheadSystemLoad] = useState<Point[]>([]);
  const [sameDaySystemLoad, setSameDaySystemLoad] = useState<Point[]>([]);

  const { loading } = useRequestEffect<SLFValue[]>({
    url: `/api/dsp/iso/${isoName}/system_load_forecast`,
    method: 'get',
    refetchOnChange: [start_time, end_time, isoName],
    onSuccess: (data) => {
      if (data) {
        const { dayAheadForecast, sameDayForecast } = processForecast(data);
        setDayAheadSystemLoad(dayAheadForecast);
        setSameDaySystemLoad(sameDayForecast);
      }
    },

    params: {
      start_time: start_time.toISO(),
      end_time: end_time.endOf('day').toISO(),
    },
  });

  if (loading) {
    return (
      <div className="single-chart">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="single-chart">
      <ChartWrapper
        config={{
          type: 'line',
          data: {
            datasets: [
              {
                data: dayAheadSystemLoad,
                label: 'Day Ahead',
                backgroundColor: '#00467F',
                borderColor: '#00467F',
                fill: false,
                stepped: 'before',
              },
              {
                data: sameDaySystemLoad,
                label: 'Same Day',
                backgroundColor: '#7f0046',
                borderColor: '#7f0046',
                fill: false,
                stepped: 'before',
              },
            ],
            labels: [],
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                intersect: false,
                mode: 'nearest',
                axis: 'x',
                callbacks: {
                  title: (tooltipItems) => {
                    let title = '';
                    if (tooltipItems.length > 0) {
                      const item = tooltipItems[0];
                      const dateTime = DateTime.fromMillis(item.parsed.x, {
                        zone: timezone,
                      });
                      title = dateTime.toFormat('DDD hh:mm:ss a ZZ');
                    }

                    return title;
                  },
                  label: (tooltipItem) => {
                    const { parsed, label } = tooltipItem;
                    let value = parsed.y;
                    value = Math.round(value * 100) / 100; // Get 2 decimal places
                    return `${label}: ${value.toLocaleString()} MW`;
                  },
                },
              },
            },
            scales: {
              x: {
                adapters: {
                  date: {
                    zone: timezone,
                  },
                },
                min: start_time.startOf('day').valueOf(),
                max: end_time.endOf('day').valueOf(),
                type: 'time',
                offset: true,
                title: {
                  display: true,
                  text: `Time (${timezone})`,
                },
                ticks: {
                  major: {
                    enabled: true,
                  },
                  source: 'auto',
                  autoSkip: true,
                  autoSkipPadding: 75,
                  maxRotation: 0,
                  sampleSize: 100,
                },
              },
              y: {
                min: 0,
                suggestedMax: 1, // Ensure that we at last show to 1MW
                grid: {
                  drawBorder: false,
                },
                title: {
                  display: true,
                  text: 'System Load (MW)',
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default SystemLoadChart;
