import { GeoJsonLayer } from 'deck.gl';
import {
  DlmpNodalPrice,
  OverlayData,
  OverlayFeature,
  OverlayFeatures,
} from 'types/map';

const sumPositives = (...values: number[]) => {
  return values.reduce((acc, value) => acc + Math.max(0, value), 0);
};

const getDLMPLayers = (
  polygonLayerAssets: OverlayFeatures,
  scaling: number,
  overlayData: OverlayData<DlmpNodalPrice>,
  onHover: (obj: Record<string, unknown>, x: number, y: number) => void
) => {
  return [
    new GeoJsonLayer<OverlayFeature>({
      id: 'generationGeojson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) =>
        scaling *
        sumPositives(
          overlayData[n.properties.id]['generation'],
          overlayData[n.properties.id]['energy'],
          overlayData[n.properties.id]['loss'],
          overlayData[n.properties.id]['congestion']
        ),
      getFillColor: (d) => [110, 110, 255],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
    new GeoJsonLayer<OverlayFeature>({
      id: 'congestionGeojson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) =>
        scaling *
        sumPositives(
          overlayData[n.properties.id]['energy'],
          overlayData[n.properties.id]['loss'],
          overlayData[n.properties.id]['congestion']
        ),
      getFillColor: (d) => [253, 220, 55],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
    new GeoJsonLayer<OverlayFeature>({
      id: 'lossGeojson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) =>
        scaling *
        sumPositives(
          overlayData[n.properties.id]['energy'],
          overlayData[n.properties.id]['loss']
        ),
      getFillColor: (d) => [255, 110, 110],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
    new GeoJsonLayer<OverlayFeature>({
      id: 'energyGeojson',
      // @ts-ignore The GeoJSON layer data type is incorrect
      data: polygonLayerAssets,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      pickable: true,
      getElevation: (n) => scaling * overlayData[n.properties.id]['energy'],
      getFillColor: (d) => [122, 213, 20],
      onHover: (info: any) => onHover(info.object, info.x, info.y),
    }),
  ];
};
export default getDLMPLayers;
