import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';

import Checkbox from 'components/Checkbox';
import SearchInput from 'components/SearchInput';
import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';

import { useEnrollmentAPI } from '../api';

import './Enrollment.scss';

type EnrollmentTableRow = {
  containerID: string;
  isContainer: boolean;
  enrolled: boolean;
  id: string;
  name: string;
  type: string;
};

export default function ContainerEnrollment() {
  const {
    programID,
    containers,
    loading,
    updating,
    toggleContainerEnrollment,
    toggleDEREnrollment,
  } = useEnrollmentAPI();

  // To avoid a lot of sub-table problems (and making filtering complex)
  // we'll keep track of all the visible rows in the table as needed
  const [rows, setRows] = useState<EnrollmentTableRow[]>([]);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    // If the containers change, recreate our table rows
    const newRows: EnrollmentTableRow[] = [];

    containers.forEach((c) => {
      newRows.push({
        containerID: c.id,
        enrolled: c.enrolled,
        id: c.id,
        isContainer: true,
        name: c.name,
        type: c.type.charAt(0).toUpperCase() + c.type.substr(1).toLowerCase(),
      });

      if (c.enrolled && expandedRows[c.id] && c.children) {
        // This is an expanded row. Include the DERs
        c.children.forEach((der) => {
          newRows.push({
            containerID: c.id,
            enrolled: der.enrolled,
            id: der.der_rdf_id,
            isContainer: false,
            name: der.name,
            type: der.der_type,
          });
        });
      }
    });

    setRows(newRows);
  }, [containers, expandedRows]);

  const expandContainer = (id: string) => {
    setExpandedRows({
      ...expandedRows,
      [id]: !expandedRows[id],
    });
  };

  return (
    <HeaderLayout className="enrollment-container" title="Program Enrollment">
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="table-container">
          <ReactTable
            data={rows}
            columns={[
              {
                expander: true,
                width: 65,
                Expander: ({
                  original: { isContainer, enrolled, id, type },
                }: any) => {
                  const isExpanded = !!expandedRows[id];
                  return enrolled && isContainer ? (
                    <div
                      className={classNames(
                        'rt-expander',
                        isExpanded && '-open'
                      )}
                      onClick={() => expandContainer(id)}
                    >
                      &bull;
                    </div>
                  ) : null;
                },
                style: {
                  fontSize: 25,
                  padding: '0',
                  textAlign: 'center',
                  userSelect: 'none',
                },
              },
              {
                Header: 'Name',
                accessor: 'name',
                filterable: true,
                filterMethod: (filter: any, row: any) =>
                  row[filter.id]
                    .toLowerCase()
                    .includes(filter.value.toLowerCase()),
                Filter: (cellInfo) => (
                  <SearchInput
                    onChange={(e) => cellInfo.onChange(e.target.value)}
                    placeholder="Name"
                  />
                ),
              },
              {
                Header: 'Type',
                accessor: 'type',
                filterable: true,
                filterMethod: (filter: any, row: any) =>
                  row[filter.id]
                    .toLowerCase()
                    .includes(filter.value.toLowerCase()),
                Filter: (cellInfo) => (
                  <SearchInput
                    onChange={(e) => cellInfo.onChange(e.target.value)}
                    placeholder="Type"
                  />
                ),
              },
              {
                Header: 'Enrolled',
                id: 'enrolled',
                Cell: ({
                  original: { containerID, enrolled, id, isContainer },
                }: any) => (
                  <Checkbox
                    checked={enrolled}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      if (isContainer) {
                        toggleContainerEnrollment(event, id);
                      } else {
                        toggleDEREnrollment(event, containerID, id);
                      }
                    }}
                  ></Checkbox>
                ),
                width: 100,
              },
              {
                Cell: ({
                  original: { containerID, enrolled, id, isContainer },
                }: any) => {
                  if (!enrolled) {
                    return (
                      <a href="#/" className="disabled-link">
                        Settings
                      </a>
                    );
                  }

                  const link = isContainer
                    ? `/program/${programID}/enrollment/feeder/${id}`
                    : `/program/${programID}/enrollment/asset/${containerID}/${id}`;
                  return <Link to={link}>Settings</Link>;
                },
                width: 100,
              },
            ]}
            pageSize={rows.length || 3}
            showPagination={false}
            loading={updating}
          />
        </div>
      )}
    </HeaderLayout>
  );
}
