import React, { useState, Fragment } from 'react';

import { Link, useHistory } from 'react-router-dom';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import {
  apm,
  Request,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Checkbox from 'components/Checkbox';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
import SearchInput from 'components/SearchInput';
import fileExportSave from 'helpers/downloadFile';

import { Participant } from 'types/participant';
import './Participants.scss';

const Participants = () => {
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const history = useHistory();

  const {
    data: participants,
    loading,
    refetch: reloadParticipants,
  } = useRequestEffect<Participant[]>({
    url: '/api/dsp/participants',
    initialData: [],
    method: 'get',
    refetchOnChange: [],
    toast: {
      error: 'Failed to load participant list.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { loading: reportDownloading, makeRequest: runDownload } = useRequest(
    '/api/dsp/participants/report'
  );

  const downloadReport = async () => {
    await runDownload({
      method: 'get',
      onSuccess: (data: Blob, headers: Record<string, unknown>) => {
        fileExportSave(data, headers);
      },
      toast: {
        error: 'Failed to download participant report.',
        settings: {
          autoDismiss: true,
        },
      },

      // Request options
      // TODO: Remove the ignore once the useRequest hooks are converted to typescript
      // The TS compiler fails to understand that these properties are passed to ...options
      // which is of type AxiosRequestConfig. Once the hook is in typescript, it should know
      // this. The type is defined at https://github.com/axios/axios/blob/351cf290f0478d6e47e74c6da2f3ad8fe8f29887/index.d.ts#L43
      // @ts-ignore
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const handleDelete = async (participantID: number) => {
    setDeleting(true);

    const request = new Request(`/api/dsp/participants/${participantID}`);

    try {
      await request.delete();
      reloadParticipants();
    } catch (error) {
      apm.captureError(error);
    }

    setDeleting(false);
  };

  const updateParticipant = async (
    participantID: number,
    key: string,
    value: any
  ) => {
    setUpdating(true);
    const request = new Request(`/api/dsp/participants/${participantID}`);

    try {
      await request.patch({
        [key]: value,
      });
      reloadParticipants();
    } catch (error) {
      apm.captureError(error);
    }

    setUpdating(false);
  };

  const disabled = loading || reportDownloading || updating || deleting;

  return (
    <HeaderLayout
      className="participants"
      title="Market Participants"
      titleRightContent={
        <Fragment>
          <IconButton
            className="header-button"
            disabled={disabled}
            icon="add_circle"
            onClick={() => history.push('/participants/create')}
            tooltip="Create New Participant"
          />
          <IconButton
            className="header-button"
            disabled={disabled}
            icon="refresh"
            onClick={() => reloadParticipants()}
            tooltip="Refresh Participant List"
          />
          <IconButton
            className="header-button"
            disabled={disabled}
            icon="get_app"
            onClick={downloadReport}
            tooltip="Download Participant Report"
          />
        </Fragment>
      }
    >
      <div className="table-container">
        <ReactTable
          data={participants}
          loading={loading}
          columns={[
            {
              Header: 'Billing Account ID',
              accessor: 'billing_account_id',
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Account ID"
                />
              ),
              sortable: true,
            },
            {
              Header: 'Participant',
              accessor: 'name',
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Name"
                />
              ),
              sortable: true,
              Cell: (props) => (
                <Link to={`/participants/${props.original.id}`}>
                  {props.value}
                </Link>
              ),
            },
            {
              Header: 'Email',
              id: 'email',
              accessor: (d) => d.email.email,
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Email"
                />
              ),
              sortable: true,
            },
            {
              Header: 'Email Notification',
              id: 'email_notification',
              accessor: (d) => d.email.enabled,
              Cell: (props) => (
                <span className="center-container">
                  <Checkbox
                    checked={props.value}
                    disabled={updating}
                    onClick={() =>
                      updateParticipant(
                        props.original.id,
                        'enabled',
                        !props.original.email.enabled
                      )
                    }
                  />
                </span>
              ),
              width: 150,
              resizable: false,
            },
            {
              Header: 'Event Confirmation',
              accessor: 'auto_confirm_enabled',
              Cell: (props) => (
                <span className="center-container">
                  <Checkbox
                    checked={props.value}
                    disabled={updating}
                    onClick={() =>
                      updateParticipant(
                        props.original.id,
                        'auto_confirm_enabled',
                        !props.original.auto_confirm_enabled
                      )
                    }
                  />
                </span>
              ),
              width: 150,
              resizable: false,
            },
            {
              Header: 'Number of Assets',
              id: 'totalPrice',
              accessor: (d) => d.ders.length,
              Cell: (props) => (
                <span>
                  {`${props.value ? props.value : 'No'} asset${
                    props.value !== 1 ? 's' : ''
                  }`}
                </span>
              ),
            },
            {
              id: 'delete',
              Cell: (props) => (
                <span className="center-container">
                  <IconButton
                    disabled={disabled}
                    icon="delete"
                    onClick={() => handleDelete(props.original.id)}
                    tooltip="Delete Participant"
                  />
                </span>
              ),
              width: 60,
              resizable: false,
            },
          ]}
          className="-striped -highlight"
          showPaginationBottom
        />
      </div>
    </HeaderLayout>
  );
};

export default Participants;
