import React, { FunctionComponent, ChangeEvent } from 'react';

import './SearchInput.scss';

interface SearchInputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value?: string;
}

// Create an input with search icon
const SearchInput: FunctionComponent<SearchInputProps> = ({
  onChange,
  placeholder,
  value,
}) => (
  <div className="search-container">
    <span className="search-input-wrapper">
      <input
        className="search-input"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </span>
  </div>
);

export default SearchInput;
