// eslint-disable-next-line no-use-before-define
import React, { useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { apm, Request } from '@opusonesolutions/gridos-app-framework';

import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import TextInput from 'components/TextInput';
import Checkbox from 'components/Checkbox';

import './AddParticipant.scss';
import Button from 'components/Button';

const Participant = () => {
  const [participant, setParticipant] = useState({
    company: {
      address: {},
    },
    invoicee: {
      address: {},
    },
  });
  const [edited, setEdited] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessages, setSaveMessages] = useState({});
  const [saved, setSaved] = useState(false);
  const [copyCustomerAddress, setcopyCustomerAddress] = useState(false);

  const updateKey = (key, value) => {
    setEdited(true);
    setParticipant({
      ...participant,
      [key]: value,
    });
  };

  const updateNestedKey = (topKey, key, value) => {
    setEdited(true);
    setParticipant({
      ...participant,
      [topKey]: {
        ...participant[topKey],
        [key]: value,
      },
    });
  };

  const copy = (buttonIsChecked) => {
    if (buttonIsChecked) {
      participant.invoicee.name = participant.name;
      participant.invoicee.phone_number = participant.company.phone_number;
      participant.invoicee.address = participant.company.address;
    } else {
      participant.invoicee.name = '';
      participant.invoicee.phone_number = '';
      participant.invoicee.address = {};
    }
  };

  const updateAddress = (type, key, value) => {
    setEdited(true);
    setParticipant({
      ...participant,
      [type]: {
        ...participant[type],
        address: {
          ...participant[type].address,
          [key]: value,
        },
      },
    });
  };

  const addressValid = (address) =>
    address.line_1 &&
    address.locality &&
    address.administrative_area &&
    address.country;

  const valid =
    participant.name &&
    participant.email &&
    participant.billing_account_id &&
    participant.company.phone_number &&
    addressValid(participant.company.address) &&
    participant.invoicee.name &&
    participant.invoicee.phone_number &&
    addressValid(participant.invoicee.address);

  const createParticipant = async () => {
    const request = new Request('/api/dsp/participants');
    setSaving(true);

    try {
      await request.post(participant);
      setSaveMessages({});
      setSaved(true);
    } catch (error) {
      const { response } = error;

      if (response.status === 400) {
        setSaveMessages(response.data.message);
      } else if (response.status === 422) {
        // Happens when the billing account ID already exists
        setSaveMessages({
          billing_account_id: response.data.message,
        });
      }
      apm.captureError(error);
    }

    setSaving(false);
  };

  return (
    <HeaderLayout
      className="add-participant"
      title="New Market Participant"
      titleRightContent={
        <Button
          disabled={!edited || saving || !valid}
          onClick={() => createParticipant()}
        >
          Save
        </Button>
      }
    >
      <Fragment>
        {saved && <Redirect to="/participants" />}
        <div className="column-container">
          <div className="column">
            <div className="flex-column-header-wrapper">
              <h3 className="column-header">Company</h3>
              <div>
                <Checkbox
                  disabled={saving}
                  label="is DSO"
                  checked={participant.is_dso || false}
                  onClick={(event) =>
                    updateKey('is_dso', event.currentTarget.checked)
                  }
                />
                is DSO
              </div>
            </div>
            <TextInput
              disabled={saving}
              id="name"
              label="Company Name"
              value={participant.name || ''}
              placeholder="Company Name"
              onChange={(value) => updateKey('name', value)}
              required
              invalid={!!saveMessages.name}
              validationMessage={
                saveMessages.name ? saveMessages.name[0] : null
              }
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="email"
              label="Email"
              value={participant.email || ''}
              placeholder="Email"
              onChange={(value) => updateKey('email', value)}
              invalid={!!saveMessages.email}
              validationMessage={
                saveMessages.email ? saveMessages.email[0] : null
              }
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="billing_account_id"
              label="Account ID"
              value={participant.billing_account_id || ''}
              placeholder="Account ID"
              onChange={(value) => updateKey('billing_account_id', value)}
              invalid={!!saveMessages.billing_account_id}
              validationMessage={saveMessages.billing_account_id}
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="external_account_id"
              label="External Account ID"
              value={participant.external_account_id || ''}
              placeholder="External Account ID"
              onChange={(value) => updateKey('external_account_id', value)}
              invalid={!!saveMessages.external_account_id}
              validationMessage={saveMessages.external_account_id}
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="phone_number"
              label="Phone Number"
              value={participant.company.phone_number || ''}
              placeholder="Phone Number"
              onChange={(value) =>
                updateNestedKey('company', 'phone_number', value)
              }
              required
              resetEditState={saving}
              invalid={!!saveMessages.name}
              validationMessage={
                saveMessages.name ? saveMessages.name[0] : null
              }
            />
            <TextInput
              disabled={saving}
              id="line_1"
              label="Street Address"
              value={participant.company.address.line_1 || ''}
              placeholder="Street Address"
              onChange={(value) => updateAddress('company', 'line_1', value)}
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="line_2"
              label=""
              value={participant.company.address.line_2 || ''}
              placeholder="Suite, unit, building, floor, etc."
              onChange={(value) => updateAddress('company', 'line_2', value)}
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="locality"
              label="City"
              value={participant.company.address.locality || ''}
              placeholder="City"
              onChange={(value) => updateAddress('company', 'locality', value)}
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="administrative_area"
              label="State / Province"
              value={participant.company.address.administrative_area || ''}
              placeholder="State / Province"
              onChange={(value) =>
                updateAddress('company', 'administrative_area', value)
              }
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="postal_code"
              label="Zip / Postal Code"
              value={participant.company.address.postal_code || ''}
              placeholder="Zip / Postal Code"
              onChange={(value) =>
                updateAddress('company', 'postal_code', value)
              }
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="country"
              label="Country"
              value={participant.company.address.country || ''}
              placeholder="Country"
              onChange={(value) => updateAddress('company', 'country', value)}
              required
              resetEditState={saving}
            />
          </div>

          <div className="column">
            <div className="flex-column-header-wrapper">
              <h3 className="column-header">Invoicee</h3>
              <div>
                <Checkbox
                  disabled={saving}
                  label="Copy Billing"
                  checked={copyCustomerAddress}
                  onClick={(event) =>
                    setcopyCustomerAddress(
                      event.currentTarget.checked,
                      copy(event.currentTarget.checked)
                    )
                  }
                />
                Copy billing info
              </div>
            </div>
            <TextInput
              disabled={saving}
              id="name"
              label="Name"
              value={participant.invoicee.name || ''}
              placeholder="Company Name"
              onChange={(value) => updateNestedKey('invoicee', 'name', value)}
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="phone_number"
              label="Phone Number"
              value={participant.invoicee.phone_number || ''}
              placeholder="Phone Number"
              onChange={(value) =>
                updateNestedKey('invoicee', 'phone_number', value)
              }
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="line_1"
              label="Street Address"
              value={participant.invoicee.address.line_1 || ''}
              placeholder="Street Address"
              onChange={(value) => updateAddress('invoicee', 'line_1', value)}
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="line_2"
              label=""
              value={participant.invoicee.address.line_2 || ''}
              placeholder="Suite, unit, building, floor, etc."
              onChange={(value) => updateAddress('invoicee', 'line_2', value)}
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="locality"
              label="City"
              value={participant.invoicee.address.locality || ''}
              placeholder="City"
              onChange={(value) => updateAddress('invoicee', 'locality', value)}
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="administrative_area"
              label="State / Province"
              value={participant.invoicee.address.administrative_area || ''}
              placeholder="State / Province"
              onChange={(value) =>
                updateAddress('invoicee', 'administrative_area', value)
              }
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="postal_code"
              label="Zip / Postal Code"
              value={participant.invoicee.address.postal_code || ''}
              placeholder="Zip / Postal Code"
              onChange={(value) =>
                updateAddress('invoicee', 'postal_code', value)
              }
              required
              resetEditState={saving}
            />
            <TextInput
              disabled={saving}
              id="country"
              label="Country"
              value={participant.invoicee.address.country || ''}
              placeholder="Country"
              onChange={(value) => updateAddress('invoicee', 'country', value)}
              required
              resetEditState={saving}
            />
          </div>
        </div>
        {saving && (
          <div className="spinner-container">
            <LoadingSpinner />
          </div>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default Participant;
