import React, { useContext, Fragment, FunctionComponent } from 'react';
import { DateTime } from 'luxon';
import { useParams, useHistory } from 'react-router-dom';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import HeaderLayout from 'components/HeaderLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import NumberInput from 'components/NumberInput';
import SearchInput from 'components/SearchInput';
import Tabs from 'components/Tabs';
import TextInput from 'components/TextInput';
import { ProgramsContext } from 'contexts/ProgramsContext';

import { MeasurementMapping } from 'routes/Measurements/routes/Mapping/MappingTypes';
import {
  PricingEvent,
  PricingEventState,
} from 'routes/Program/routes/OperationalView/pricingEvents';

import './SingleEvent.scss';
import { TagValue } from 'contexts/MeasurementContext';

type PricingEventStateResponse = {
  changed_by: string;
  reason: string;
  state_type: PricingEventState;
  time: string;
};

interface SinglePricingEvent extends PricingEvent {
  der: {
    name: string;
  };
}

const SingleEvent: FunctionComponent = () => {
  const { programID, eventID } = useParams<{
    programID: string;
    eventID: string;
  }>();
  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);

  const history = useHistory();

  const {
    data: event,
    loading: eventLoading,
  }: { data?: SinglePricingEvent; loading: boolean } = useRequestEffect({
    url: `/api/dsp/program/${programID}/pricing_event/${eventID}`,
    method: 'get',
    refetchOnChange: [programID, eventID],
    onError: (response) => {
      if (response.code === '404') {
        // No event found, ID is bad so redirect to the list of events
        history.push(`/program/${programID}/events`);
      }
    },
    toast: {
      error: (response) => {
        if (response.code === '404') {
          return 'Could not find pricing event';
        }
        return 'Failed to get pricing event';
      },
    },
  });

  const {
    data: states,
    loading: statesLoading,
  }: {
    data?: PricingEventStateResponse[];
    loading: boolean;
  } = useRequestEffect({
    url: `/api/dsp/program/${programID}/pricing_event/${eventID}/states`,
    method: 'get',
    refetchOnChange: [programID, eventID],
  });

  const { data: measurementMappings } = useRequestEffect<MeasurementMapping[]>({
    url: `/api/dsp/program/${programID}/measurements/mappings/id/${event?.der_rdf_id}`,
    method: 'get',
    blockRequest: () => !event,
    dataTransform: (data) => {
      // Filter to REAL_POWER measurements since those are used for settlement
      return (data || []).filter(
        (mapping: MeasurementMapping) =>
          mapping.measurement_type === 'REAL_POWER'
      );
    },
  });

  const endTimeParam = event
    ? DateTime.fromISO(event.start_time)
        .plus({ seconds: event.duration })
        .toISO()
    : DateTime.local().toISO();
  const measTimeseriesID =
    measurementMappings && measurementMappings.length
      ? measurementMappings[0].timeseries_id
      : '';
  const measTagID =
    measurementMappings && measurementMappings.length
      ? measurementMappings[0].tag_id
      : '';
  const { data: measurementData } = useRequestEffect<TagValue[]>({
    url: `/api/dsp/measurements/timeseries/${measTimeseriesID}/tag/${measTagID}/by_time`,
    method: 'get',
    params: {
      start_time: event?.start_time,
      end_time: endTimeParam,
    },
    blockRequest: () =>
      !measurementMappings || measurementMappings.length === 0 || !event,
  });

  let endTime = '';

  if (event) {
    const duration = event.duration;
    endTime = DateTime.fromISO(event.start_time)
      // Subtract one to show a nice end time like 01:59:59 instead of 02:00:00
      .plus({ seconds: duration - 1 })
      .setZone(program?.timezone || 'UTC')
      .toFormat('DDD HH:mm:ssZZ');
  }

  const loading = eventLoading || statesLoading;

  return (
    <HeaderLayout
      className="single-event"
      title={
        <Breadcrumbs
          parents={[
            {
              to: `/program/${programID}/events`,
              label: <h2 className="title">Pricing Event</h2>,
            },
          ]}
          separator="/"
          currentHeader={`Event ID: ${eventID}`}
        />
      }
    >
      <Fragment>
        {loading && <LoadingSpinner />}
        {!loading && event && states && (
          <Tabs
            tabs={[
              { name: 'Event Details', disabled: false },
              { name: 'History', disabled: false },
              {
                name: 'Measurements',
                disabled:
                  measurementMappings && measurementMappings.length === 0,
              },
            ]}
          >
            {(TabPanel) => [
              <TabPanel key="tab0">
                <div className="column">
                  <TextInput
                    id="start"
                    disabled
                    label="Event Start Time"
                    value={DateTime.fromISO(event.start_time)
                      .setZone(program?.timezone || 'UTC')
                      .toFormat('DDD HH:mm:ssZZ')}
                  />
                  <TextInput
                    id="end"
                    disabled
                    label="Event End Time"
                    value={endTime}
                  />
                  <TextInput
                    id="asset"
                    disabled
                    label="Asset"
                    value={event.der.name}
                  />
                  <NumberInput
                    id="real_power"
                    disabled
                    label="Power"
                    unit="kW"
                    value={parseFloat(event.power_required) / 1000}
                  />
                  <NumberInput
                    id="reactive_power"
                    disabled
                    label="Reactive Power"
                    unit="kVAr"
                    value={parseFloat(event.reactive_power_required) / 1000}
                  />
                  <NumberInput
                    id="price"
                    disabled
                    label="Price"
                    unit={`${program?.currency}/kWh`}
                    value={parseFloat(event.unit_price)}
                  />
                  <TextInput
                    id="settlment_status"
                    disabled
                    label="Settlement Status"
                    value={event.settlement_status}
                  />
                  <NumberInput
                    id="power_delivered"
                    disabled
                    label="Power Delivered"
                    unit="kW"
                    value={
                      event.power_delivered === 'None'
                        ? undefined
                        : parseFloat(event.power_delivered) / 1000
                    }
                  />
                  <NumberInput
                    id="settlement_value"
                    disabled
                    label="Settlement Value"
                    unit={program?.currency}
                    value={
                      event.settlement_value === 'None'
                        ? undefined
                        : parseFloat(event.settlement_value)
                    }
                  />
                </div>
              </TabPanel>,
              <TabPanel key="tab1">
                <ReactTable
                  // Reverse to put latest change at the top
                  data={states.reverse()}
                  loading={loading}
                  pageSize={10}
                  columns={[
                    {
                      Header: 'State',
                      accessor: 'state_type',
                      filterable: true,
                      filterMethod: (filter: any, row: any) => {
                        return row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase());
                      },
                      Filter: (cellInfo) => (
                        <SearchInput
                          onChange={(e) => cellInfo.onChange(e.target.value)}
                          placeholder="State Type"
                        />
                      ),
                    },
                    {
                      Header: 'Changed By',
                      accessor: 'changed_by',
                      filterable: true,
                      filterMethod: (filter: any, row: any) => {
                        return row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase());
                      },
                      Filter: (cellInfo) => (
                        <SearchInput
                          onChange={(e) => cellInfo.onChange(e.target.value)}
                          placeholder="User"
                        />
                      ),
                    },
                    {
                      Header: `Changed At (${program?.timezone})`,
                      accessor: (row) => DateTime.fromISO(row.time),
                      id: 'time',
                      Cell: (props) => (
                        <span>
                          {props.value
                            .setZone(program!.timezone)
                            .toFormat('ff')}
                        </span>
                      ),
                    },
                  ]}
                  className="-striped -highlight"
                  showPaginationBottom
                />
              </TabPanel>,
              <TabPanel key="tab2">
                <ReactTable
                  data={measurementData || []}
                  loading={loading}
                  pageSize={10}
                  columns={[
                    {
                      Header: `Time Sampled (${program?.timezone})`,
                      accessor: (row) => DateTime.fromISO(row.time_sampled),
                      id: 'time_sampled',
                      Cell: (props) => (
                        <span>
                          {props.value
                            .setZone(program!.timezone)
                            .toFormat('ff')}
                        </span>
                      ),
                    },
                    {
                      Header: 'Value',
                      accessor: 'value',
                    },
                  ]}
                  className="-striped -highlight"
                  showPaginationBottom
                />
              </TabPanel>,
            ]}
          </Tabs>
        )}
      </Fragment>
    </HeaderLayout>
  );
};

export default SingleEvent;
