import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { MeasurementsProvider } from 'contexts/MeasurementContext';

import Measurements from './Measurements';
import Feeds from './routes/Feeds';
import LoadForecast from './routes/LoadForecast';
import Mapping from './routes/Mapping';
import Timeseries from './routes/Timeseries';
import Weather from './routes/Weather';

const MeasurementsContainer = () => {
  return (
    <MeasurementsProvider>
      <Switch>
        <Route exact path="/measurements">
          <Measurements />
        </Route>
        <Route path="/measurements/feeds">
          <Feeds />
        </Route>
        <Route path="/measurements/forecasts">
          <LoadForecast />
        </Route>
        <Route path="/measurements/mapping">
          <Mapping />
        </Route>
        <Route path="/measurements/timeseries">
          <Timeseries />
        </Route>
        <Route path="/measurements/weather">
          <Weather />
        </Route>
      </Switch>
    </MeasurementsProvider>
  );
};

export default MeasurementsContainer;
