import { CimJSON, CimObject } from 'types/cim';
import { getMultiRef, getSingleRef } from './references';

/**
 * Determine if a transformer is a regulator or not.
 * Regulators are determined using the following logic:
 *  The transformer has a `RatioTapChanger`
 *  The `RatioTapChanger` references a `TapChangerControl`
 *  The `ratedU` on both sides of the transformer is the same
 * @param  {Object} transformer Instance of a transformer
 * @param  {Object} lookup      Lookup of all CIM instances
 * @return {Object}             Regulator and Tap Changer details if they exist.
 */
export function isRegulator(transformer: CimObject, cim: CimJSON): boolean {
  let regulator = false;
  const ratedVoltages = new Set();

  if (transformer.references['PowerTransformer.TransformerTanks']) {
    const tanks = getMultiRef(
      transformer,
      'PowerTransformer.TransformerTanks'
    ).map((ref) => cim.objects[ref]);
    const tankEnds = tanks.map((tank) =>
      getMultiRef(tank, 'TransformerTank.TransformerTankEnds').map(
        (ref) => cim.objects[ref]
      )
    );
    const tankInfos = tanks.map(
      (tank) =>
        cim.objects[getSingleRef(tank, 'PowerSystemResource.AssetDatasheet')]
    );

    tankEnds.forEach((end) => {
      const ratio = end.filter(
        (e) => e.references['TransformerEnd.RatioTapChanger']
      );

      if (ratio.length > 0) {
        const ratioInstance = ratio.map(
          (r) => cim.objects[getSingleRef(r, 'TransformerEnd.RatioTapChanger')]
        );
        const hasControl = ratioInstance.find(
          (r) => r.references['TapChanger.TapChangerControl']
        );

        if (hasControl) {
          regulator = !!transformer;
        }
      }
    });

    tankInfos.forEach((tankInfo) => {
      if (tankInfo) {
        const endInfos = getMultiRef(
          tankInfo,
          'TransformerTankInfo.TransformerEndInfos'
        ).map((ref) => cim.objects[ref]);
        endInfos.forEach((endInfo) => {
          if (endInfo && 'TransformerEndInfo.ratedU' in endInfo.attributes) {
            ratedVoltages.add(endInfo.attributes['TransformerEndInfo.ratedU']);
          }
        });
      }
    });
  }

  if (ratedVoltages.size > 1) {
    // Multiple voltage levels. This is a transformer even if there is an LTC
    regulator = false;
  }

  return regulator;
}
