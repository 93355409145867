import React, { useContext, FunctionComponent } from 'react';
import Select from 'react-select';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import { ProgramsContext } from 'contexts/ProgramsContext';

import SettingsMenu from './SettingsMenu';

import './Header.scss';

interface HeaderProps {
  onLoginPage: boolean;
  programId: number | null;
  setSelectedProgram: (id: number) => void;
}

const Header: FunctionComponent<HeaderProps> = ({
  onLoginPage,
  programId,
  setSelectedProgram,
}) => {
  const { programs } = useContext(ProgramsContext);
  const programOptions = programs.map(({ program_id, name }) => ({
    label: name,
    value: program_id,
  }));
  const { user } = useAuthContext();

  return (
    <div className="header">
      <div className="header-left">
        <img className="logo" alt="Logo" src="/logo.svg" />
        {!onLoginPage && (
          <div className="program-select">
            <Select
              key={`react-select__${programId}`} // Force a rerender of select component when ProgramID changes & goes to null
              isClearable
              isMulti={false}
              options={programOptions}
              onChange={(option) => {
                // @ts-ignore
                setSelectedProgram(option ? option.value : null);
              }}
              placeholder="Select Program"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 10,
                }),
              }}
              value={programOptions.find(
                ({ value }) => value === programId || null
              )}
            />
          </div>
        )}
      </div>
      <div className="right-container">
        {!onLoginPage && user.email && (
          <button className="username">
            <i className="material-icons">account_circle</i>
            <p className="username-name">{user.email}</p>
          </button>
        )}
        {!onLoginPage && <SettingsMenu />}
      </div>
    </div>
  );
};

export default Header;
