import check from 'check-types';
import React, { useContext, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import Checkbox from 'components/Checkbox';
import HeaderLayout from 'components/HeaderLayout';
import NumberInput from 'components/NumberInput';
import { ProgramsContext } from 'contexts/ProgramsContext';

import './ProgramAdminSettings.scss';
import Button from 'components/Button';

type Settings = {
  debug_data_retention_period: number;
  id: number;
  program_id: number;
  retain_debug_data_permanently: boolean;
};

type EditableSettings = {
  debug_data_retention_period?: number;
  id: number;
  program_id: number;
  retain_debug_data_permanently: boolean;
};

const ProgramAdminSettings = () => {
  const { programs } = useContext(ProgramsContext);
  const { programID } = useParams<{ programID: string }>();

  const [adminSettings, setAdminSettings] = useState<EditableSettings | null>(
    null
  );
  const [edited, setEdited] = useState(false);

  const { loading } = useRequestEffect<Settings>({
    url: `/api/dsp/program/${programID}/admin_settings`,
    method: 'get',
    refetchOnChange: [programID],
    blockRequest: () => programID === undefined,
    onSuccess: (data) => {
      if (data) {
        setAdminSettings(data);
      }
    },
    toast: {
      error: 'Could not load program admin settings.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { loading: saving, makeRequest: runSave } = useRequest(
    `/api/dsp/program/${programID}/admin_settings`
  );

  const program = programID
    ? programs.filter((p) => p.program_id === parseInt(programID, 10))[0]
    : undefined;
  const programName = program ? program.name : '';

  const saveSettings = async () => {
    await runSave({
      method: 'patch',
      body: {
        debug_data_retention_period: adminSettings?.debug_data_retention_period,
        retain_debug_data_permanently:
          adminSettings?.retain_debug_data_permanently,
      },
      onSuccess: (data: Settings) => {
        setAdminSettings(data);
        setEdited(false);
      },
      toast: {
        success: 'Successfully updated program admin settings.',
        error: 'Could not save program admin settings.',
        settings: {
          autoDismiss: true,
        },
      },
    });
  };

  let valid = true;

  if (adminSettings) {
    valid =
      adminSettings.retain_debug_data_permanently ||
      (check.number(adminSettings.debug_data_retention_period) &&
        check.positive(adminSettings.debug_data_retention_period));
  }

  return (
    <HeaderLayout
      className="program-admin-settings"
      title={
        <Breadcrumbs
          parents={[
            {
              to: '/admin',
              label: <h2 className="title">Admin Panel</h2>,
            },
            {
              to: '/admin/programs',
              label: <h2 className="title">Programs</h2>,
            },
          ]}
          separator="/"
          currentHeader={programName}
        />
      }
      titleRightContent={
        <Button
          disabled={loading || saving || !edited || !valid}
          onClick={() => saveSettings()}
        >
          Save
        </Button>
      }
    >
      {adminSettings == null ? null : (
        <Fragment>
          <div className="row">
            <Checkbox
              disabled={loading || saving}
              checked={adminSettings.retain_debug_data_permanently}
              onClick={() => {
                setAdminSettings({
                  ...adminSettings,
                  retain_debug_data_permanently: !adminSettings.retain_debug_data_permanently,
                });
                setEdited(true);
              }}
            />
            Retain Simulation Debug Data Indefinitely
          </div>
          <div className="row">
            <NumberInput
              disabled={
                loading || saving || adminSettings.retain_debug_data_permanently
              }
              id="workspace"
              label="Number of Days to Retain Simulation Debug Data"
              onChange={(value) => {
                setAdminSettings({
                  ...adminSettings,
                  debug_data_retention_period: value,
                });
                setEdited(true);
              }}
              min={1}
              required
              step="1"
              value={adminSettings.debug_data_retention_period}
            />
          </div>
        </Fragment>
      )}
    </HeaderLayout>
  );
};

export default ProgramAdminSettings;
