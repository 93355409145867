import React from 'react';

import './ForecastTooltip.scss';
import { Icon, Line, Node } from 'types/cim';

const round = (value: number, decimalPlaces = 3) =>
  Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

interface ForecastTooltipProps {
  hoveredObject: Line | Node | Icon;
  overlayData: { [key: string]: number };
  pointerX: number;
  pointerY: number;
}

const ForecastTooltip = ({
  hoveredObject,
  overlayData,
  pointerX,
  pointerY,
}: ForecastTooltipProps) => {
  if (
    !hoveredObject ||
    !hoveredObject.properties ||
    !hoveredObject.properties.id
  ) {
    return null;
  }

  const objectId = hoveredObject.properties.id;
  const forecast = overlayData[objectId] || 0;

  return (
    hoveredObject && (
      <div
        className="forecast-tooltip"
        style={{
          position: 'absolute',
          zIndex: 1,
          pointerEvents: 'none',
          left: pointerX,
          top: pointerY,
        }}
      >
        <div>
          <b>{`${hoveredObject.properties.name}`}</b>
        </div>
        <div className="dotted-line" />
        <div>
          <b>Forecast: &nbsp;</b>
          {`${round(forecast / 1000).toLocaleString()} kW`}
        </div>
      </div>
    )
  );
};

export default ForecastTooltip;
