export type PricingEventState =
  | 'Accepted'
  | 'Active'
  | 'Cancelled'
  | 'Completed'
  | 'Confirmed'
  | 'Countered'
  | 'Expired'
  | 'Incomplete'
  | 'Pending'
  | 'Rejected';

export type SettlementStatus =
  | 'Not Verified'
  | 'Verified Underdelivered'
  | 'Verified Delivered'
  | 'Verified Overdelivered'
  | 'Paid';

export interface PricingEvent {
  der_rdf_id: string;
  duration: number;
  financial_model_log: Record<string, unknown>;
  id: number;
  opf_completed: boolean;
  power_delivered: string;
  power_required: string;
  reactive_power_required: string;
  settlement_status: SettlementStatus;
  settlement_value: string;
  state: {
    state_type: PricingEventState;
  };
  start_time: string;
  unit_price: string;
}

type SingleDLMP = {
  energy: number;
  loss: number;
  congestion: number;
  lmp: number;
};

interface DlmpPricingEvent extends PricingEvent {
  financial_model_log: {
    dlmp_variables: {
      ABC: SingleDLMP;
      A?: SingleDLMP;
      B?: SingleDLMP;
      C?: SingleDLMP;
    };
  };
}

interface LmpdPricingEvent extends PricingEvent {
  financial_model_log: {
    lmpd_variables: {
      b1: number;
      b2: number;
      d3: number;
      e1: number;
      lbmp: number;
    };
  };
}

interface PayasbidPricingEvent extends PricingEvent {
  financial_model_log: {
    pay_as_bid_variables: {
      price: number;
      quantity: number;
      bidOfferType: string;
    };
  };
}

interface PayasclearPricingEvent extends PricingEvent {
  financial_model_log: {
    pay_as_clear_variables: {
      price: number;
      quantity: number;
      bidOfferType: string;
    };
  };
}

export interface PricingEventsResponse<T extends PricingEvent> {
  event_type: string;
  events: T[];
}

const areDlmpPricingEvents = (
  data: PricingEventsResponse<PricingEvent>
): data is PricingEventsResponse<DlmpPricingEvent> => {
  if (data.event_type === 'DLMP') {
    return true;
  }
  return false;
};

const areLmpdPricingEvents = (
  data: PricingEventsResponse<PricingEvent>
): data is PricingEventsResponse<LmpdPricingEvent> => {
  if (data.event_type === 'LMPD') {
    return true;
  }
  return false;
};

const arePayasbidPricingEvents = (
  data: PricingEventsResponse<PricingEvent>
): data is PricingEventsResponse<PayasbidPricingEvent> => {
  if (data.event_type === 'PAY_AS_BID') {
    return true;
  }
  return false;
};

const arePayasclearPricingEvents = (
  data: PricingEventsResponse<PricingEvent>
): data is PricingEventsResponse<PayasclearPricingEvent> => {
  if (data.event_type === 'PAY_AS_CLEAR') {
    return true;
  }
  return false;
};

const dlmpReducer = (
  acc: { [key: string]: Record<string, unknown> },
  event: DlmpPricingEvent
) => {
  const power = parseFloat(event.power_required);
  const { congestion, energy, lmp, loss } =
    (event.financial_model_log.dlmp_variables || {}).ABC || {};
  const { der_rdf_id } = event;
  const dlmp = energy + loss + congestion;

  if (event.opf_completed) {
    acc[der_rdf_id] = {
      congestion,
      dlmp,
      energy,
      lmp,
      loss,
      power,
    };
  }

  return acc;
};

const lmpdReducer = (
  acc: { [key: string]: Record<string, unknown> },
  event: LmpdPricingEvent
) => {
  const power = parseFloat(event.power_required);
  const { b1, b2, d3, e1, lbmp } =
    event.financial_model_log.lmpd_variables || {};
  const { der_rdf_id } = event;
  const d = b1 + b2 + d3 + e1;

  if (event.opf_completed) {
    acc[der_rdf_id] = {
      d,
      lmp: lbmp,
      lmpd: lbmp + d,
      power,
    };
  }

  return acc;
};

const payAsBidReducer = (
  acc: { [key: string]: Record<string, unknown> },
  event: PayasbidPricingEvent
) => {
  const power = parseFloat(event.power_required);
  const unit_price = parseFloat(event.unit_price);
  const { der_rdf_id } = event;

  if (event.opf_completed) {
    acc[der_rdf_id] = {
      price: unit_price,
      power: power,
    };
  }

  return acc;
};

const payAsClearReducer = (
  acc: { [key: string]: Record<string, unknown> },
  event: PayasclearPricingEvent
) => {
  const power = parseFloat(event.power_required);
  const unit_price = parseFloat(event.unit_price);
  const { der_rdf_id } = event;

  if (event.opf_completed) {
    acc[der_rdf_id] = {
      price: unit_price,
      power: power,
    };
  }

  return acc;
};

export const processPricingEvents = (
  data: PricingEventsResponse<PricingEvent>
) => {
  if (areDlmpPricingEvents(data)) {
    return data.events.reduce(dlmpReducer, {});
  }

  if (areLmpdPricingEvents(data)) {
    return data.events.reduce(lmpdReducer, {});
  }

  if (arePayasbidPricingEvents(data)) {
    return data.events.reduce(payAsBidReducer, {});
  }

  if (arePayasclearPricingEvents(data)) {
    return data.events.reduce(payAsClearReducer, {});
  }

  return {};
};
