import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Admin from './Admin';
import ProgramAdmin from './routes/ProgramAdmin';
import ManualEvents from './routes/ManualEvents';
import ServiceDashboard from './routes/ServiceDashboard';
import SimulationDebug from './routes/SimulationDebug';

const AdminContainer = () => {
  return (
    <Switch>
      <Route exact path="/admin">
        <Admin />
      </Route>
      <Route path="/admin/health-check">
        <ServiceDashboard />
      </Route>
      <Route path="/admin/programs">
        <ProgramAdmin />
      </Route>
      <Route path="/admin/triggers">
        <ManualEvents />
      </Route>
      <Route exact path="/admin/simulations">
        <SimulationDebug />
      </Route>
    </Switch>
  );
};

export default AdminContainer;
