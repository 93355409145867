import React, { useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { useLocation, useParams, Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import {
  apm,
  Request,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';

import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import HeaderLayout from 'components/HeaderLayout';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import SearchInput from 'components/SearchInput';
import { ProgramsContext } from 'contexts/ProgramsContext';

import fileExportSave from 'helpers/downloadFile';

import useLocaleFormatter from 'hooks/useLocaleFormatter';
import useQueryState, {
  getDateFromParam,
  serializeDate,
} from 'hooks/useQueryState';

import './Events.scss';
import {
  PricingEvent,
  PricingEventState,
  SettlementStatus,
} from '../OperationalView/pricingEvents';
import { boolean } from 'check-types';

const STATUS_TO_COLOR: { [key in PricingEventState]?: string } = {
  Accepted: '#78A22F',
  Active: '#78A22F',
  Confirmed: '#009DDC',
  Cancelled: '#D0021B',
  Countered: '#FF7F00',
};

const MARKETS = [
  { label: 'Same Day', value: 'SAMEDAY' },
  { label: 'Day Ahead', value: 'DAYAHEAD' },
];

// These statuses cannot be cancelled by the DSP
const NON_CANCELLABLE = ['Cancelled', 'Completed', 'Expired'];

type ParsedEvent = {
  id: number;
  der_rdf_id: string;
  start: DateTime;
  end: DateTime;
  der: string;
  opf_failed: boolean;
  real_power: number;
  reactive_power: number;
  price: number;
  settlement_value: number | null;
  settlement_status: SettlementStatus;
  status: PricingEventState;
  duration: number;
};

type DER = {
  rdf_id: string;
  info?: {
    name: string;
  };
};

type DERMap = {
  [key: string]: DER;
};

type Feeder = {
  id: string;
  name: string;
  enrolled: boolean;
};

interface SelectOption {
  isDisabled?: boolean;
  label: string;
  value: any;
}

const Events = () => {
  const { programID } = useParams<{ programID: string }>();
  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);

  const [updating, setUpdating] = useState(false);
  const [reportDownloading, setReportDownloading] = useState(false);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const zone = program ? program.timezone : 'UTC';

  const [date, setDate] = useQueryState(
    getDateFromParam(
      params,
      'date',
      DateTime.local().setZone(zone).startOf('day')
    ),
    'date',
    serializeDate
  );
  const [market, setMarket] = useQueryState(
    params.get('market') || 'SAMEDAY',
    'market'
  );

  const initialFeederOptions: SelectOption[] = [];
  const [feederId, setFeederId] = useState('');
  const [feederOptions, setFeederOptions] = useState(initialFeederOptions);

  // When the program changes we need to adjust the timezone
  useEffect(() => {
    if (program) {
      setDate((d) => d.setZone(program.timezone));
    }
  }, [program, setDate]);

  const { data: ders } = useRequestEffect<DERMap>({
    url: `/api/dsp/program/${programID}/der`,
    method: 'get',
    dataTransform: (data: DER[]) => {
      const derByRdfID: DERMap = {};
      data.forEach((der) => (derByRdfID[der.rdf_id] = der));
      return derByRdfID;
    },
  });

  useRequestEffect<{ feeders: Feeder[] }>({
    url: `/api/dsp/program/${programID}/feeder`,
    method: 'get',
    initialData: { feeders: [] },
    refetchOnChange: [programID],
    onSuccess: (data) => {
      if (data) {
        setFeederId(data.feeders[0].id);
        setFeederOptions(
          data.feeders
            .filter((feeder) => feeder.enrolled)
            .map(({ id, name }) => ({
              label: name,
              value: id,
            }))
        );
      }
    },
    toast: {
      error: 'Could not load feeder list.',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const {
    data: events,
    loading,
    refetch: loadPricingEvents,
  }: {
    data: ParsedEvent[];
    loading: boolean;
    refetch: () => void;
  } = useRequestEffect({
    url: `/api/dsp/program/${programID}/pricing_event`,
    method: 'get',
    initialData: [],
    refetchOnChange: [programID, market, date, feederId],
    blockRequest: () => !feederId,
    params: {
      from_start_time: date.setZone(zone).toISO(),
      to_start_time: date.setZone(zone).endOf('day').toISO(),
      market_types: market,
      feeder_id: feederId,
    },
    dataTransform: (data) => {
      data.reverse(); // UI wants data earliest event first
      const eventData = data.map((pe: PricingEvent) => {
        const start = DateTime.fromISO(pe.start_time);
        // Subtract 1 to show end time as 01:59:59 instead of 02:00:00
        const end = start.plus({ seconds: pe.duration - 1 });

        const settlement_value =
          pe.settlement_value === 'None'
            ? null
            : parseFloat(pe.settlement_value);

        return {
          id: pe.id,
          der_rdf_id: pe.der_rdf_id,
          start,
          end,
          real_power: parseFloat(pe.power_required),
          reactive_power: parseFloat(pe.reactive_power_required),
          price: parseFloat(pe.unit_price),
          status: pe.state.state_type,
          opf_failed: !pe.opf_completed,
          settlement_status: pe.settlement_status,
          settlement_value: settlement_value,
          duration: pe.duration,
        };
      });
      return eventData;
    },
    toast: {
      error: 'Failed to load pricing events',
    },
  });

  const downloadReport = async () => {
    setReportDownloading(true);
    const request = new Request(
      `/api/dsp/program/${programID}/price_event/report`
    );
    const options = {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
      params: {
        from_start_time: date.toISO(),
        to_start_time: date.endOf('day').toISO(),
        market_types: [market],
      },
    };

    try {
      // @ts-ignore because typescript cannot resolve the response type correctly
      const { data, headers } = await request.get(options);
      fileExportSave(data, headers);
    } catch (error) {
      apm.captureError(error);
    }

    setReportDownloading(false);
  };

  const updateEvent = async (
    pricingEventID: string,
    state_type: string,
    reason: string
  ) => {
    setUpdating(true);
    const request = new Request(
      `/api/dsp/program/${programID}/pricing_event/${pricingEventID}/state`
    );

    try {
      await request.post({
        state_type,
        reason,
      });
      // Request succeeded, trigger reload of data
      loadPricingEvents();
    } catch (error) {
      apm.captureError(error);
    }

    setUpdating(false);
  };

  const { numberFormatter } = useLocaleFormatter(
    program?.currency,
    program?.locale
  );

  return (
    <HeaderLayout className="events" title="Pricing Events">
      <div className="table-container">
        <div className="header-row">
          <div className="select-filters">
            <div className="market-container">
              <Select
                label="Market"
                isClearable={false}
                isMulti={false}
                onChange={({ value }) => setMarket(value)}
                options={MARKETS}
                row
                value={MARKETS.find(({ value }) => value === market)}
              />
            </div>
            <div className="feeder-container">
              <Select
                label="Feeder"
                isClearable={false}
                isMulti={false}
                onChange={({ value: feederId }) => setFeederId(feederId)}
                options={feederOptions}
                row
                value={
                  feederOptions
                    ? feederOptions.find((f) => f.value === feederId)
                    : undefined
                }
              />
            </div>
          </div>
          <div className="date-container">
            <DatePicker date={date} onChange={(d: DateTime) => setDate(d)} />
          </div>
          <div className="right-buttons">
            <Link to={`/program/${programID}/events/create`}>
              <IconButton icon="add" onClick={() => {}} />
            </Link>
            <IconButton
              disabled={reportDownloading}
              icon="get_app"
              onClick={downloadReport}
            />
            <IconButton
              disabled={updating || loading}
              icon="refresh"
              onClick={loadPricingEvents}
            />
          </div>
        </div>
        <ReactTable
          data={events}
          loading={loading}
          columns={[
            {
              Header: '',
              id: 'event',
              Cell: (props) => (
                <span className="center-container">
                  <Link
                    to={`/program/${programID}/events/${props.original.id}`}
                  >
                    Details
                  </Link>
                </span>
              ),
              width: 75,
              resizable: false,
            },
            {
              Header: `Start (${program?.timezone})`,
              accessor: 'start',
              Cell: (props) => (
                <span className="center-container">
                  {props.value.setZone(program!.timezone).toFormat('HH:mm:ss')}
                </span>
              ),
              filterable: true,
              filterMethod: (filter: any, row: any) => {
                return (
                  row[filter.id] >=
                  DateTime.fromObject({
                    year: date.year,
                    month: date.month,
                    day: date.day,
                    hour: filter.value.hour,
                    minute: filter.value.minute,
                    second: 0,
                    millisecond: 0,
                  })
                );
              },
              Filter: (cellInfo) => (
                <DatePicker
                  dateFormat="H:i"
                  date={date.startOf('day')}
                  onChange={(d) => cellInfo.onChange(d)}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                  }}
                  showArrows={false}
                  useUTC={false}
                />
              ),
            },
            {
              Header: `End (${program?.timezone})`,
              accessor: 'end',
              Cell: (props) => (
                <span className="center-container">
                  {props.value.setZone(program!.timezone).toFormat('HH:mm:ss')}
                </span>
              ),
              filterable: true,
              filterMethod: (filter: any, row: any) => {
                return (
                  row[filter.id] <
                  DateTime.fromObject({
                    year: date.year,
                    month: date.month,
                    day: date.day,
                    hour: filter.value.hour,
                    minute: filter.value.minute,
                    second: 59,
                    millisecond: 999,
                  })
                );
              },
              Filter: (cellInfo) => (
                <DatePicker
                  dateFormat="H:i"
                  date={date.endOf('day')}
                  onChange={(d) => cellInfo.onChange(d)}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                  }}
                  showArrows={false}
                  useUTC={false}
                />
              ),
            },
            {
              Header: 'DER',
              id: 'der',
              accessor: (d) => (ders ? ders[d.der_rdf_id]?.info?.name : null),
              Cell: (props) => (
                <span>
                  <Link to={`/ders/${props.original.der_rdf_id}`}>
                    {props.value}
                  </Link>
                </span>
              ),
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="DER Name"
                />
              ),
            },
            {
              Header: 'Request (kW)',
              id: 'real_power',
              accessor: (d) => d.real_power * 1e-3,
              Cell: (props) => <span>{props.value.toFixed(3)}</span>,
            },
            {
              Header: 'Request (kVAr)',
              id: 'reactive_power',
              accessor: (d) => d.reactive_power * 1e-3,
              Cell: (props) => <span>{props.value.toFixed(3)}</span>,
            },
            {
              Header: `Price (${program?.currency}/MWh)`,
              accessor: 'price',
              Cell: (props) => (
                <span>{numberFormatter.format(props.value)}</span>
              ),
            },
            {
              Header: `Total Price (${program?.currency})`,
              id: 'totalPrice',
              accessor: (d) =>
                d.real_power * 1e-6 * d.price * (d.duration / 3600),
              Cell: (props) => (
                <span>{numberFormatter.format(props.value)}</span>
              ),
            },
            {
              Header: 'Status',
              id: 'status',
              accessor: (d) => {
                // return false;
                return {
                  status: d.status,
                  opf_failed: d.opf_failed,
                };
              },
              filterable: true,
              filterMethod: (filter: any, row: any) => {
                if (boolean(row[filter.id])) {
                  return false;
                }
                return row[filter.id].status
                  .toLowerCase()
                  .includes(filter.value.toLowerCase());
              },
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Status"
                />
              ),
              Cell: (props: {
                value: { status: PricingEventState; opf_failed: boolean };
              }) => {
                const color = STATUS_TO_COLOR[props.value.status] || '#949899';
                return (
                  <div style={{ display: 'flex' }}>
                    {props.value.opf_failed && (
                      <Tooltip content="OPF Failed to Solve">
                        <span className="center-container warning-icon">
                          <i className="material-icons">warning</i>
                        </span>
                      </Tooltip>
                    )}
                    <span
                      className="status"
                      style={{
                        color: color,
                        border: `1px solid ${color}`,
                      }}
                    >
                      {props.value.status}
                    </span>
                  </div>
                );
              },
            },
            {
              Header: 'Settlement Status',
              accessor: 'settlement_status',
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id]
                  .toLowerCase()
                  .includes(filter.value.toLowerCase()),
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Settlement Status"
                />
              ),
              Cell: (props) => {
                return <span className="status">{props.value}</span>;
              },
              minWidth: 150,
            },
            {
              Header: 'Settlement Value ($)',
              accessor: 'settlement_value',
              sortable: true,
              Cell: (props) => (
                <span className="center-container">
                  {props.value !== null ? props.value.toFixed(2) : ''}
                </span>
              ),
            },
            {
              Header: 'Update Event',
              id: 'update',
              Cell: (props) => (
                <span className="center-container">
                  {props.original.status === 'Accepted' && (
                    <IconButton
                      disabled={updating}
                      icon="check_circle"
                      onClick={() =>
                        updateEvent(
                          props.original.id,
                          'Confirmed',
                          'Market Operator manually confirmed event'
                        )
                      }
                      tooltip="Confirm Event"
                    />
                  )}
                  <IconButton
                    disabled={
                      updating ||
                      NON_CANCELLABLE.indexOf(props.original.status) !== -1
                    }
                    icon="cancel"
                    onClick={() =>
                      updateEvent(
                        props.original.id,
                        'Cancelled',
                        'Market Operator manually cancelled event'
                      )
                    }
                    tooltip="Cancel Event"
                  />
                </span>
              ),
              width: 150,
            },
          ]}
          className="-striped -highlight"
          showPaginationBottom
          getTrProps={(state: any, rowInfo: any, column: any) => {
            const prevRow =
              rowInfo && rowInfo.viewIndex > 0
                ? state.sortedData[rowInfo.viewIndex - 1]
                : null;

            return {
              style: {
                borderTop:
                  prevRow && prevRow.start.hours !== rowInfo.row.start.hours
                    ? '2px solid darkgrey'
                    : '',
              },
            };
          }}
        />
      </div>
    </HeaderLayout>
  );
};

export default Events;
