import React, { ReactNode } from 'react';
import classNames from 'classnames';

import Button from '../Button';
import Tooltip from '../Tooltip';

import './IconButton.scss';

interface BaseButtonProps {
  active: boolean;
  children?: ReactNode;
  className: string;
  disabled: boolean;
  hasNotification: boolean;
  height?: string;
  icon: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  round: boolean;
  rotate?: number;
  width?: string;
}

interface IconButtonProps extends BaseButtonProps {
  tooltip?: string;
}

const BaseButton = ({
  active,
  children,
  className,
  disabled,
  hasNotification,
  height,
  icon,
  onClick,
  rotate,
  round,
  width,
}: BaseButtonProps) => (
  <div className={`icon-btn-container ${className}`}>
    <Button
      type="primary"
      className={classNames({
        'icon-btn': true,
        'icon-btn--active': active,
        [className]: true,
        round,
      })}
      disabled={disabled}
      height={height}
      onClick={onClick}
      width={width}
    >
      {hasNotification && <div className="has-notification">&nbsp;</div>}
      {icon ? (
        <span className="icon-container">
          <i
            {...(rotate && rotate > 0 && rotate < 360
              ? { style: { transform: `rotate(${Math.trunc(rotate)}deg)` } }
              : {})}
            className="material-icons"
          >
            {icon}
          </i>
        </span>
      ) : (
        <div className="icon-container">{children}</div>
      )}
    </Button>
  </div>
);
const IconButton = ({ tooltip, ...props }: IconButtonProps) => {
  return tooltip ? (
    <Tooltip content={tooltip}>
      <BaseButton {...props} />
    </Tooltip>
  ) : (
    <BaseButton {...props} />
  );
};

const iconButtonDefaultProps = {
  active: false,
  disabled: false,
  hasNotification: false,
  round: false,
  className: '',
  children: undefined,
  rotate: undefined,
  icon: undefined,
};
const withTooltipDefaultProps = {
  ...iconButtonDefaultProps,
  tooltip: undefined,
};

BaseButton.defaultProps = iconButtonDefaultProps;
IconButton.defaultProps = withTooltipDefaultProps;

export default IconButton;
