import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import LoadContribution from 'components/LoadContribution';
import HeaderLayout from 'components/HeaderLayout';
import Tabs from 'components/Tabs';
import { ProgramsContext } from 'contexts/ProgramsContext';

import { useContainers } from 'hooks/useContainers';

import FeederConfig from './FeederConfig';

import './FeederSettings.scss';

const FeederSettings = () => {
  const { programID, feederID } = useParams<{
    programID: string;
    feederID: string;
  }>();
  const { getProgram } = useContext(ProgramsContext);
  const program = getProgram(programID);

  const { containers = {} } = useContainers(programID);
  const feeder = feederID ? containers[feederID] : undefined;

  return (
    <HeaderLayout
      className="feeder-settings"
      title={
        <Breadcrumbs
          parents={[
            {
              to: `/program/${programID}/enrollment`,
              label: <h2 className="title">Program Enrollment</h2>,
            },
          ]}
          separator="/"
          currentHeader={`${feeder ? feeder.name : ''}`}
        />
      }
    >
      <Tabs
        tabs={[{ name: 'Feeder Forecast' }, { name: 'Feeder Configuration' }]}
      >
        {(TabPanel) => [
          <TabPanel key="tab0">
            {program && feederID && (
              <LoadContribution
                feederName={feeder?.name || ''}
                programID={program.program_id}
                rdf_id={feederID}
                timezone={program.timezone}
              />
            )}
          </TabPanel>,
          <TabPanel key="tab1">
            <FeederConfig feederID={feederID} programID={programID} />
          </TabPanel>,
        ]}
      </Tabs>
    </HeaderLayout>
  );
};

export default FeederSettings;
